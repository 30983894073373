import React from 'react';
import { IProps } from './interfaces';

const WsLogOut: React.FC<IProps> = ({
  size = 32,
  color = '#000',
  className,
}) => {
  return (
    <svg
      className={className}
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0"
        mask-type="alpha"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="24"
        height="24"
      >
        <rect width="24" height="24" fill="#C4C4C4" />
      </mask>
      <g mask="url(#mask0)">
        <path
          d="M14.863 6.85146V5.87129C14.863 3.73341 13.1296 2 10.9917 2H5.87024C3.73341 2 2 3.73341 2 5.87129V17.564C2 19.7018 3.73341 21.4352 5.87024 21.4352H11.0022C13.1338 21.4352 14.863 19.7071 14.863 17.5755V16.5848"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M22.0001 11.7177H9.35034"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M18.9236 8.65527L21.9996 11.7176L18.9236 14.7811"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
};

export default WsLogOut;
