import React, { useEffect, useState } from 'react';

import api from '~/services/api';

import { Container } from './styles';

interface IAchievements {
  userId: string;
}

const Achievements: React.FC<IAchievements> = ({ userId }) => {
  const [coursesOwned, setCoursesOwned] = useState(0);
  const [certificatesEarned, setCertificatesEarned] = useState(0);

  useEffect(() => {
    api.get(`courses-students/owned-courses/${userId}`).then((response) => {
      setCoursesOwned(response.data.ownedCourses);
    });

    api.get(`certificates/certificates-earned/${userId}`).then((response) => {
      setCertificatesEarned(response.data.certificateEarned);
    });
  }, [userId]);

  return (
    <Container>
      <div className="container">
        <div className="row">
          <div className="col-md-6 col-lg-12 col-xl-6 px-2">
            <div className="border-gray-achievements mx-auto my-2">
              <div className="p-3 d-flex d-md-block d-lg-flex d-xl-block justify-content-between align-items-center">
                <h3 className="h6 small-md normal-lg mb-0 mb-md-3 mb-lg-0 mb-xl-3">
                  Courses
                  <br />
                  Owned
                </h3>
                <p className="h1 font-weight-bold mb-0">{coursesOwned}</p>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-lg-12 col-xl-6 px-2">
            <div className="border-gray-achievements mx-auto my-2">
              <div className="p-3 d-flex d-md-block d-lg-flex d-xl-block justify-content-between align-items-center">
                <h3 className="h6 small-md normal-lg mb-0 mb-md-3 mb-lg-0 mb-xl-3">
                  Certificates
                  <br />
                  Earned
                </h3>
                <p className="h1 font-weight-bold mb-0">{certificatesEarned}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default Achievements;
