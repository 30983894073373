import React from 'react';
import { IProps } from './interfaces';

const WsWallet: React.FC<IProps> = ({
  size = 32,
  color = '#000',
  className,
}) => {
  return (
    <svg
      className={className}
      width={size}
      height={size}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.51004 2.08334H13.49C16.1648 2.08334 18.3333 4.30097 18.3333 7.03646V7.655L18.3335 7.66869L18.3333 7.68237V12.1001L18.3335 12.1138L18.3333 12.1275V12.9636C18.3333 15.699 16.1648 17.9167 13.49 17.9167H6.51004C3.83516 17.9167 1.66667 15.699 1.66667 12.9636V7.03646C1.66667 4.30097 3.83516 2.08334 6.51004 2.08334ZM17.122 7.03646V7.04931H14.4587L14.3061 7.0536C12.8418 7.13538 11.6789 8.37429 11.6789 9.89125C11.6799 11.4609 12.9236 12.7322 14.4583 12.7332H17.122V12.9636C17.122 15.0149 15.4959 16.6779 13.49 16.6779H6.51004C4.50415 16.6779 2.87799 15.0149 2.87799 12.9636V7.03646C2.87799 4.98512 4.50415 3.32211 6.51004 3.32211H13.49C15.4959 3.32211 17.122 4.98512 17.122 7.03646ZM14.459 8.28807L17.122 8.28807V11.4944H14.4587L14.3361 11.4895C13.5274 11.4252 12.8908 10.7343 12.8903 9.89087C12.8903 9.0062 13.5924 8.28861 14.459 8.28807ZM15.4341 9.84037C15.4341 9.4983 15.163 9.22099 14.8285 9.22099H14.5768L14.4946 9.22665C14.199 9.26766 13.9711 9.52681 13.9711 9.84037C13.9711 10.1824 14.2423 10.4598 14.5768 10.4598H14.8285L14.9107 10.4541C15.2063 10.4131 15.4341 10.1539 15.4341 9.84037ZM10.9006 6.45053C10.9006 6.10845 10.6294 5.83115 10.2949 5.83115H5.93503L5.85284 5.8368C5.55722 5.87781 5.32937 6.13696 5.32937 6.45053C5.32937 6.7926 5.60053 7.06991 5.93503 7.06991H10.2949L10.3771 7.06426C10.6727 7.02324 10.9006 6.7641 10.9006 6.45053Z"
        fill={color}
      />
    </svg>
  );
};

export default WsWallet;
