import React from 'react';
import { Switch } from 'react-router-dom';

import Route from './Route';

import SignIn from '~/pages/SignIn';
import Home from '~/pages/Home';
import StudentsList from '~/pages/Students/List';
import StudentsUpdate from '~/pages/Students/Update';
import StudentsUpdateCourses from '~/pages/Students/Update/Courses';
import FranchisesUpdateCourses from '~/pages/Students/Update/Franchises';
import Profile from '~/pages/Profile';
import ResourcesList from '~/pages/Resources/List';
import ResourcesRegister from '~/pages/Resources/Register';
import ResourcesUpdate from '~/pages/Resources/Update';
import CoachesList from '~/pages/Coaches/List';
import CoachesRegister from '~/pages/Coaches/Register';
import CoachesUpdate from '~/pages/Coaches/Update';
import CoachesBio from '~/pages/Coaches/Bio';
import CoursesList from '~/pages/Courses/List';
import CoursesRegister from '~/pages/Courses/Register';
import CoursesUpdate from '~/pages/Courses/Update';
import LessonsList from '~/pages/Courses/Lessons/List';
import LessonsRegister from '~/pages/Courses/Lessons/Register';
import LessonsUpdate from '~/pages/Courses/Lessons/Update';
import ModulesList from '~/pages/Courses/Modules/List';
import ModulesRegister from '~/pages/Courses/Modules/Register';
import ModulesUpdate from '~/pages/Courses/Modules/Update';
import Affiliates from '~/pages/Affiliates';
import TrainingList from '~/pages/Affiliates/Training/List';
import TrainingRegister from '~/pages/Affiliates/Training/Register';
import TrainingUpdate from '~/pages/Affiliates/Training/Update';
import CrowdfundingList from '~/pages/Affiliates/Crowdfunding/List';
import PaymentPlans from '~/pages/Affiliates/Crowdfunding/PaymentPlans';
import CrowdfundingRegister from '~/pages/Affiliates/Crowdfunding/Register';
import PromotionalMaterial from '~/pages/Affiliates/PromotionalMaterial';
import ProductsList from '~/pages/Products/List';
import ProductsRegister from '~/pages/Products/Register';
import ProductsUpdate from '~/pages/Products/Update';
import FaqList from '~/pages/Faq';
import Categories from '~/pages/Categories';
import ForgotPassword from '~/pages/ForgotPassword';
import OrderReview from '~/pages/OrderReview';
import ScheduledWithdrawals from '~/pages/ScheduledWithdrawals';
import SalesFunnel from '~/pages/SalesFunnel';
import AffiliateOffers from '~/pages/AffiliateOffers';
import AffiliateOffersRegister from '~/pages/AffiliateOffers/Register';

import Video from '~/pages/Video';
import Employees from '~/pages/Employees';
import UsersTypes from '~/pages/UsersTypes';

const routes: React.FC = () => {
  return (
    <Switch>
      <Route path={`${process.env.PUBLIC_URL}/`} exact component={SignIn} />
      <Route
        path={`${process.env.PUBLIC_URL}/dashboard`}
        component={Home}
        isPrivate
      />
      <Route
        path={`${process.env.PUBLIC_URL}/students`}
        exact
        component={StudentsList}
        isPrivate
      />
      <Route
        path={`${process.env.PUBLIC_URL}/students/update/:userID`}
        exact
        component={StudentsUpdate}
        isPrivate
      />
      <Route
        path={`${process.env.PUBLIC_URL}/students/update/:userID/courses`}
        exact
        component={StudentsUpdateCourses}
        isPrivate
      />
      <Route
        path={`${process.env.PUBLIC_URL}/students/update/:userID/franchises`}
        exact
        component={FranchisesUpdateCourses}
        isPrivate
      />
      <Route
        path={`${process.env.PUBLIC_URL}/profile`}
        component={Profile}
        isPrivate
      />
      <Route
        path={`${process.env.PUBLIC_URL}/resources`}
        component={ResourcesList}
        exact
        isPrivate
      />
      <Route
        path={`${process.env.PUBLIC_URL}/resources/downloads`}
        component={ResourcesList}
        exact
        isPrivate
      />
      <Route
        path={`${process.env.PUBLIC_URL}/resources/links`}
        component={ResourcesList}
        exact
        isPrivate
      />
      <Route
        path={`${process.env.PUBLIC_URL}/resources/register`}
        component={ResourcesRegister}
        isPrivate
      />
      <Route
        path={`${process.env.PUBLIC_URL}/resources/update/:slug`}
        component={ResourcesUpdate}
        isPrivate
      />
      <Route
        path={`${process.env.PUBLIC_URL}/coaches`}
        exact
        component={CoachesList}
        isPrivate
      />
      <Route
        path={`${process.env.PUBLIC_URL}/coaches/register`}
        component={CoachesRegister}
        isPrivate
      />
      <Route
        path={`${process.env.PUBLIC_URL}/coaches/update/:slug`}
        component={CoachesUpdate}
        isPrivate
      />
      <Route
        path={`${process.env.PUBLIC_URL}/coaches/:slug`}
        component={CoachesBio}
        isPrivate
      />
      <Route
        path={`${process.env.PUBLIC_URL}/courses`}
        exact
        component={CoursesList}
        isPrivate
      />
      <Route
        path={`${process.env.PUBLIC_URL}/courses/drafts`}
        exact
        component={CoursesList}
        isPrivate
      />
      <Route
        path={`${process.env.PUBLIC_URL}/courses/categories`}
        exact
        component={CoursesList}
        isPrivate
      />
      <Route
        path={`${process.env.PUBLIC_URL}/courses/categories/:slug`}
        exact
        component={CoursesList}
        isPrivate
      />
      <Route
        path={`${process.env.PUBLIC_URL}/courses/register`}
        component={CoursesRegister}
        isPrivate
      />
      <Route
        path={`${process.env.PUBLIC_URL}/courses/update/:slug`}
        component={CoursesUpdate}
        isPrivate
      />
      <Route
        path={`${process.env.PUBLIC_URL}/courses/:slug/lessons`}
        exact
        component={LessonsList}
        isPrivate
      />
      <Route
        path={`${process.env.PUBLIC_URL}/courses/:slug/lessons/register`}
        component={LessonsRegister}
        isPrivate
      />
      <Route
        path={`${process.env.PUBLIC_URL}/courses/:slug/lessons/update/:lessonSlug`}
        component={LessonsUpdate}
        isPrivate
      />
      <Route
        path={`${process.env.PUBLIC_URL}/courses/:slug/modules`}
        exact
        component={ModulesList}
        isPrivate
      />
      <Route
        path={`${process.env.PUBLIC_URL}/courses/:slug/modules/register`}
        component={ModulesRegister}
        isPrivate
      />
      <Route
        path={`${process.env.PUBLIC_URL}/courses/:slug/modules/update/:moduleSlug`}
        component={ModulesUpdate}
        isPrivate
      />
      <Route
        path={`${process.env.PUBLIC_URL}/affiliates`}
        exact
        component={Affiliates}
        isPrivate
      />
      <Route
        path={`${process.env.PUBLIC_URL}/affiliates/training`}
        exact
        component={TrainingList}
        isPrivate
      />
      <Route
        path={`${process.env.PUBLIC_URL}/affiliates/training/register`}
        exact
        component={TrainingRegister}
        isPrivate
      />
      <Route
        path={`${process.env.PUBLIC_URL}/affiliates/training/update/:slug`}
        exact
        component={TrainingUpdate}
        isPrivate
      />
      <Route
        path={`${process.env.PUBLIC_URL}/affiliates/training/:slug`}
        exact
        component={TrainingList}
        isPrivate
      />
      <Route
        path={`${process.env.PUBLIC_URL}/affiliates/crowdfunding`}
        exact
        component={CrowdfundingList}
        isPrivate
      />
      <Route
        path={`${process.env.PUBLIC_URL}/affiliates/crowdfunding/:userName`}
        exact
        component={PaymentPlans}
        isPrivate
      />
      <Route
        path={`${process.env.PUBLIC_URL}/affiliates/crowdfunding/register`}
        exact
        component={CrowdfundingRegister}
        isPrivate
      />
      <Route
        path={`${process.env.PUBLIC_URL}/affiliates/promotional-material`}
        component={PromotionalMaterial}
        isPrivate
      />
      <Route
        path={`${process.env.PUBLIC_URL}/products`}
        component={ProductsList}
        exact
        isPrivate
      />
      <Route
        path={`${process.env.PUBLIC_URL}/products/register`}
        component={ProductsRegister}
        isPrivate
      />
      <Route
        path={`${process.env.PUBLIC_URL}/products/update/:slug`}
        component={ProductsUpdate}
        isPrivate
      />
      <Route
        path={`${process.env.PUBLIC_URL}/faq`}
        component={FaqList}
        exact
        isPrivate
      />
      <Route
        path={`${process.env.PUBLIC_URL}/categories`}
        component={Categories}
        isPrivate
      />
      <Route
        path={`${process.env.PUBLIC_URL}/video`}
        component={Video}
        isPrivate
      />
      <Route
        path={`${process.env.PUBLIC_URL}/forgot-password`}
        exact
        component={ForgotPassword}
      />
      <Route
        path={`${process.env.PUBLIC_URL}/order-review`}
        component={OrderReview}
        isPrivate
        exact
      />
      <Route
        path={`${process.env.PUBLIC_URL}/order-review/:slug`}
        component={OrderReview}
        isPrivate
      />
      <Route
        path={`${process.env.PUBLIC_URL}/scheduled-withdrawals`}
        component={ScheduledWithdrawals}
        isPrivate
      />
      <Route
        path={`${process.env.PUBLIC_URL}/sales-funnel/:slug`}
        component={SalesFunnel}
        isPrivate
      />
      <Route
        path={`${process.env.PUBLIC_URL}/affiliate-offers`}
        component={AffiliateOffers}
        exact
        isPrivate
      />
      <Route
        path={`${process.env.PUBLIC_URL}/affiliate-offers/register`}
        component={AffiliateOffersRegister}
        isPrivate
      />
      <Route
        path={`${process.env.PUBLIC_URL}/access-types`}
        component={UsersTypes}
        isPrivate
      />
      <Route
        path={`${process.env.PUBLIC_URL}/employees`}
        component={Employees}
        isPrivate
      />
    </Switch>
  );
};

export default routes;
