import React, { useEffect, useState } from 'react';

import { NavLink } from 'react-router-dom';
import api from '~/services/api';

import { Container } from './styles';
import categoryIcon from '~/assets/icons/categories.svg';

interface ICategory {
  id: string;
  name: string;
  slug: string;
}

interface ICategoriesProps {
  type: 'courses' | 'trainings';
}

const Categories: React.FC<ICategoriesProps> = ({ type }) => {
  const [categories, setCategories] = useState<ICategory[]>([]);
  const [baseUrl, setBaseUrl] = useState('');

  useEffect(() => {
    api
      .get('categories', {
        params: {
          type,
        },
      })
      .then((response) => {
        setCategories(response.data);
      });

    if (type === 'courses') {
      setBaseUrl(`${process.env.PUBLIC_URL}/courses/categories`);
    } else {
      setBaseUrl(`${process.env.PUBLIC_URL}/affiliates/training`);
    }
  }, [type]);

  return (
    <Container>
      <div className="d-flex align-items-center">
        <h2 className="h5 text-right text-md-left mb-0">Categories</h2>
        <img src={categoryIcon} alt="categories" className="ml-4" />
      </div>
      <div className="categories row mt-3 mt-md-4 mt-xl-5 text-left w-md-100">
        <div className="col-12 mb-3">
          <NavLink
            to={baseUrl}
            className="w-100 pl-4 py-3"
            activeClassName="active"
            exact
          >
            All
          </NavLink>
        </div>
        {categories.map((category) => (
          <div key={category.id} className="col-12 mb-3">
            <NavLink
              to={`${baseUrl}/${category.slug}`}
              className="w-100 pl-4 py-3"
              activeClassName="active"
            >
              {category.name}
            </NavLink>
          </div>
        ))}
      </div>
    </Container>
  );
};

export default Categories;
