import React, { useEffect, useState } from 'react';

import api from '~/services/api';

import { Container, Welcome } from './styles';
import ListFormat from './ListFormat';
import ListPending from './ListPending';
import TreeFormat from './TreeFormat';

interface ICrowdfunding {
  affiliate_owner_id: string;
}

const LinksList: React.FC = () => {
  const [pageSelected, setPageSelected] = useState('list');
  const [totalMembersHold, setTotalMembersHold] = useState(0);
  const [totalMembersPlaced, setTotalMembersPlaced] = useState(0);
  const [totalMembers, setTotalMembers] = useState(0);
  const [changeValues, setChangeValues] = useState(false);

  useEffect(() => {
    api.get<ICrowdfunding[]>('crowdfunding').then((response) => {
      const membersHold = response.data.filter(
        (crowdfunding, index) => !crowdfunding.affiliate_owner_id && index > 0
      );
      const membersPlaced = response.data.filter(
        (crowdfunding, index) => crowdfunding.affiliate_owner_id || index === 0
      );

      setTotalMembersHold(membersHold.length);
      setTotalMembersPlaced(membersPlaced.length);
      setTotalMembers(response.data.length);
    });
  }, [changeValues]);

  return (
    <Container>
      <div className="container-fluid container-xxl">
        <div className="row justify-content-center">
          <div className="col-12 p-0">
            <Welcome>
              <div className="container-fluid">
                <div className="row">
                  <div className="col-lg-6 d-flex pl-0 align-items-start">
                    <h1 className="h4 h2-sm mt-4 mb-5 mb-lg-2">
                      CrowdFunding Placement
                    </h1>
                    {false && (
                      <>
                        {pageSelected === 'list' ? (
                          <button
                            type="button"
                            className="border-golden rounded-pill d-inline-block ml-2"
                            onClick={() => setPageSelected('tree')}
                          >
                            <span className="d-block px-3 py-1">Tree View</span>
                          </button>
                        ) : (
                          <button
                            type="button"
                            className="border-golden rounded-pill d-inline-block ml-2"
                            onClick={() => setPageSelected('list')}
                          >
                            <span className="d-block px-3 py-1">List View</span>
                          </button>
                        )}
                      </>
                    )}
                  </div>
                  <div className="col-lg-6 text-center px-0">
                    <div className="d-sm-flex bg-gray justify-content-between py-3">
                      <div className="w-100 px-2">
                        <span className="mb-2">{totalMembersHold}</span>
                        <p className="mb-0 small">Total members on Hold</p>
                      </div>
                      <hr className="d-sm-none mx-auto" />
                      <div className="w-100 px-2 borders-gray">
                        <span className="mb-2">{totalMembersPlaced}</span>
                        <p className="mb-0 small">Placed in position</p>
                      </div>
                      <hr className="d-sm-none mx-auto" />
                      <div className="w-100 px-2">
                        <span className="mb-2">{totalMembers}</span>
                        <p className="mb-0 small">Total Members</p>
                      </div>
                    </div>
                  </div>
                  {pageSelected !== 'tree' && (
                    <div className="col-12 d-flex pl-0 align-items-start">
                      <button
                        type="button"
                        className={`p-relative d-inline-block ml-sm-2 ${
                          pageSelected === 'list' ? 'active' : ''
                        }`}
                        onClick={() => setPageSelected('list')}
                      >
                        <span className="d-block px-3 py-1">List View</span>
                      </button>

                      <button
                        type="button"
                        className={`p-relative d-inline-block ml-2 ${
                          pageSelected === 'pending' ? 'active' : ''
                        }`}
                        onClick={() => setPageSelected('pending')}
                      >
                        <span className="d-block px-3 py-1">
                          Pending payments
                        </span>
                      </button>
                    </div>
                  )}
                </div>
              </div>
            </Welcome>
          </div>
          {pageSelected === 'list' && (
            <ListFormat
              active={pageSelected === 'list'}
              setChangeValues={setChangeValues}
              changeValues={changeValues}
            />
          )}
          {pageSelected === 'pending' && (
            <ListPending
              active={pageSelected === 'pending'}
              setChangeValues={setChangeValues}
              changeValues={changeValues}
            />
          )}
          {pageSelected === 'tree' && (
            <TreeFormat
              active={pageSelected === 'tree'}
              setChangeValues={setChangeValues}
              changeValues={changeValues}
            />
          )}
        </div>
      </div>
    </Container>
  );
};

export default LinksList;
