import React, { useEffect, useMemo, useState } from 'react';
import { FaSearch } from 'react-icons/fa';
import { MdInfoOutline } from 'react-icons/md';
import { differenceInDays, parseISO, getDate, getYear, format } from 'date-fns';

import { useParams } from 'react-router-dom';
import { formatPrice } from '~/utils/format';
import api from '~/services/api';

import { Container, Box, Status } from './styles';
import Table from '~/components/Table';
import Graph from '~/components/GraphParcel';

interface IProduct {
  id: string;
  name: string;
  price: number;
}

interface ISubOrderResponse {
  id: string;
  amount_paid: string;
  updated_at: string;
}

interface IInstallmentOrderResponse {
  id: string;
  status: string;
  current_installment: number;
  amount_paid: string;
  due_date?: string;
  installments: number;
  updated_at: string;
  subOrders: ISubOrderResponse[];
  merchant_id: string;
  anticipated: boolean;
  deleted_at?: Date;
}

interface IInstallmentResponse {
  id: string;
  status: string;
  current_installment: number;
  amount_paid: string;
  due_date?: string;
  installments: number;
  installmentsOrders: IInstallmentOrderResponse[];
  product: IProduct;
  merchant_id: string;
  updated_at: string;
}

interface IInstallmentData {
  status: string;
}

interface IPayment {
  id: string;
  date: string;
  amount: string;
  anticipated: boolean;
}

interface IInstallment {
  id: string;
  product_id: string;
  name: string;
  status: string;
  credit_amount: string;
  installmentsData: IInstallmentData[];
  amount: string;
  paid: string;
  left_pay: string;
  due_day: number;
  due_month: string;
  due_year: number;
  overdue: boolean;
  all_paid: boolean;
  save: string;
  remaining_amount: string;
  order_id: string;
  payments: IPayment[];
  anticipated: boolean;
  anticipated_amount: string;
}

interface IParams {
  userName: string;
}

const PaymentPlans: React.FC = () => {
  const params = useParams<IParams>();
  const [installments, setInstallments] = useState<IInstallment[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    api
      .get(`students/@${params.userName}`)
      .then(async (responseUser) => {
        if (responseUser.data) {
          const response = await api.get<IInstallmentResponse[]>(
            `orders/admins/${responseUser.data.id}`
          );
          const responseData = response.data.filter(
            (installment) => !!installment.product
          );

          const data: IInstallment[] = responseData.map((installment) => {
            const current_date = new Date();
            const installmentSelected: IInstallment = {
              id: '',
              product_id: installment.product ? installment.product.id : '',
              name: installment.product ? installment.product.name : '',
              status: '',
              credit_amount: '',
              installmentsData: [],
              amount: '',
              paid: '',
              left_pay: '',
              due_day: 0,
              due_month: '',
              due_year: 0,
              overdue: false,
              all_paid: false,
              save: '',
              remaining_amount: '',
              order_id: installment.id,
              payments: [],
              anticipated: false,
              anticipated_amount: '$0.00',
            };

            const installmentsDatas: IInstallmentData[] = [];
            const installmentData: IInstallmentData = {
              status: installment.status,
            };

            let paid = 0;
            let anticipated_amount = 0;
            let left_pay = 0;
            let save = 0;

            installmentsDatas.push(installmentData);

            const descountData = [
              {
                discount:
                  Math.ceil(
                    parseFloat(installment.product.price.toString()) +
                      parseFloat(installment.product.price.toString()) * 0.25
                  ) - parseFloat(installment.product.price.toString()),
                price:
                  parseFloat(installment.product.price.toString()) +
                  parseFloat(installment.product.price.toString()) * 0,
              },
              {
                discount:
                  Math.ceil(
                    parseFloat(installment.product.price.toString()) +
                      parseFloat(installment.product.price.toString()) * 0.25
                  ) -
                  Math.ceil(
                    parseFloat(installment.product.price.toString()) +
                      parseFloat(installment.product.price.toString()) * 0.1
                  ),
                price: parseFloat(
                  (
                    (parseFloat(installment.product.price.toString()) +
                      parseFloat(installment.product.price.toString()) * 0.1) /
                    2
                  ).toFixed(0)
                ),
              },
              {
                discount:
                  Math.ceil(
                    parseFloat(installment.product.price.toString()) +
                      parseFloat(installment.product.price.toString()) * 0.25
                  ) -
                  Math.ceil(
                    parseFloat(installment.product.price.toString()) +
                      parseFloat(installment.product.price.toString()) * 0.15
                  ),
                price: parseFloat(
                  (
                    (parseFloat(installment.product.price.toString()) +
                      parseFloat(installment.product.price.toString()) * 0.15) /
                    3
                  ).toFixed(0)
                ),
              },
              {
                discount:
                  Math.ceil(
                    parseFloat(installment.product.price.toString()) +
                      parseFloat(installment.product.price.toString()) * 0.25
                  ) -
                  Math.ceil(
                    parseFloat(installment.product.price.toString()) +
                      parseFloat(installment.product.price.toString()) * 0.2
                  ),
                price: parseFloat(
                  (
                    (parseFloat(installment.product.price.toString()) +
                      parseFloat(installment.product.price.toString()) * 0.2) /
                    4
                  ).toFixed(0)
                ),
              },
              {
                discount: 0,
                price: parseFloat(
                  (
                    (parseFloat(installment.product.price.toString()) +
                      parseFloat(installment.product.price.toString()) * 0.25) /
                    5
                  ).toFixed(0)
                ),
              },
            ];

            if (installment.status !== 'succeeded') {
              installmentSelected.id = installment.id;
              left_pay += parseFloat(installment.amount_paid);

              const due_date = parseISO(
                installment.due_date || installment.updated_at
              );

              const diffDay = differenceInDays(due_date, current_date);

              if (diffDay < 0) {
                const day = diffDay * -1;
                if (day === 1) {
                  installmentSelected.status = `${day} day past due`;
                } else {
                  installmentSelected.status = `${day} days past due`;
                }
                installmentSelected.overdue = true;
              } else if (diffDay > 0) {
                if (diffDay === 1) {
                  installmentSelected.status = `${diffDay} day left to expiration`;
                } else {
                  installmentSelected.status = `${diffDay} days left to expiration`;
                }
              } else {
                installmentSelected.status = `Your invoice is due today`;
              }

              installmentSelected.amount = formatPrice(
                parseFloat(installment.amount_paid)
              );

              installmentSelected.due_day = getDate(due_date);
              installmentSelected.due_month = format(due_date, 'MMMM');
              installmentSelected.due_year = getYear(due_date);

              if (
                installment.installments === installment.current_installment
              ) {
                save = 0;
                installmentSelected.remaining_amount = formatPrice(
                  parseFloat(installment.amount_paid)
                );
              } else {
                save =
                  descountData[installment.current_installment - 1].discount;
                installmentSelected.remaining_amount = formatPrice(
                  descountData[installment.current_installment - 1].price
                );
              }
            } else {
              paid += parseFloat(installment.amount_paid);
              anticipated_amount += parseFloat(installment.amount_paid);
              installmentSelected.payments.push({
                id: installment.id,
                date: format(parseISO(installment.updated_at), 'MMM dd, yyyy'),
                amount: formatPrice(parseFloat(installment.amount_paid)),
                anticipated: false,
              });

              if (installment.installmentsOrders.length === 0) {
                installmentSelected.id = installment.id;
                const due_date = parseISO(installment.updated_at);

                installmentSelected.status = `All installments have been paid`;
                installmentSelected.all_paid = true;
                save = 0;
                installmentSelected.remaining_amount = formatPrice(0);

                installmentSelected.amount = formatPrice(
                  parseFloat(installment.amount_paid)
                );

                installmentSelected.due_day = getDate(due_date);
                installmentSelected.due_month = format(due_date, 'MMMM');
                installmentSelected.due_year = getYear(due_date);
              }
            }

            let credit_amount = parseFloat(installment.amount_paid);

            const anticipatedOrder = installment.installmentsOrders.find(
              (installmentOrder) =>
                installmentOrder.anticipated &&
                installmentOrder.status === 'succeeded'
            );

            installment.installmentsOrders.forEach((subInstallment) => {
              if (!subInstallment.deleted_at) {
                if (!subInstallment.anticipated) {
                  const subInstallmentData: IInstallmentData = {
                    status: subInstallment.status,
                  };
                  installmentsDatas.push(subInstallmentData);
                  if (subInstallment.status !== 'succeeded') {
                    if (subInstallment.subOrders.length > 0) {
                      subInstallment.subOrders.forEach((subOrder) => {
                        credit_amount += parseFloat(subOrder.amount_paid);
                        paid += parseFloat(subOrder.amount_paid);
                        installmentSelected.payments.push({
                          id: subOrder.id,
                          date: format(
                            parseISO(subOrder.updated_at),
                            'MMM dd, yyyy'
                          ),
                          amount: formatPrice(parseFloat(subOrder.amount_paid)),
                          anticipated: false,
                        });
                      });
                    }

                    left_pay += parseFloat(subInstallment.amount_paid);
                    if (!installmentSelected.id) {
                      installmentSelected.id = subInstallment.id;

                      const due_date = parseISO(
                        subInstallment.due_date || subInstallment.updated_at
                      );
                      const diffDay = differenceInDays(due_date, current_date);

                      if (diffDay < 0) {
                        const day = diffDay * -1;
                        if (day === 1) {
                          installmentSelected.status = `${day} day past due`;
                        } else {
                          installmentSelected.status = `${day} days past due`;
                        }
                        installmentSelected.overdue = true;
                      } else if (diffDay > 0) {
                        if (diffDay === 1) {
                          installmentSelected.status = `${diffDay} day left to expiration`;
                        } else {
                          installmentSelected.status = `${diffDay} days left to expiration`;
                        }
                      } else {
                        installmentSelected.status = `Your invoice is due today`;
                      }

                      installmentSelected.amount = formatPrice(
                        parseFloat(subInstallment.amount_paid)
                      );

                      installmentSelected.due_day = getDate(due_date);
                      installmentSelected.due_month = format(due_date, 'MMMM');
                      installmentSelected.due_year = getYear(due_date);

                      if (
                        installment.installments ===
                        subInstallment.current_installment
                      ) {
                        save = 0;
                        installmentSelected.remaining_amount = formatPrice(
                          parseFloat(subInstallment.amount_paid)
                        );
                      } else {
                        save =
                          descountData[subInstallment.current_installment - 1]
                            .discount;
                      }
                    }
                  } else {
                    if (subInstallment.subOrders.length > 0) {
                      subInstallment.subOrders.forEach((subOrder) => {
                        credit_amount += parseFloat(subOrder.amount_paid);
                        paid += parseFloat(subOrder.amount_paid);
                        installmentSelected.payments.push({
                          id: subOrder.id,
                          date: format(
                            parseISO(subOrder.updated_at),
                            'MMM dd, yyyy'
                          ),
                          amount: formatPrice(parseFloat(subOrder.amount_paid)),
                          anticipated: false,
                        });
                      });
                    }

                    paid += parseFloat(subInstallment.amount_paid);
                    if (!anticipatedOrder) {
                      installmentSelected.payments.push({
                        id: subInstallment.id,
                        date: format(
                          parseISO(subInstallment.updated_at),
                          'MMM dd, yyyy'
                        ),
                        amount: formatPrice(
                          parseFloat(subInstallment.amount_paid)
                        ),
                        anticipated: false,
                      });
                    } else if (subInstallment.due_date) {
                      if (
                        parseISO(anticipatedOrder.updated_at) <
                          parseISO(subInstallment.due_date) &&
                        !subInstallment.merchant_id
                      ) {
                        installmentSelected.payments.push({
                          id: subInstallment.id,
                          date: format(
                            parseISO(subInstallment.due_date),
                            'MMM dd, yyyy'
                          ),
                          amount: formatPrice(
                            parseFloat(subInstallment.amount_paid)
                          ),
                          anticipated: true,
                        });
                      } else {
                        anticipated_amount = paid;
                        installmentSelected.payments.push({
                          id: subInstallment.id,
                          date: format(
                            parseISO(subInstallment.updated_at),
                            'MMM dd, yyyy'
                          ),
                          amount: formatPrice(
                            parseFloat(subInstallment.amount_paid)
                          ),
                          anticipated: false,
                        });
                      }
                    }

                    if (
                      installment.installments ===
                      subInstallment.current_installment
                    ) {
                      installmentSelected.id = subInstallment.id;
                      const due_date = parseISO(subInstallment.updated_at);

                      installmentSelected.status = `All installments have been paid`;
                      installmentSelected.all_paid = true;
                      save = 0;
                      installmentSelected.remaining_amount = formatPrice(0);

                      installmentSelected.amount = formatPrice(
                        parseFloat(subInstallment.amount_paid)
                      );

                      installmentSelected.due_day = getDate(due_date);
                      installmentSelected.due_month = format(due_date, 'MMMM');
                      installmentSelected.due_year = getYear(due_date);
                    }
                  }

                  credit_amount += parseFloat(subInstallment.amount_paid);
                }
              }
            });

            if (anticipatedOrder) {
              anticipated_amount += parseFloat(anticipatedOrder.amount_paid);
              installmentSelected.anticipated = true;
              installmentSelected.anticipated_amount = formatPrice(
                anticipated_amount
              );
              installmentSelected.payments.push({
                id: anticipatedOrder.id,
                date: format(
                  parseISO(anticipatedOrder.updated_at),
                  'MMM dd, yyyy'
                ),
                amount: formatPrice(parseFloat(anticipatedOrder.amount_paid)),
                anticipated: false,
              });
            }

            installmentSelected.installmentsData = installmentsDatas;
            installmentSelected.credit_amount = formatPrice(credit_amount);
            installmentSelected.paid = formatPrice(paid);
            installmentSelected.left_pay = formatPrice(left_pay);
            installmentSelected.save = formatPrice(save);

            if (installmentSelected.remaining_amount.length === 0) {
              installmentSelected.remaining_amount = formatPrice(
                left_pay - save
              );
            }

            return installmentSelected;
          });
          console.log(data);
          setInstallments(data);
        }
      })
      .catch((error) => console.log(error))
      .finally(() => setLoading(false));
  }, [params.userName]);

  const columns = useMemo(
    () => [
      {
        name: 'Date',
        selector: 'date',
        sortable: true,
      },
      {
        name: 'Amount',
        selector: 'amount',
        sortable: true,
      },
    ],
    []
  );

  return (
    <>
      <Container>
        <div className="container">
          <div className="row">
            <div className="col-lg-6 d-flex pl-0 align-items-start">
              <h1 className="h4 h2-sm mt-4 mb-5">Pending payments</h1>
            </div>
          </div>
        </div>
        {installments.map((installment, index) => (
          <div key={index.toString()} className="container mb-3 bg-gray">
            <div className="row px-3 px-xxl-5 py-5 justify-content-center">
              <div className="col-md-9 col-lg-12 flex-wrap bd-gray py-3 px-4 d-lg-flex justify-content-between align-items-center">
                <h2 className="col col-xl-7 px-0 px-sm-3 mb-lg-0">
                  {installment.name}
                </h2>
                <h3 className="col col-xl-5 text-1600-right px-0 px-sm-3 mb-0">
                  Account Status:{' '}
                  <Status overdue={installment.overdue} className="h4 pl-2">
                    <br className="d-sm-none d-lg-block d-1600-none" />
                    {installment.status}
                  </Status>
                </h3>
              </div>
            </div>
            <Box className="row px-3 px-xxl-5 pb-5 justify-content-center">
              <div className="col-md-9 col-lg-6 col-xxl-4 d-flex flex-wrap px-1600-0 pl-xxl-0 mb-4 mb-lg-0">
                <div className="col-12 text-center px-1 py-4 bd-gray mb-4 mb-lg-3">
                  <h2 className="mb-4">Credit amount</h2>
                  <h3 className="mb-4">{installment.credit_amount}</h3>
                  <Graph installmentsData={installment.installmentsData} />
                </div>
                <div className="col-sm-6 px-0 mt-lg-3 pr-sm-2 mb-4 mb-sm-0 pr-1600-3">
                  <div className="col-12 bd-gray text-center p-3 py-4">
                    <h2 className="mb-3">Paid</h2>
                    <h4 className="mb-0 mb-sm-3">{installment.paid}</h4>
                  </div>
                </div>
                <div className="col-sm-6 px-0 mt-lg-3 pl-sm-2 pl-1600-3">
                  <div className="col-12 bd-gray text-center p-3 py-4">
                    <h2 className="mb-3">Left to pay</h2>
                    <h4 className="mb-0 mb-sm-3">{installment.left_pay}</h4>
                  </div>
                </div>
              </div>
              <div className="col-md-9 col-lg-6 col-xxl-4 d-flex flex-wrap pl-0 pl-lg-3 pr-0 pr-xxl-3">
                <div className="col-12 text-center py-4 bd-gray h-30 mg-box mb-4 mb-lg-0">
                  <h2 className="mb-4">Payment amount</h2>
                  <h3 className="mb-0 mb-sm-4">{installment.amount}</h3>
                </div>
                <div className="col-12 text-center p-4 bd-gray h-lg-64">
                  <h2 className="mb-4">Payment history</h2>
                  <div className="d-flex search mx-auto w-md-75 w-lg-100 align-items-center px-3">
                    <input
                      type="text"
                      id="search"
                      className="w-100 py-1"
                      placeholder="Search"
                    />
                    <FaSearch size={20} />
                  </div>
                  <Table columns={columns} data={installment.payments} />
                </div>
              </div>
              <div className="col-md-9 col-lg-12 col-xxl-4 d-flex d-xxl-block flex-wrap justify-content-end px-1600-0 pr-xxl-0 mt-4 mt-xxl-0">
                <div className="col-lg-6 col-xxl-12 mg-box px-0 mb-4 mb-lg-0">
                  <div className="col-12 px-0 text-center py-4 bd-gray h-100 h-xxl-30">
                    <h2 className="mb-4">
                      Early payoff savings{' '}
                      <span aria-label="Simply dummy text of the printing and typesetting industry">
                        <MdInfoOutline size={20} />
                      </span>
                    </h2>
                    <h3 className="mb-0 mb-sm-3 mb-lg-2 mb-xl-4">
                      {installment.save}
                    </h3>
                  </div>
                </div>
                <div className="col-lg-6 col-xxl-12 mg-box px-0 pl-lg-3 pl-xxl-0 mb-5 mb-lg-0">
                  <div className="col-12 px-0 text-center py-4 bd-gray">
                    <h2 className="mb-0">Payment date</h2>
                    <div className="d-flex flex-wrap align-items-center mt-4 my-xxl-4">
                      <div className="col-4 col-xxl-6 justify-content-end justify-content-lg-center d-flex">
                        <div className="d-flex align-items-center justify-content-center circle">
                          {installment.due_day}
                        </div>
                      </div>
                      <div className="col-8 col-xxl-6 pl-0">
                        <p className="mb-0">
                          {installment.due_month}{' '}
                          <br className="d-none d-xxl-block" />
                          {installment.due_year}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Box>
          </div>
        ))}
        {loading && (
          <div className="loading-box">
            <div className="spinner-border text-light" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        )}
      </Container>
    </>
  );
};

export default PaymentPlans;
