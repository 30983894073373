import React from 'react';
import { IProps } from './interfaces';

const WsCourses: React.FC<IProps> = ({
  size = 32,
  color = '#000',
  className,
}) => {
  return (
    <svg
      className={className}
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0"
        mask-type="alpha"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="24"
        height="24"
      >
        <rect width="24" height="24" fill="#C4C4C4" />
      </mask>
      <g mask="url(#mask0)">
        <path
          d="M12.631 4L12.3675 4.08342L1.8416 7.76114C1.8416 7.76114 0.000364661 8.12343 3.08119e-08 8.58373C-0.000185999 8.81857 0.842068 9.0494 0.842068 9.0494V13.7333C0.339354 14.038 3.08109e-08 14.5939 3.08109e-08 15.2437C3.08109e-08 15.7095 0.177435 16.1562 0.493272 16.4856C0.809109 16.815 1.23748 17 1.68414 17C2.1308 17 2.55916 16.815 2.875 16.4856C3.19084 16.1562 3.36827 15.7095 3.36827 15.2437C3.36827 14.5939 3.02892 14.038 2.5262 13.7333V9.65532L4.21034 10.2305V15.973C4.21034 16.6931 4.63138 17.2902 5.13156 17.7029C5.63175 18.113 6.2532 18.4028 6.99927 18.6628C8.4931 19.1809 10.4619 19.4856 12.631 19.4856C14.8002 19.4856 16.7689 19.1817 18.2628 18.6619C19.0088 18.4028 19.6303 18.113 20.1305 17.7021C20.6307 17.2902 21.0517 16.6931 21.0517 15.973V10.2305L23.4204 9.40681C23.4204 9.40681 24 9.02281 24 8.58373C24 8.14466 23.4196 7.76026 23.4196 7.76026L12.8937 4.08342L12.631 4ZM12.631 5.31908L21.6923 8.5C21.6923 8.5 13.2206 11.3996 12.631 11.4102C12.1566 11.4187 3.36827 8.7103 3.36827 8.5C3.36827 8.16061 12.631 5.31908 12.631 5.31908ZM5.47344 10.7791L12.3683 13.0845L12.631 13.1671L12.8946 13.0836L19.7886 10.8336V15.9727C19.7886 15.9727 19.6674 16.0991 19.3676 16.4118C18.9465 16.8509 18.7293 17.0722 18.1045 17.29C16.8565 17.7229 14.6478 18.1681 12.631 18.1681C10.6143 18.1681 8.8274 17.7246 7.57861 17.29C6.95548 17.0731 6.16057 16.6305 5.89448 16.4118C5.6267 16.1923 5.47344 15.9262 5.47344 15.9174V10.7791Z"
          fill={color}
        />
      </g>
    </svg>
  );
};

export default WsCourses;
