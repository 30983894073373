import React, { useRef, useState, useEffect } from 'react';
import videojs, { VideoJsPlayerOptions } from 'video.js';
import 'video.js/dist/video-js.css';
import 'videojs-contrib-hls';

import { Container } from './styles';

interface playerProps {
  src: string;
}

const Player: React.FC<playerProps> = ({ src }) => {
  const playerRef = useRef(null);
  const [elements, setElements] = useState(document.querySelectorAll('video'));

  useEffect(() => {
    if (elements.length > 0) {
      elements.forEach((el) =>
        el.addEventListener('contextmenu', (e) => e.preventDefault())
      );
    } else {
      setElements(document.querySelectorAll('video'));
    }
  }, [elements]);

  useEffect(() => {
    const fileType = src.split('.')[src.split('.').length - 1];
    const sources = [
      {
        src,
        type: 'video/mp4',
      },
    ];

    if (fileType === 'm3u8') {
      sources[0].type = 'application/x-mpegURL';
    }

    const options: VideoJsPlayerOptions = {
      fluid: true,
      preload: 'auto',
      html5: {
        hls: {
          enableLowInitialPlaylist: true,
          smoothQualityChange: true,
          overrideNative: true,
        },
      },
      autoplay: false,
      controls: true,
      sources,
    };

    if (playerRef.current) {
      videojs(playerRef.current, options);
    }
  }, [src]);

  return (
    <Container className="h-100">
      <div data-vjs-player className="h-100">
        <video ref={playerRef} className="video-js h-100">
          <track kind="captions" />
        </video>
      </div>
    </Container>
  );
};

export default Player;
