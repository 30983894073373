import { createGlobalStyle } from 'styled-components';
import { lighten } from 'polished';

export default createGlobalStyle`
  @import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&display=swap');

  ::-webkit-scrollbar {
    background-color: #18191a;
    width: 5px;
    height: 5px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background: #242526;
  }

  *{
    margin: 0;
    padding: 0;
    outline: 0;
    box-sizing: border-box;
  }

  html{
    background: rgb(36,37,38);
    background: linear-gradient(90deg, rgba(36,37,38,1) 0%, rgba(36,37,38,1) 9.1%, rgba(24,25,26,1) 9.1%, rgba(24,25,26,1) 100%);
  }

  body{
    -webkit-font-smoothing: antialiased;
  }

  body, input, button {
    font-family: 'Inter', sans-serif;
    font-size: 16px;
    color: #E4E4E4 !important;
  }

  h1, h2, h3, h4, h5, h6, strong{
    font-weight: 500
  }

  button {
    cursor: pointer;
  }

  button:focus{
    outline: none;
    box-shadow: none !important;
  }

  .font-weight-300{
    font-weight: 300 !important;
  }

  .font-weight-400{
    font-weight: 400 !important;
  }

  .font-weight-600{
    font-weight: 600 !important;
  }

  .align-content-space-between{
    align-content: space-between;
  }

  .bg-light-grey{
    background-color: rgba(51, 51, 51, 1) !important;
  }
  .light-grey-text{
    color: #6D6D6D;
  }
  .text-decoration-underline{
    text-decoration: underline;
  }

  .transparent-button {
    background-color: transparent;
    border: none;
  }

  .table-btn{
    font-size: 0.8rem;
  }

  .btn-grey{
    background-color: #606060!important;
    border: none;
    border-radius: 15px;
    :hover{
      background-color: ${lighten(0.1, '#606060')}!important;
      transition-duration: 0.5s;
    }
  }
  .btn-dark{
    background-color: rgba(27, 21, 21, 1);
    border: none;
    border-radius: 15px;
  }

  .border-golden {
    padding: 2px;
    background-image: linear-gradient(0deg, #202020, #202020),
      linear-gradient(180deg, #825511 0%, #f3bf5c 100%);
    background-origin: border-box;
    background-clip: content-box, border-box;
    border-image-slice: 1;
    border-radius: 10px;
    color: #e4e4e4;
    border: none;

    :disabled{
      opacity: 0.7;
      cursor: default;
    }
  }

  .border-silver {
    padding: 2px;
    background-image: linear-gradient(0deg, #202020, #202020),
      linear-gradient(180deg, #4A4B4B 0%, #c3c3c3 100%);
    background-origin: border-box;
    background-clip: content-box, border-box;
    border-image-slice: 1;
    border-radius: 10px;
    color: #e4e4e4;
    border: none;
  }

  .border-delete {
    padding: 2px;
    background-image: linear-gradient(0deg, #202020, #202020),
      linear-gradient(180deg, #ff1a50 0%, #ff4d77 100%);
    background-origin: border-box;
    background-clip: content-box, border-box;
    border-image-slice: 1;
    border-radius: 10px;
    color: #e4e4e4;
    border: none;
  }

  .loading-box {
    position: fixed;
    width: 100%;
    height: 100vh;
    z-index: 1100;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    top: 0;
    left: 0;
    background: #00000055;
  }

  .modal-content {
    background: #202020 !important;

    .modal-header, .modal-body, .modal-footer {
      border: none;
    }

    .modal-header{
      span{
        color: #fff;
        font-weight: 100;
      }
    }

    .modal-body{
      p{
        color: #828282;
      }

      .border-1px{
        padding: 1px;
      }

      .btn-radio{
        color: #CBCCCE;

        :hover{
          color: #CBCCCE;
        }
      }

      .border-silver{
        color: #8C8C8C;
      }
    }
  }

  .modal-quizzes .modal-content {
    background: #18191a !important;
  }

  .questions .close{
    text-shadow: 0 1px 0 #dc3545;
  }

  .swal2-container{
    z-index: 1500 !important;
  }

  // inicio estlilização datepicker

  .react-datepicker {
    border-radius: 15px !important;
  }

  .react-datepicker__month-container {
    background: #17171a !important;
    border-radius: 15px !important;
  }

  .react-datepicker__header {
    background: #17171a !important;
    border-radius: 15px 15px 0 0 !important;
  }

  .react-datepicker__current-month,
  .react-datepicker-time__header,
  .react-datepicker-year-header {
    color: #bdbdbd !important;
  }

  .react-datepicker__month {
    border-radius: 15px !important;
  }

  .react-datepicker__day-name,
  .react-datepicker__day,
  .react-datepicker__time-name {
    color: #bdbdbd !important;
    :hover {
      color: #17171a !important;
    }
  }

  .react-datepicker__month-select,
  .react-datepicker__year-select {
    color: #bdbdbd !important;
    background: #17171a !important;
  }

  // fim estilização datepicker

// estilos complementares do BS4
  body{
    .p-relative{
      position: relative;
    }

    .p-absolute{
      position: absolute;
    }

    .h1, .h2, .h3, .h4, .h5, .h6,
    .h1-sm, .h2-sm, .h3-sm, .h4-sm, .h5-sm, .h6-sm,
    .h1-md, .h2-md, .h3-md, .h4-md, .h5-md, .h6-md,
    .h1-lg, .h2-lg, .h3-lg, .h4-lg, .h5-lg, .h6-lg,
    .h1-xl, .h2-xl, .h3-xl, .h4-xl, .h5-xl, .h6-xl,
    .h1-xxl, .h2-xxl, .h3-xxl, .h4-xxl, .h5-xxl, .h6-xxl {
      margin-bottom: 0.5rem;
      font-weight: 500;
      line-height: 1.2;
    }

    .display-1 {
      font-size: 6rem;
      font-weight: 300;
      line-height: 1.2;
    }

    .display-2 {
      font-size: 5.5rem;
      font-weight: 300;
      line-height: 1.2;
    }

    .display-3 {
      font-size: 4.5rem;
      font-weight: 300;
      line-height: 1.2;
    }

    .display-4 {
      font-size: 3.5rem;
      font-weight: 300;
      line-height: 1.2;
    }

    .h1 {
      font-size: 2.5rem;
    }

    .h2 {
      font-size: 2rem;
    }

    .h3 {
      font-size: 1.75rem;
    }

    .h4 {
      font-size: 1.5rem;
    }

    .h5 {
      font-size: 1.25rem;
    }

    .h6 {
      font-size: 1rem;
    }

    .w-8{
      width: 8%;
    }

    .w-25 {
      width: 25% !important;
    }

    .w-50 {
      width: 50% !important;
    }

    .w-75 {
      width: 75% !important;
    }

    .w-100 {
      width: 100% !important;
    }

    .w-auto {
      width: auto !important;
    }

    .h-25 {
      height: 25% !important;
    }

    .h-50 {
      height: 50% !important;
    }

    .h-75 {
      height: 75% !important;
    }

    .h-100 {
      height: 100% !important;
    }

    .h-auto {
      height: auto !important;
    }

    .mw-100 {
      max-width: 100% !important;
    }

    .mh-100 {
      max-height: 100% !important;
    }

    .min-vw-100 {
      min-width: 100vw !important;
    }

    .min-vh-100 {
      min-height: 100vh !important;
    }

    .vw-100 {
      width: 100vw !important;
    }

    .vh-100 {
      height: 100vh !important;
    }

    .small {
      font-size: 90%;
      font-weight: 400;
    }

    .normal{
      font-size: 100%;
    }

    .big{
      font-size: 120%;
    }

    @media screen and (min-width: 576px){
      .p-sm-relative{
        position: relative;
      }

      .p-sm-absolute{
        position: absolute;
      }

      .display-sm-1 {
        font-size: 6rem;
        font-weight: 300;
        line-height: 1.2;
      }

      .display-sm-2 {
        font-size: 5.5rem;
        font-weight: 300;
        line-height: 1.2;
      }

      .display-sm-3 {
        font-size: 4.5rem;
        font-weight: 300;
        line-height: 1.2;
      }

      .display-sm-4 {
        font-size: 3.5rem;
        font-weight: 300;
        line-height: 1.2;
      }

      .h1-sm {
        font-size: 2.5rem;
      }

      .h2-sm {
        font-size: 2rem;
      }

      .h3-sm {
        font-size: 1.75rem;
      }

      .h4-sm {
        font-size: 1.5rem;
      }

      .h6-sm {
        font-size: 1.25rem;
      }

      .h6-sm {
        font-size: 1rem;
      }

      .w-sm-25 {
        width: 25% !important;
      }

      .w-sm-50 {
        width: 50% !important;
      }

      .w-sm-75 {
        width: 75% !important;
      }

      .w-sm-100 {
        width: 100% !important;
      }

      .w-sm-auto {
        width: auto !important;
      }

      .h-sm-25 {
        height: 25% !important;
      }

      .h-sm-50 {
        height: 50% !important;
      }

      .h-sm-75 {
        height: 75% !important;
      }

      .h-sm-100 {
        height: 100% !important;
      }

      .h-sm-auto {
        height: auto !important;
      }

      .mw-sm-100 {
        max-width: 100% !important;
      }

      .mh-sm-100 {
        max-height: 100% !important;
      }

      .min-vw-sm-100 {
        min-width: 100vw !important;
      }

      .min-vh-sm-100 {
        min-height: 100vh !important;
      }

      .vw-sm-100 {
        width: 100vw !important;
      }

      .vh-sm-100 {
        height: 100vh !important;
      }

      .small-sm {
        font-size: 80%;
        font-weight: 400;
      }

      .normal-sm {
        font-size: 100%;
      }

      .big-sm {
        font-size: 120%;
      }
    }

    @media screen and (max-width: 575px){
      .ReactCreditCard__container {
        width: 90%!important;

      }

      .ReactCreditCard {
        min-width: 290px!important;
      }

      .ReactCreditCard__number {
        font-size: 21px;
      }

      .ReactCreditCard__name {
        font-size: 14px;
      }
    }


    @media screen and (min-width: 768px){
      .font-weight-md-bold{
        font-weight: bold !important;
      }
      .mobile-only{
        display: none;
      }

      .desk-only{
        display: block;
      }

      .p-md-relative{
        position: relative;
      }

      .p-md-absolute{
        position: absolute;
      }

      .display-md-1 {
        font-size: 6rem;
        font-weight: 300;
        line-height: 1.2;
      }

      .display-md-2 {
        font-size: 5.5rem;
        font-weight: 300;
        line-height: 1.2;
      }

      .display-md-3 {
        font-size: 4.5rem;
        font-weight: 300;
        line-height: 1.2;
      }

      .display-md-4 {
        font-size: 3.5rem;
        font-weight: 300;
        line-height: 1.2;
      }

      .h1-md {
        font-size: 2.5rem;
      }

      .h2-md {
        font-size: 2rem;
      }

      .h3-md {
        font-size: 1.75rem;
      }

      .h4-md {
        font-size: 1.5rem;
      }

      .h5-md {
        font-size: 1.25rem;
      }

      .h6-md {
        font-size: 1rem;
      }

      .w-md-25 {
        width: 25% !important;
      }

      .w-md-50 {
        width: 50% !important;
      }

      .w-md-75 {
        width: 75% !important;
      }

      .w-md-100 {
        width: 100% !important;
      }

      .w-md-auto {
        width: auto !important;
      }

      .h-md-25 {
        height: 25% !important;
      }

      .h-md-50 {
        height: 50% !important;
      }

      .h-md-75 {
        height: 75% !important;
      }

      .h-md-100 {
        height: 100% !important;
      }

      .h-md-auto {
        height: auto !important;
      }

      .mw-md-100 {
        max-width: 100% !important;
      }

      .mh-md-100 {
        max-height: 100% !important;
      }

      .min-vw-md-100 {
        min-width: 100vw !important;
      }

      .min-vh-md-100 {
        min-height: 100vh !important;
      }

      .vw-md-100 {
        width: 100vw !important;
      }

      .vh-md-100 {
        height: 100vh !important;
      }

      .small-md {
        font-size: 80%;
        font-weight: 400;
      }

      .normal-md {
        font-size: 100%;
      }

      .big-md {
        font-size: 120%;
      }
    }

    @media screen and (min-width: 992px){
      .p-lg-relative{
        position: relative;
      }

      .p-lg-absolute{
        position: absolute;
      }

      .display-lg-1 {
        font-size: 6rem;
        font-weight: 300;
        line-height: 1.2;
      }

      .display-lg-2 {
        font-size: 5.5rem;
        font-weight: 300;
        line-height: 1.2;
      }

      .display-lg-3 {
        font-size: 4.5rem;
        font-weight: 300;
        line-height: 1.2;
      }

      .display-lg-4 {
        font-size: 3.5rem;
        font-weight: 300;
        line-height: 1.2;
      }

      .h1-lg {
        font-size: 2.5rem;
      }

      .h2-lg {
        font-size: 2rem;
      }

      .h3-lg {
        font-size: 1.75rem;
      }

      .h4-lg {
        font-size: 1.5rem;
      }

      .h5-lg {
        font-size: 1.25rem;
      }

      .h6-lg {
        font-size: 1rem;
      }

      .w-lg-25 {
        width: 25% !important;
      }

      .w-lg-50 {
        width: 50% !important;
      }

      .w-lg-75 {
        width: 75% !important;
      }

      .w-lg-100 {
        width: 100% !important;
      }

      .w-lg-auto {
        width: auto !important;
      }

      .h-lg-25 {
        height: 25% !important;
      }

      .h-lg-50 {
        height: 50% !important;
      }

      .h-lg-75 {
        height: 75% !important;
      }

      .h-lg-100 {
        height: 100% !important;
      }

      .h-lg-auto {
        height: auto !important;
      }

      .mw-lg-100 {
        max-width: 100% !important;
      }

      .mh-lg-100 {
        max-height: 100% !important;
      }

      .min-vw-lg-100 {
        min-width: 100vw !important;
      }

      .min-vh-lg-100 {
        min-height: 100vh !important;
      }

      .vw-lg-100 {
        width: 100vw !important;
      }

      .vh-lg-100 {
        height: 100vh !important;
      }

      .small-lg {
        font-size: 80%;
        font-weight: 400;
      }

      .normal-lg {
        font-size: 100%;
      }

      .big-lg {
        font-size: 120%;
      }
    }

    @media screen and (min-width: 1200px){
      .p-xl-relative{
        position: relative;
      }

      .p-xl-absolute{
        position: absolute;
      }

      .display-xl-1 {
        font-size: 6rem;
        font-weight: 300;
        line-height: 1.2;
      }

      .display-xl-2 {
        font-size: 5.5rem;
        font-weight: 300;
        line-height: 1.2;
      }

      .display-xl-3 {
        font-size: 4.5rem;
        font-weight: 300;
        line-height: 1.2;
      }

      .display-xl-4 {
        font-size: 3.5rem;
        font-weight: 300;
        line-height: 1.2;
      }

      .h1-xl {
        font-size: 2.5rem;
      }

      .h2-xl {
        font-size: 2rem;
      }

      .h3-xl {
        font-size: 1.75rem;
      }

      .h4-xl {
        font-size: 1.5rem;
      }

      .h5-xl {
        font-size: 1.25rem;
      }

      .h6-xl {
        font-size: 1rem;
      }

      .w-xl-25 {
        width: 25% !important;
      }

      .w-xl-50 {
        width: 50% !important;
      }

      .w-xl-75 {
        width: 75% !important;
      }

      .w-xl-100 {
        width: 100% !important;
      }

      .w-xl-auto {
        width: auto !important;
      }

      .h-xl-25 {
        height: 25% !important;
      }

      .h-xl-50 {
        height: 50% !important;
      }

      .h-xl-75 {
        height: 75% !important;
      }

      .h-xl-100 {
        height: 100% !important;
      }

      .h-xl-auto {
        height: auto !important;
      }

      .mw-xl-100 {
        max-width: 100% !important;
      }

      .mh-xl-100 {
        max-height: 100% !important;
      }

      .min-vw-xl-100 {
        min-width: 100vw !important;
      }

      .min-vh-xl-100 {
        min-height: 100vh !important;
      }

      .vw-xl-100 {
        width: 100vw !important;
      }

      .vh-xl-100 {
        height: 100vh !important;
      }

      .small-xl {
        font-size: 80%;
        font-weight: 400;
      }

      .normal-xl {
        font-size: 100%;
      }

      .big-xl {
        font-size: 120%;
      }
    }

    @media screen and (min-width: 1400px){

      .order-xxl-1{
        order: 1
      }
      .order-xxl-2{
        order: 2
      }

      .modal-dialog.modal-lg {
        width: 100%;
      }

      .d-xxl-none{
        display: none !important;
      }

      .d-xxl-block{
        display: block !important;
      }

      .d-xxl-flex{
        display: flex !important;
      }

      .container, .container-lg, .container-md, .container-sm, .container-xl, .container-xxl {
        max-width: 1320px !important;
      }

      .col-xxl-1,
      .col-xxl-2,
      .col-xxl-3,
      .col-xxl-4,
      .col-xxl-5,
      .col-xxl-6,
      .col-xxl-7,
      .col-xxl-8,
      .col-xxl-9,
      .col-xxl-10,
      .col-xxl-11,
      .col-xxl-12,
      .col-xxl,
      .col-xxl-auto {
        position: relative;
        width: 100%;
        padding-right: 15px;
        padding-left: 15px;
      }

      .col-xxl {
        -ms-flex-preferred-size: 0;
        flex-basis: 0;
        -ms-flex-positive: 1;
        flex-grow: 1;
        max-width: 100%;
      }
      .col-xxl-auto {
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        width: auto;
        max-width: 100%;
      }
      .col-xxl-1 {
        -ms-flex: 0 0 8.333333%;
        flex: 0 0 8.333333%;
        max-width: 8.333333%;
      }
      .col-xxl-2 {
        -ms-flex: 0 0 16.666667%;
        flex: 0 0 16.666667%;
        max-width: 16.666667%;
      }
      .col-xxl-3 {
        -ms-flex: 0 0 25%;
        flex: 0 0 25%;
        max-width: 25%;
      }
      .col-xxl-4 {
        -ms-flex: 0 0 33.333333%;
        flex: 0 0 33.333333%;
        max-width: 33.333333%;
      }
      .col-xxl-5 {
        -ms-flex: 0 0 41.666667%;
        flex: 0 0 41.666667%;
        max-width: 41.666667%;
      }
      .col-xxl-6 {
        -ms-flex: 0 0 50%;
        flex: 0 0 50%;
        max-width: 50%;
      }
      .col-xxl-7 {
        -ms-flex: 0 0 58.333333%;
        flex: 0 0 58.333333%;
        max-width: 58.333333%;
      }
      .col-xxl-8 {
        -ms-flex: 0 0 66.666667%;
        flex: 0 0 66.666667%;
        max-width: 66.666667%;
      }
      .col-xxl-9 {
        -ms-flex: 0 0 75%;
        flex: 0 0 75%;
        max-width: 75%;
      }
      .col-xxl-10 {
        -ms-flex: 0 0 83.333333%;
        flex: 0 0 83.333333%;
        max-width: 83.333333%;
      }
      .col-xxl-11 {
        -ms-flex: 0 0 91.666667%;
        flex: 0 0 91.666667%;
        max-width: 91.666667%;
      }
      .col-xxl-12 {
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        max-width: 100%;
      }

      .p-xxl-relative{
        position: relative;
      }

      .p-xxl-absolute{
        position: absolute;
      }

      .display-xxl-1 {
        font-size: 6rem;
        font-weight: 300;
        line-height: 1.2;
      }

      .display-xxl-2 {
        font-size: 5.5rem;
        font-weight: 300;
        line-height: 1.2;
      }

      .display-xxl-3 {
        font-size: 4.5rem;
        font-weight: 300;
        line-height: 1.2;
      }

      .display-xxl-4 {
        font-size: 3.5rem;
        font-weight: 300;
        line-height: 1.2;
      }

      .h1-xxl {
        font-size: 2.5rem;
      }

      .h2-xxl {
        font-size: 2rem;
      }

      .h3-xxl {
        font-size: 1.75rem;
      }

      .h4-xxl {
        font-size: 1.5rem;
      }

      .h5-xxl {
        font-size: 1.25rem;
      }

      .h6-xxl {
        font-size: 1rem;
      }

      .w-xxl-25 {
        width: 25% !important;
      }

      .w-xxl-50 {
        width: 50% !important;
      }

      .w-xxl-75 {
        width: 75% !important;
      }

      .w-xxl-100 {
        width: 100% !important;
      }

      .w-xxl-auto {
        width: auto !important;
      }

      .h-xxl-25 {
        height: 25% !important;
      }

      .h-xxl-50 {
        height: 50% !important;
      }

      .h-xxl-75 {
        height: 75% !important;
      }

      .h-xxl-100 {
        height: 100% !important;
      }

      .h-xxl-auto {
        height: auto !important;
      }

      .mw-xxl-100 {
        max-width: 100% !important;
      }

      .mh-xxl-100 {
        max-height: 100% !important;
      }

      .min-vw-xxl-100 {
        min-width: 100vw !important;
      }

      .min-vh-xxl-100 {
        min-height: 100vh !important;
      }

      .vw-xxl-100 {
        width: 100vw !important;
      }

      .vh-xxl-100 {
        height: 100vh !important;
      }

      .small-xxl {
        font-size: 80%;
        font-weight: 400;
      }

      .normal-xxl {
        font-size: 100%;
      }

      .big-xxl {
        font-size: 120%;
      }

      .d-xxl-flex{
        display: flex;
      }

      .justify-content-xxl-start {
        -ms-flex-pack: start !important;
        justify-content: flex-start !important;
      }
      .justify-content-xxl-end {
        -ms-flex-pack: end !important;
        justify-content: flex-end !important;
      }
      .justify-content-xxl-center {
        -ms-flex-pack: center !important;
        justify-content: center !important;
      }
      .justify-content-xxl-between {
        -ms-flex-pack: justify !important;
        justify-content: space-between !important;
      }
      .justify-content-xxl-around {
        -ms-flex-pack: distribute !important;
        justify-content: space-around !important;
      }
      .align-items-xxl-start {
        -ms-flex-align: start !important;
        align-items: flex-start !important;
      }
      .align-items-xxl-end {
        -ms-flex-align: end !important;
        align-items: flex-end !important;
      }
      .align-items-xxl-center {
        -ms-flex-align: center !important;
        align-items: center !important;
      }
      .align-items-xxl-baseline {
        -ms-flex-align: baseline !important;
        align-items: baseline !important;
      }
      .align-items-xxl-stretch {
        -ms-flex-align: stretch !important;
        align-items: stretch !important;
      }
      .align-content-xxl-start {
        -ms-flex-line-pack: start !important;
        align-content: flex-start !important;
      }
      .align-content-xxl-end {
        -ms-flex-line-pack: end !important;
        align-content: flex-end !important;
      }
      .align-content-xxl-center {
        -ms-flex-line-pack: center !important;
        align-content: center !important;
      }
      .align-content-xxl-between {
        -ms-flex-line-pack: justify !important;
        align-content: space-between !important;
      }
      .align-content-xxl-around {
        -ms-flex-line-pack: distribute !important;
        align-content: space-around !important;
      }
      .align-content-xxl-stretch {
        -ms-flex-line-pack: stretch !important;
        align-content: stretch !important;
      }
      .align-self-xxl-auto {
        -ms-flex-item-align: auto !important;
        align-self: auto !important;
      }
      .align-self-xxl-start {
        -ms-flex-item-align: start !important;
        align-self: flex-start !important;
      }
      .align-self-xxl-end {
        -ms-flex-item-align: end !important;
        align-self: flex-end !important;
      }
      .align-self-xxl-center {
        -ms-flex-item-align: center !important;
        align-self: center !important;
      }
      .align-self-xxl-baseline {
        -ms-flex-item-align: baseline !important;
        align-self: baseline !important;
      }
      .align-self-xxl-stretch {
        -ms-flex-item-align: stretch !important;
        align-self: stretch !important;
      }

      .m-xxl-0 {
        margin: 0 !important;
      }
      .mt-xxl-0,
      .my-xxl-0 {
        margin-top: 0 !important;
      }
      .mr-xxl-0,
      .mx-xxl-0 {
        margin-right: 0 !important;
      }
      .mb-xxl-0,
      .my-xxl-0 {
        margin-bottom: 0 !important;
      }
      .ml-xxl-0,
      .mx-xxl-0 {
        margin-left: 0 !important;
      }
      .m-xxl-1 {
        margin: 0.25rem !important;
      }
      .mt-xxl-1,
      .my-xxl-1 {
        margin-top: 0.25rem !important;
      }
      .mr-xxl-1,
      .mx-xxl-1 {
        margin-right: 0.25rem !important;
      }
      .mb-xxl-1,
      .my-xxl-1 {
        margin-bottom: 0.25rem !important;
      }
      .ml-xxl-1,
      .mx-xxl-1 {
        margin-left: 0.25rem !important;
      }
      .m-xxl-2 {
        margin: 0.5rem !important;
      }
      .mt-xxl-2,
      .my-xxl-2 {
        margin-top: 0.5rem !important;
      }
      .mr-xxl-2,
      .mx-xxl-2 {
        margin-right: 0.5rem !important;
      }
      .mb-xxl-2,
      .my-xxl-2 {
        margin-bottom: 0.5rem !important;
      }
      .ml-xxl-2,
      .mx-xxl-2 {
        margin-left: 0.5rem !important;
      }
      .m-xxl-3 {
        margin: 1rem !important;
      }
      .mt-xxl-3,
      .my-xxl-3 {
        margin-top: 1rem !important;
      }
      .mr-xxl-3,
      .mx-xxl-3 {
        margin-right: 1rem !important;
      }
      .mb-xxl-3,
      .my-xxl-3 {
        margin-bottom: 1rem !important;
      }
      .ml-xxl-3,
      .mx-xxl-3 {
        margin-left: 1rem !important;
      }
      .m-xxl-4 {
        margin: 1.5rem !important;
      }
      .mt-xxl-4,
      .my-xxl-4 {
        margin-top: 1.5rem !important;
      }
      .mr-xxl-4,
      .mx-xxl-4 {
        margin-right: 1.5rem !important;
      }
      .mb-xxl-4,
      .my-xxl-4 {
        margin-bottom: 1.5rem !important;
      }
      .ml-xxl-4,
      .mx-xxl-4 {
        margin-left: 1.5rem !important;
      }
      .m-xxl-5 {
        margin: 3rem !important;
      }
      .mt-xxl-5,
      .my-xxl-5 {
        margin-top: 3rem !important;
      }
      .mr-xxl-5,
      .mx-xxl-5 {
        margin-right: 3rem !important;
      }
      .mb-xxl-5,
      .my-xxl-5 {
        margin-bottom: 3rem !important;
      }
      .ml-xxl-5,
      .mx-xxl-5 {
        margin-left: 3rem !important;
      }
      .p-xxl-0 {
        padding: 0 !important;
      }
      .pt-xxl-0,
      .py-xxl-0 {
        padding-top: 0 !important;
      }
      .pr-xxl-0,
      .px-xxl-0 {
        padding-right: 0 !important;
      }
      .pb-xxl-0,
      .py-xxl-0 {
        padding-bottom: 0 !important;
      }
      .pl-xxl-0,
      .px-xxl-0 {
        padding-left: 0 !important;
      }
      .p-xxl-1 {
        padding: 0.25rem !important;
      }
      .pt-xxl-1,
      .py-xxl-1 {
        padding-top: 0.25rem !important;
      }
      .pr-xxl-1,
      .px-xxl-1 {
        padding-right: 0.25rem !important;
      }
      .pb-xxl-1,
      .py-xxl-1 {
        padding-bottom: 0.25rem !important;
      }
      .pl-xxl-1,
      .px-xxl-1 {
        padding-left: 0.25rem !important;
      }
      .p-xxl-2 {
        padding: 0.5rem !important;
      }
      .pt-xxl-2,
      .py-xxl-2 {
        padding-top: 0.5rem !important;
      }
      .pr-xxl-2,
      .px-xxl-2 {
        padding-right: 0.5rem !important;
      }
      .pb-xxl-2,
      .py-xxl-2 {
        padding-bottom: 0.5rem !important;
      }
      .pl-xxl-2,
      .px-xxl-2 {
        padding-left: 0.5rem !important;
      }
      .p-xxl-3 {
        padding: 1rem !important;
      }
      .pt-xxl-3,
      .py-xxl-3 {
        padding-top: 1rem !important;
      }
      .pr-xxl-3,
      .px-xxl-3 {
        padding-right: 1rem !important;
      }
      .pb-xxl-3,
      .py-xxl-3 {
        padding-bottom: 1rem !important;
      }
      .pl-xxl-3,
      .px-xxl-3 {
        padding-left: 1rem !important;
      }
      .p-xxl-4 {
        padding: 1.5rem !important;
      }
      .pt-xxl-4,
      .py-xxl-4 {
        padding-top: 1.5rem !important;
      }
      .pr-xxl-4,
      .px-xxl-4 {
        padding-right: 1.5rem !important;
      }
      .pb-xxl-4,
      .py-xxl-4 {
        padding-bottom: 1.5rem !important;
      }
      .pl-xxl-4,
      .px-xxl-4 {
        padding-left: 1.5rem !important;
      }
      .p-xxl-5 {
        padding: 3rem !important;
      }
      .pt-xxl-5,
      .py-xxl-5 {
        padding-top: 3rem !important;
      }
      .pr-xxl-5,
      .px-xxl-5 {
        padding-right: 3rem !important;
      }
      .pb-xxl-5,
      .py-xxl-5 {
        padding-bottom: 3rem !important;
      }
      .pl-xxl-5,
      .px-xxl-5 {
        padding-left: 3rem !important;
      }
      .m-xxl-n1 {
        margin: -0.25rem !important;
      }
      .mt-xxl-n1,
      .my-xxl-n1 {
        margin-top: -0.25rem !important;
      }
      .mr-xxl-n1,
      .mx-xxl-n1 {
        margin-right: -0.25rem !important;
      }
      .mb-xxl-n1,
      .my-xxl-n1 {
        margin-bottom: -0.25rem !important;
      }
      .ml-xxl-n1,
      .mx-xxl-n1 {
        margin-left: -0.25rem !important;
      }
      .m-xxl-n2 {
        margin: -0.5rem !important;
      }
      .mt-xxl-n2,
      .my-xxl-n2 {
        margin-top: -0.5rem !important;
      }
      .mr-xxl-n2,
      .mx-xxl-n2 {
        margin-right: -0.5rem !important;
      }
      .mb-xxl-n2,
      .my-xxl-n2 {
        margin-bottom: -0.5rem !important;
      }
      .ml-xxl-n2,
      .mx-xxl-n2 {
        margin-left: -0.5rem !important;
      }
      .m-xxl-n3 {
        margin: -1rem !important;
      }
      .mt-xxl-n3,
      .my-xxl-n3 {
        margin-top: -1rem !important;
      }
      .mr-xxl-n3,
      .mx-xxl-n3 {
        margin-right: -1rem !important;
      }
      .mb-xxl-n3,
      .my-xxl-n3 {
        margin-bottom: -1rem !important;
      }
      .ml-xxl-n3,
      .mx-xxl-n3 {
        margin-left: -1rem !important;
      }
      .m-xxl-n4 {
        margin: -1.5rem !important;
      }
      .mt-xxl-n4,
      .my-xxl-n4 {
        margin-top: -1.5rem !important;
      }
      .mr-xxl-n4,
      .mx-xxl-n4 {
        margin-right: -1.5rem !important;
      }
      .mb-xxl-n4,
      .my-xxl-n4 {
        margin-bottom: -1.5rem !important;
      }
      .ml-xxl-n4,
      .mx-xxl-n4 {
        margin-left: -1.5rem !important;
      }
      .m-xxl-n5 {
        margin: -3rem !important;
      }
      .mt-xxl-n5,
      .my-xxl-n5 {
        margin-top: -3rem !important;
      }
      .mr-xxl-n5,
      .mx-xxl-n5 {
        margin-right: -3rem !important;
      }
      .mb-xxl-n5,
      .my-xxl-n5 {
        margin-bottom: -3rem !important;
      }
      .ml-xxl-n5,
      .mx-xxl-n5 {
        margin-left: -3rem !important;
      }
      .m-xxl-auto {
        margin: auto !important;
      }
      .mt-xxl-auto,
      .my-xxl-auto {
        margin-top: auto !important;
      }
      .mr-xxl-auto,
      .mx-xxl-auto {
        margin-right: auto !important;
      }
      .mb-xxl-auto,
      .my-xxl-auto {
        margin-bottom: auto !important;
      }
      .ml-xxl-auto,
      .mx-xxl-auto {
        margin-left: auto !important;
      }
    }
  }
`;
