import React, { useEffect, useMemo, useState } from 'react';
import { Link, NavLink, useLocation } from 'react-router-dom';

import { useAuth } from '~/hooks/Auth';

import { Container, Menu } from './styles';
import * as WiserrIcons from '../WiserrIcons';

import logo from '~/assets/logotipos/logo.svg';

const Header: React.FC = () => {
  const location = useLocation();
  const { user, signOut } = useAuth();
  const [active, setActive] = useState(false);
  const [menuHovered, setMenuHovered] = useState('');

  useEffect(() => {
    setActive(false);
  }, [location]);

  const menus = useMemo(() => {
    const data = user.type.usersTypesMenus.map((userTypeMenu) => {
      return {
        id: userTypeMenu.menu.id,
        icon: WiserrIcons[userTypeMenu.menu.icon as keyof typeof WiserrIcons],
        link: userTypeMenu.menu.link,
        title: userTypeMenu.menu.title,
        isHover: menuHovered === userTypeMenu.menu.id,
        isActive: location.pathname.includes(userTypeMenu.menu.link),
      };
    });

    return data;
  }, [location.pathname, menuHovered, user.type.usersTypesMenus]);

  return (
    <Container className="d-flex flex-lg-column justify-content-between justify-content-lg-around align-items-center text-center menu-header">
      <div className="d-flex flex-column py-lg-3">
        <Link to="/">
          <img src={logo} alt="logo" className="pl-2 pl-lg-0 w-75" />
        </Link>
      </div>
      <Menu active={active} className="w-auto w-lg-100">
        <button
          type="button"
          className="bg-transparent d-lg-none border-0 mx-3"
          onClick={() => setActive(!active)}
        >
          <span />
          <span />
          <span />
        </button>
        <div className="overflow d-flex flex-column p-absolute p-lg-relative menu-group active">
          <div className="d-flex flex-column pl-4 px-lg-0 py-5 py-lg-1 py-xl-3 h-lg-100 menu">
            {menus.map((menu) => (
              <NavLink
                key={menu.id}
                to={`${process.env.PUBLIC_URL}/${menu.link}`}
                className="menu-item px-2 px-lg-0 py-2 py-lg-3 py-xxl-4 w-100 w-lg-auto d-flex flex-lg-column justify-content-lg-center align-items-center"
                activeClassName="active"
                onMouseEnter={() => setMenuHovered(menu.id)}
                onMouseLeave={() => setMenuHovered('')}
              >
                <menu.icon
                  size={24}
                  color={menu.isHover || menu.isActive ? '#CBCCCE' : '#8C8C8C'}
                  className="d-none d-lg-block"
                />
                <small>{menu.title}</small>
              </NavLink>
            ))}
          </div>
          <div className="d-flex flex-column mb-5 mb-lg-0 pl-4 px-lg-0 py-3 logoff">
            <button
              type="button"
              onClick={() => signOut()}
              className="border-0 menu-item px-2 px-lg-0 py-2 py-lg-2 py-xxl-3 w-100 w-lg-auto d-flex flex-lg-column justify-content-lg-center align-items-center"
            >
              <div className="logout ml-0 mx-lg-auto" />
              <small>Logout</small>
            </button>
          </div>
        </div>
      </Menu>
    </Container>
  );
};

export default Header;
