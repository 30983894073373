import styled from 'styled-components';
import { lighten } from 'polished';

interface IThumbnailProps {
  src: string;
  alt: string;
}

export const Container = styled.div`
  h2.text-gray {
    color: #ababab;
  }
`;

export const Welcome = styled.div`
  h1 {
    color: #e4e4e4;
  }
  p {
    color: #858585;
  }

  @media screen and (max-width: 1399px) {
    p {
      font-size: 14px;
    }
  }

  @media screen and (max-width: 991px) {
    p {
      font-size: 16px;
    }
  }

  @media screen and (max-width: 400px) {
    h1 {
      font-size: 24px !important;
    }
  }
`;

export const AddButton = styled.button`
  border-radius: 50px;
  background-color: #202020;
  border: solid 1px #606060;
  color: #e4e4e4 !important;

  :hover {
    color: ${lighten(0.1, '#e4e4e4')}!important;
    border: solid 1px ${lighten(0.1, '#606060')};
    img {
      filter: brightness(1.5);
    }
  }
`;

export const Card = styled.div`
  border-radius: 20px;
  overflow: hidden;
  background-color: #202020;
  min-height: 526px;

  textarea {
    height: 29px;
    max-height: 180px;
    resize: none;
    overflow: hidden;
    cursor: pointer;
    background-color: transparent;
    border-radius: 5px;

    :focus {
      outline: none;
      cursor: unset;
      background-color: #18191a;
      box-shadow: none;
    }

    ::placeholder {
      color: #828282;
    }
  }

  .btn-share {
    background-color: rgb(44, 44, 44) !important;
    border: none;
    cursor: unset;
  }

  .btn-copy {
    cursor: unset;
  }

  .label-image {
    height: 222px;
    background-color: #18191a;
    border-radius: 20px;
  }

  .username {
    color: #969696;
  }

  .tinytext {
    color: #858585;
  }

  small {
    color: #cbccce;
  }

  button:hover:not(.btn-share, .btn-copy) {
    filter: brightness(1.3);

    img {
      filter: brightness(1.5);
    }
  }

  button.btn-top {
    padding: 1px;
    width: 40%;
    text-align: center;
    background-color: rgb(44, 44, 44) !important;
    border: none;

    :hover {
      text-decoration: none;
    }
  }

  button.border-delete {
    color: #ff4d77;
  }

  img {
    border-radius: 20px;
  }

  .border-golden {
    padding: 1px;
  }

  @media screen and (max-width: 1399px) {
    min-height: 480px;
    .label-image {
      height: 250px;
    }
  }

  @media screen and (max-width: 768px) {
    .label-image {
      height: 300px;
    }
  }

  @media screen and (max-width: 500px) {
    .label-image {
      height: 220px;
    }
  }

  @media screen and (max-width: 350px) {
    button.btn-top {
      width: 37%;
    }
  }
`;

export const LinkCopy = styled.div`
  background: #343434;

  p {
    color: #e6e7e7;
  }
`;

export const Thumbnail = styled.div<IThumbnailProps>`
  background-color: #18191a;
  border-radius: 10px;
  background-image: url(${(props) => props.src});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  @media screen and (max-width: 767px) {
    height: 200px;
  }

  @media screen and (max-width: 575px) {
    height: 160px;
  }
`;
