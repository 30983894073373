import React, {
  useState,
  useEffect,
  useCallback,
  ChangeEvent,
  useRef,
} from 'react';
import { Form } from '@unform/web';
import { useHistory } from 'react-router-dom';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';
import Swal from 'sweetalert2';

import api from '~/services/api';

import { Container, VideoInfo, CourseInfo, Img, VideoPreview } from './styles';
import Input from '~/components/Input';
import Textarea from '~/components/Textarea';
import Player from '~/components/Player';
import InputCategory, { IValue } from '~/components/InputCategory';
import InputMask from '~/components/InputMask';
import CoursesActionTitle from '~/components/CoursesActionTitle';
import WaveForm from '~/components/WaveForm';

import videoThumb from '~/assets/icons/video_thumb.svg';
import audioThumb from '~/assets/icons/audio_thumb.svg';
import imgThumb from '~/assets/icons/img_thumb.svg';
import getValidationErros from '~/utils/getValidationsErrors';
import uploadVideo from '~/utils/uploadVideo';
import uploadAudio from '~/utils/uploadAudio';
import Loading from '~/components/Loading';

interface courseFormData {
  thumbnail_title: string;
  title: string;
  subtitle: string;
  presented_by: string;
  description: string;
  categories: string;
  slug: string;
  page_title: string;
  meta_description: string;
  price: string;
  show_promotion_price: string;
  promotion_price?: string;
}

const CoursesRegister: React.FC = () => {
  const history = useHistory();
  const formRef = useRef<FormHandles>(null);
  const [thumbnail, setThumbnail] = useState('');
  const [thumbnailSelected, setThumbnailSelected] = useState<File | null>(null);
  const [video, setVideo] = useState('');
  const [audio, setAudio] = useState('');
  const [videoSelected, setVideoSelected] = useState<File | null>(null);
  const [audioSelected, setAudioSelected] = useState<File | null>(null);
  const [videoDuration, setVideoDuration] = useState('');
  const [audioDuration, setAudioDuration] = useState('');
  const [url, setUrl] = useState('');
  const [loading, setLoading] = useState(false);
  const [showPromotionPrice, setShowPromotionPrice] = useState('no');
  const [categoriesData, setCategoriesData] = useState<IValue[]>([]);
  const [status, setStatus] = useState('');
  const [percent, setPercent] = useState(0);
  const [showPercent, setShowPercent] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleBlurDuration = useCallback((e) => {
    const duration = e.target.value;
    const [hours, minutes, seconds] = duration.split(':');

    let newHours = hours;
    let newMinutes = minutes;
    let newSeconds = seconds;

    if (newHours) {
      if (newHours.length < 2) {
        newHours += '0';
      }
    } else {
      newHours = '00';
    }

    if (newMinutes) {
      if (newMinutes > 59) {
        newMinutes = 59;
      } else if (newMinutes.length < 2) {
        newMinutes += '0';
      }
    } else {
      newMinutes = '00';
    }

    if (newSeconds) {
      if (newSeconds > 59) {
        newSeconds = 59;
      } else if (newSeconds.length < 2) {
        newSeconds += '0';
      }
    } else {
      newSeconds = '00';
    }

    setVideoDuration(`${newHours}:${newMinutes}:${newSeconds}`);
  }, []);

  const handleBlurAudioDuration = useCallback((e) => {
    const duration = e.target.value;
    const [hours, minutes, seconds] = duration.split(':');

    let newHours = hours;
    let newMinutes = minutes;
    let newSeconds = seconds;

    if (newHours) {
      if (newHours.length < 2) {
        newHours += '0';
      }
    } else {
      newHours = '00';
    }

    if (newMinutes) {
      if (newMinutes > 59) {
        newMinutes = 59;
      } else if (newMinutes.length < 2) {
        newMinutes += '0';
      }
    } else {
      newMinutes = '00';
    }

    if (newSeconds) {
      if (newSeconds > 59) {
        newSeconds = 59;
      } else if (newSeconds.length < 2) {
        newSeconds += '0';
      }
    } else {
      newSeconds = '00';
    }

    setAudioDuration(`${newHours}:${newMinutes}:${newSeconds}`);
  }, []);

  useEffect(() => {
    if (video) {
      setTimeout(() => {
        const videoElement = document.querySelector(
          '#videoInfo video'
        ) as HTMLVideoElement;
        if (videoElement) {
          videoElement.onloadedmetadata = () => {
            const { duration } = videoElement;
            const hrs = `0${~~(duration / 3600)}`.slice(-2);
            const mins = `0${~~((duration % 3600) / 60)}`.slice(-2);
            const secs = `0${~~(duration % 60)}`.slice(-2);
            const durationData = `${hrs}:${mins}:${secs}`;
            setVideoDuration(durationData);
            const data = {
              target: {
                value: durationData,
              },
            };
            handleBlurDuration(data);
          };
        }
      }, 100);
    }
  }, [handleBlurDuration, video]);

  const handleSelectThumbnail = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      if (e.target.files) {
        const file = e.target.files[0];
        if (file) {
          setThumbnail(URL.createObjectURL(file));
          setThumbnailSelected(file);
        } else {
          setThumbnail('');
          setThumbnailSelected(null);
        }
      }
    },
    []
  );

  const handleSelectVideo = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const file = e.target.files[0];
      if (file) {
        setVideo(URL.createObjectURL(file));
        setVideoSelected(file);
      } else {
        setVideoDuration('');
        setVideo('');
        setVideoSelected(null);
      }
    }
  }, []);

  const handleSelectAudio = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const file = e.target.files[0];
      if (file) {
        setAudio(URL.createObjectURL(file));
        setAudioSelected(file);
      } else {
        setAudioDuration('');
        setAudio('');
        setAudioSelected(null);
      }
    }
  }, []);

  const handleRemoveVideo = useCallback(() => {
    setVideoDuration('');
    setVideo('');
    setVideoSelected(null);
  }, []);

  const handleRemoveAudio = useCallback(() => {
    setAudioDuration('');
    setAudio('');
    setAudioSelected(null);
  }, []);

  const handleChangeDuration = useCallback((e) => {
    const duration = e.target.value;
    const [hours, minutes, seconds] = duration.split(':');
    let newMinutes = minutes;
    let newSeconds = seconds;

    if (newMinutes > 59) {
      newMinutes = 59;
    }

    if (newSeconds > 59) {
      newSeconds = 59;
    }

    setVideoDuration(`${hours}:${newMinutes}:${newSeconds}`);
  }, []);

  const handleChangeAudioDuration = useCallback((e) => {
    const duration = e.target.value;
    const [hours, minutes, seconds] = duration.split(':');
    let newMinutes = minutes;
    let newSeconds = seconds;

    if (newMinutes > 59) {
      newMinutes = 59;
    }

    if (newSeconds > 59) {
      newSeconds = 59;
    }

    setAudioDuration(`${hours}:${newMinutes}:${newSeconds}`);
  }, []);

  const changeShowPromotionPrice = useCallback((e) => {
    setShowPromotionPrice(e.target.value);
  }, []);

  const handleChangeTitle = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setUrl(
      e.target.value
        .replace(/[àáâãäå]/g, 'a')
        .replace(/æ/g, 'ae')
        .replace(/ç/g, 'c')
        .replace(/[èéêë]/g, 'e')
        .replace(/[ìíîï]/g, 'i')
        .replace(/ñ/g, 'n')
        .replace(/[òóôõö]/g, 'o')
        .replace(/œ/g, 'oe')
        .replace(/[ùúûü]/g, 'u')
        .replace(/[ýÿ]/g, 'y')
        .replace(/[^a-zA-Z0-9 -]/g, '')
        .replace(/ /g, '-')
        .toLowerCase()
    );
  }, []);

  const handleChangeSlug = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setUrl(
      e.target.value
        .replace(/[àáâãäå]/g, 'a')
        .replace(/æ/g, 'ae')
        .replace(/ç/g, 'c')
        .replace(/[èéêë]/g, 'e')
        .replace(/[ìíîï]/g, 'i')
        .replace(/ñ/g, 'n')
        .replace(/[òóôõö]/g, 'o')
        .replace(/œ/g, 'oe')
        .replace(/[ùúûü]/g, 'u')
        .replace(/[ýÿ]/g, 'y')
        .replace(/[^a-zA-Z0-9 -]/g, '')
        .replace(/ /g, '-')
        .toLowerCase()
    );
  }, []);

  const handleSelectCategories = useCallback((data) => {
    setCategoriesData(data);
  }, []);

  const handleSubmit = useCallback(
    async (data: courseFormData) => {
      setStatus('Uploading thumbnail...');
      setLoading(true);
      try {
        formRef.current?.setErrors({});
        const schema = Yup.object().shape({
          title: Yup.string().required('Title is a required'),
          subtitle: Yup.string().required('Subtitle is a required'),
          presented_by: Yup.string().required('Presented by is a required'),
          description: Yup.string().required('Description is a required'),
          categories: Yup.string().when('$categoryFilled', {
            is: (categoryFilled: boolean) => categoryFilled,
            then: Yup.string(),
            otherwise: Yup.string().required('Categories is a required'),
          }),
          slug: Yup.string().required('URL is a required'),
          page_title: Yup.string().required('Page Title is a required'),
          meta_description: Yup.string().required(
            'Meta Description is a required'
          ),
          thumbnail: Yup.string(),
          thumbnail_title: Yup.string(),
          video: Yup.string(),
          duration: Yup.string(),
          audio: Yup.string(),
          audioDuration: Yup.string(),
          price: Yup.string().required('Price Short Title is a required'),
          show_promotion_price: Yup.string(),
          promotion_price: Yup.string().when('$exist', {
            is: (exist: boolean) => exist,
            then: Yup.string().required('Promotion Price is a required'),
            otherwise: Yup.string(),
          }),
        });

        await schema.validate(data, {
          abortEarly: false,
          context: {
            exist: showPromotionPrice === 'yes',
            categoryFilled: categoriesData.length > 0,
          },
        });

        let responseThumbnails;
        let responseVideo;
        let responseAudio;

        if (thumbnailSelected) {
          const thumbnailData = new FormData();
          thumbnailData.append('thumbnail', thumbnailSelected as File);
          thumbnailData.append('title', data.thumbnail_title);
          responseThumbnails = await api.post('thumbnails', thumbnailData);
        }

        if (videoSelected) {
          setStatus('Uploading video...');
          setPercent(0);
          setShowPercent(true);
          responseVideo = await uploadVideo({
            course_title: data.title,
            file: videoSelected,
            duration: videoDuration,
            setPercent,
            setStatus,
          });
        }

        if (audioSelected) {
          setStatus('Uploading audio...');
          setPercent(0);
          setShowPercent(true);
          responseAudio = await uploadAudio({
            course_title: data.title,
            file: audioSelected,
            duration: audioDuration,
            setPercent,
            setStatus,
          });
        }

        setStatus('Creating course...');
        const {
          title,
          subtitle,
          presented_by,
          description,
          slug,
          page_title,
          meta_description,
          price,
          promotion_price,
        } = data;

        const formData = {
          title,
          subtitle,
          presented_by,
          description,
          slug,
          page_title,
          meta_description,
          price: parseFloat(price.replace(/[$,]/g, '')),
          show_promotion_price: showPromotionPrice === 'yes',
          promotion_price: promotion_price
            ? parseFloat(promotion_price.replace(/[$,]/g, ''))
            : undefined,
          thumbnail_id: '' || undefined,
          video_id: '' || undefined,
          audio_id: '' || undefined,
        };

        if (responseThumbnails) {
          formData.thumbnail_id = responseThumbnails.data.id;
        }

        if (responseVideo) {
          formData.video_id = responseVideo.data.id;
        }

        if (responseAudio) {
          formData.audio_id = responseAudio.data.id;
        }

        const response = await api.post('courses', formData);

        if (response.data) {
          setStatus('Adjusting categories...');
          categoriesData.forEach(async (category) => {
            if (category.id) {
              api
                .get(`courses-categories/${response.data.id}/${category.id}`)
                .catch(async () => {
                  const coursesCategoriesFormData = {
                    course_id: response.data.id,
                    category_id: category.id,
                  };
                  await api.post(
                    'courses-categories',
                    coursesCategoriesFormData
                  );
                });
            }
          });

          Swal.fire(
            'Good job!',
            'Course successfully registered.',
            'success'
          ).then(() => {
            setLoading(false);
            history.push(
              `${process.env.PUBLIC_URL}/courses/${response.data.slug}/lessons`
            );
          });
        }
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErros(error);
          formRef.current?.setErrors(errors);
          setLoading(false);
        } else {
          Swal.fire(
            'Opss...',
            'An error has occurred, please try again.',
            'error'
          ).then(() => setLoading(false));
        }
      }
    },
    [
      audioDuration,
      audioSelected,
      categoriesData,
      history,
      showPromotionPrice,
      thumbnailSelected,
      videoDuration,
      videoSelected,
    ]
  );

  return (
    <Container>
      <div className="container-fluid container-xxl bg">
        <Form ref={formRef} onSubmit={handleSubmit} className="row">
          <div className="col-12 px-4 pt-5 text-center">
            <CoursesActionTitle
              lightTitle="Create"
              boldTitle="Course"
              description="Surprisingly, there is a very vocal faction of the design
                community that wants to see filler text banished the original
                sources his will just slow down the design process."
            />
          </div>
          <div className="col-12">
            <div className="row justify-content-center my-5">
              <div className="col-10 col-lg-6 col-xl-5 overflow-auto pb-3 pb-sm-0">
                <div className="d-flex ml-auto min-width">
                  <div className="w-100 text-center btn-light-grey step-course rounded-pill">
                    <span className="d-block px-3 py-3">Create Course</span>
                  </div>
                  <hr className="w-25 w-md-50 my-auto border-gray mx-2" />
                  <div className="w-100 text-center btn-grey step-lesson rounded-pill">
                    <span className="px-3 py-3 h-100 d-flex align-items-center justify-content-center">
                      Lessons
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-4 col-xxl-3 px-0 px-lg-3 pr-xl-0">
            <VideoInfo
              id="videoInfo"
              className="d-flex flex-wrap flex-xl-column justify-content-between"
            >
              <div className="col-md-6 col-lg-4 col-xl-12 pr-md-4 pl-lg-0 px-xl-0 bd-right">
                <label htmlFor="thumbnail" className="small">
                  Thumbnail
                </label>
                <label
                  htmlFor="thumbnail"
                  className="btn bg-gray text-center w-100 p-0"
                >
                  {thumbnail ? (
                    <Img src={thumbnail} className="w-100" />
                  ) : (
                    <div className="d-flex justify-content-center align-items-center py-5">
                      <img
                        src={imgThumb}
                        alt="AddThumbnail"
                        className="w-25 mb-2"
                      />
                    </div>
                  )}
                </label>
                <Input
                  type="file"
                  id="thumbnail"
                  name="thumbnail"
                  className="d-none"
                  onChange={handleSelectThumbnail}
                />
                <div className="mt-3 mb-xl-4">
                  <label htmlFor="thumbnail_title" className="small">
                    Thumbnail Short Title
                  </label>
                  <Input
                    id="thumbnail_title"
                    name="thumbnail_title"
                    className="py-3"
                  />
                </div>
              </div>
              <hr className="d-none d-xl-block hr-gray my-4" />
              <div className="col-md-6 col-lg-4 col-xl-12 pl-md-4 pr-lg-0 px-xl-0 mt-5 mt-md-0">
                <label htmlFor="video" className="small">
                  Preview of the video
                </label>
                {video ? (
                  <VideoPreview className="p-relative">
                    <Player src={video} />
                    <button
                      type="button"
                      onClick={handleRemoveVideo}
                      className="mt-2 p-absolute remove-video"
                    >
                      <span className="d-block py-1 px-2">X</span>
                    </button>
                  </VideoPreview>
                ) : (
                  <label
                    htmlFor="video"
                    className="btn bg-gray text-center w-100 p-0"
                  >
                    <div className="d-flex justify-content-center align-items-center py-5">
                      <img
                        src={videoThumb}
                        alt="AddBioPhoto"
                        className="w-25 mb-2"
                      />
                    </div>
                  </label>
                )}
                <Input
                  type="file"
                  id="video"
                  name="video"
                  className="d-none py-3"
                  onChange={handleSelectVideo}
                />
                <label htmlFor="duration" className="small mt-3">
                  Preview Duration:
                </label>
                <InputMask
                  kind="datetime"
                  options={{
                    format: 'HH:mm:ss',
                  }}
                  id="duration"
                  name="duration"
                  onChange={handleChangeDuration}
                  onBlur={handleBlurDuration}
                  value={videoDuration}
                  placeholder="00:00:00"
                  className="py-3"
                />
              </div>
              <hr className="d-none d-xl-block hr-gray my-4" />
              <div className="col-md-6 col-lg-4 col-xl-12 pl-md-4 pr-lg-0 px-xl-0 mt-5 mt-md-0">
                <label htmlFor="audio" className="small">
                  Preview of the audio
                </label>
                {audio ? (
                  <div className="position-relative">
                    <WaveForm
                      thumbnail="https://loremflickr.com/500/500"
                      audio={audio}
                      className="audio"
                    />
                    <button
                      type="button"
                      onClick={handleRemoveAudio}
                      className="mt-2 p-absolute remove-audio"
                    >
                      <span className="d-block py-1 px-2">X</span>
                    </button>
                  </div>
                ) : (
                  <label
                    htmlFor="audio"
                    className="btn bg-gray text-center w-100 p-0"
                  >
                    <div className="d-flex justify-content-center align-items-center py-5">
                      <img
                        src={audioThumb}
                        alt="AddBioPhoto"
                        className="w-25 mb-2"
                      />
                    </div>
                  </label>
                )}
                <Input
                  type="file"
                  id="audio"
                  name="audio"
                  className="d-none py-3"
                  onChange={handleSelectAudio}
                />
                <label htmlFor="audio-duration" className="small mt-3">
                  Preview Duration:
                </label>
                <InputMask
                  kind="datetime"
                  options={{
                    format: 'HH:mm:ss',
                  }}
                  id="audio-duration"
                  name="audioDuration"
                  onChange={handleChangeAudioDuration}
                  onBlur={handleBlurAudioDuration}
                  value={audioDuration}
                  placeholder="00:00:00"
                  className="py-3"
                />
              </div>
              <hr className="hr-gray my-4" />
              <div className="col-lg-4 col-xl-12 pl-lg-5 px-xl-0 mt-5 mt-xl-0">
                <div>
                  <label htmlFor="price" className="small">
                    Price
                  </label>
                  <InputMask
                    kind="money"
                    options={{
                      unit: '$',
                      delimiter: ',',
                      separator: '.',
                    }}
                    id="price"
                    name="price"
                    className="py-3"
                  />
                </div>
                <div className="mt-1">
                  <label className="small">Show Promotion Price</label>
                  <div className="d-flex text-center">
                    <label
                      className={`btn w-100 mx-1 btn-radio ${
                        showPromotionPrice === 'yes' ? 'btn-grey' : 'btn-dark'
                      }`}
                      htmlFor="show_promotion_price_yes"
                    >
                      <span className="d-block py-2">YES</span>
                      <Input
                        type="radio"
                        onChange={changeShowPromotionPrice}
                        name="show_promotion_price"
                        id="show_promotion_price_yes"
                        className="d-none"
                        value="yes"
                        checked={showPromotionPrice === 'yes'}
                      />
                    </label>
                    <label
                      className={`btn w-100 mx-1 btn-radio ${
                        showPromotionPrice === 'no' ? 'btn-grey' : 'btn-dark'
                      }`}
                      htmlFor="show_promotion_price_no"
                    >
                      <span className="d-block py-2">NO</span>
                      <Input
                        type="radio"
                        onChange={changeShowPromotionPrice}
                        name="show_promotion_price"
                        id="show_promotion_price_no"
                        className="d-none"
                        value="no"
                        checked={showPromotionPrice === 'no'}
                      />
                    </label>
                  </div>
                </div>
                {showPromotionPrice === 'yes' && (
                  <div className="mt-1">
                    <label htmlFor="promotion_price" className="small">
                      Course Promotion Price
                    </label>
                    <InputMask
                      kind="money"
                      options={{
                        unit: '$',
                        delimiter: ',',
                        separator: '.',
                      }}
                      id="promotion_price"
                      name="promotion_price"
                      className="py-3"
                    />
                  </div>
                )}
              </div>
            </VideoInfo>
          </div>
          <div className="col-xl-8 col-xxl-9">
            <CourseInfo>
              <h3 className="h5">New Course</h3>
              <div className="row">
                <div className="col-lg-6">
                  <div className="mt-3">
                    <label htmlFor="title" className="small">
                      Title
                    </label>
                    <Input
                      id="title"
                      name="title"
                      className="py-3"
                      onChange={handleChangeTitle}
                    />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="mt-3">
                    <label htmlFor="subtitle" className="small">
                      Subtitle
                    </label>
                    <Input id="subtitle" className="py-3" name="subtitle" />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="mt-3">
                    <label htmlFor="presented_by" className="small">
                      Presented by
                    </label>
                    <Input
                      id="presented_by"
                      className="py-3"
                      name="presented_by"
                    />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="mt-3">
                    <label htmlFor="categories">Categories</label>
                    <InputCategory
                      type="courses"
                      onSelect={handleSelectCategories}
                      className="py-1"
                    />
                  </div>
                </div>
                <div className="col-12">
                  <div className="mt-3">
                    <label htmlFor="description" className="small">
                      Description
                    </label>
                    <Textarea
                      id="description"
                      name="description"
                      className="p-3"
                      rows={10}
                    />
                  </div>
                </div>
              </div>
            </CourseInfo>
            <CourseInfo className="mt-4">
              <h3 className="h5">SEO</h3>
              <div className="row">
                <div className="col-lg-6">
                  <div className="mt-3">
                    <label htmlFor="slug" className="small">
                      URL
                    </label>
                    <div className="d-flex align-items-center link-preview p-relative">
                      <span className="mb-0 before-slug py-3">/course/</span>
                      <Input
                        id="slug"
                        name="slug"
                        className="py-3"
                        value={url}
                        onChange={handleChangeSlug}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="mt-3">
                    <label htmlFor="page_title" className="small">
                      Page Title
                    </label>
                    <Input id="page_title" className="py-3" name="page_title" />
                  </div>
                </div>
                <div className="col-12">
                  <div className="mt-3">
                    <label htmlFor="meta_description" className="small">
                      Meta Description
                    </label>
                    <Input
                      id="meta_description"
                      className="py-3"
                      name="meta_description"
                    />
                  </div>
                </div>
              </div>
              <div className="row mt-5 align-items-end justify-content-end">
                <div className="col-lg-4">
                  <button type="submit" className="btn-grey py-3 w-100">
                    <span className="d-block font-weight-bold">Save</span>
                  </button>
                </div>
              </div>
            </CourseInfo>
          </div>
        </Form>
      </div>
      <Loading
        show={loading}
        status={status}
        percent={percent}
        showPercent={showPercent}
      />
    </Container>
  );
};

export default CoursesRegister;
