import styled from 'styled-components';

export const Container = styled.div`
  .categories {
    overflow: auto;

    a {
      display: block;
      background-color: transparent;
      border: none;
      text-align: start;
      color: rgba(96, 96, 96, 1) !important;
      font-weight: 400;

      :hover {
        background-color: rgba(45, 45, 45, 1);
        border-radius: 15px;
        color: rgba(203, 204, 206, 1) !important;
        text-decoration: none;
        font-weight: 700;
      }
    }

    a.active {
      background-color: rgba(45, 45, 45, 1);
      border-radius: 15px;
      color: rgba(203, 204, 206, 1) !important;
      text-decoration: none;
      font-weight: 700;
    }
  }
`;
