import styled from 'styled-components';

export const Container = styled.div`
  .browse-color,
  h3 {
    color: #e4e4e4 !important;
  }

  .bg-gray {
    background: #202020;
    border-radius: 20px;
    overflow: hidden;
    border: solid 2px #333;

    p {
      color: #565656;
    }
  }
  .h-40 {
    height: 40%;
  }
  .h-60 {
    height: 60%;
  }
  .btn-remove-video {
    top: 0;
    right: 30px;
    z-index: 99;
    background-color: #606060;
    border: none;
    border-radius: 50%;
  }

  @media screen and (min-width: 992px) {
    .h-lg-40 {
      height: 40%;
    }
    .h-lg-60 {
      height: 60%;
    }
  }
`;

export const Welcome = styled.div`
  p {
    color: #969696;
  }

  a {
    color: #e4e4e4;
  }

  .search {
    padding: 1px;
    border-radius: 100px;
    border: 1px solid #4a4b4b;
    background-color: #202020;

    input {
      margin: 5px 0;
      padding: 0 10px;
      border: none;
      border-radius: 100px;
      background-color: #202020;
    }
  }
`;

export const InputsGroup = styled.div`
  background: #202020;
  border-radius: 15px;

  h2 {
    color: #cbccce;
  }

  label {
    color: #828282;
  }

  .input {
    > div {
      height: 56px;
      border-radius: 15px;
    }
  }
  .text-area {
    > div {
      border-radius: 15px;
    }
  }
  .description {
    textarea {
      min-height: 250px;
    }
  }
`;
