import React from 'react';
import { IProps } from './interfaces';

const WsOrder: React.FC<IProps> = ({
  size = 32,
  color = '#000',
  className,
}) => {
  return (
    <svg
      className={className}
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.625 5.149C20.625 4.169 19.831 3.375 18.851 3.375H5.149C4.169 3.375 3.375 4.169 3.375 5.149V18.851C3.375 19.831 4.169 20.625 5.149 20.625H18.851C19.831 20.625 20.625 19.831 20.625 18.851V5.149V5.149Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.8899 12H11.6299"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.67678 11.8232C7.77441 11.9209 7.77441 12.0791 7.67678 12.1768C7.57915 12.2744 7.42085 12.2744 7.32322 12.1768C7.22559 12.0791 7.22559 11.9209 7.32322 11.8232C7.42085 11.7256 7.57915 11.7256 7.67678 11.8232"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.8899 7.5H11.6299"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.67678 7.32713C7.77441 7.42476 7.77441 7.58305 7.67678 7.68068C7.57915 7.77831 7.42085 7.77831 7.32322 7.68068C7.22559 7.58305 7.22559 7.42476 7.32322 7.32713C7.42085 7.2295 7.57915 7.2295 7.67678 7.32713"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.9797 16.5H11.7197"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.76467 16.3271C7.8623 16.4248 7.8623 16.5831 7.76467 16.6807C7.66704 16.7783 7.50875 16.7783 7.41111 16.6807C7.31348 16.5831 7.31348 16.4248 7.41111 16.3271C7.50875 16.2295 7.66704 16.2295 7.76467 16.3271"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default WsOrder;
