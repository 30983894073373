/* eslint-disable no-param-reassign */
import React, { useEffect, useState, useCallback } from 'react';
import { IoMdArrowDown, IoMdArrowUp } from 'react-icons/io';

import api from '~/services/api';

import { Container, Avatar } from './styles';

interface ITreeFormatProps {
  active: boolean;
  changeValues: boolean;
  setChangeValues?(value: boolean): void;
}

interface ICrowdfundingResponse {
  id: string;
  affiliate_id: string;
  affiliate_owner_id: string;
  affiliate: {
    student: {
      name: string;
      referral_code: string;
      avatar: {
        avatar_url: string;
      };
    };
  };
}

interface ICrowdfunding {
  id: string;
  affiliate_owner_id: string;
  affiliate_id: string;
  name: string;
  referral_code: string;
  avatar: string;
  crowdfundings: ICrowdfunding[];
}

const TreeFormat: React.FC<ITreeFormatProps> = ({ active }) => {
  const [affiliateSelected, setAffiliateSelected] = useState<ICrowdfunding>(
    {} as ICrowdfunding
  );
  const [crowdfunding, setCrowdfunding] = useState<ICrowdfunding>(
    {} as ICrowdfunding
  );

  const adjustPiramyd = useCallback((data: ICrowdfunding[]) => {
    const crowdfundingData = data[0];

    const secondRow = data.filter(
      (dataCrowdfunding) =>
        dataCrowdfunding.affiliate_owner_id === data[0].affiliate_id
    );

    secondRow.forEach((dataCrowdfunding) => {
      const thirdRow = data.filter(
        (subDataCrowdfunding) =>
          subDataCrowdfunding.affiliate_owner_id ===
          dataCrowdfunding.affiliate_id
      );

      thirdRow.forEach((subDataCrowdfunding) => {
        const fourthRow = data.filter(
          (subSubDataCrowdfunding) =>
            subSubDataCrowdfunding.affiliate_owner_id ===
            subDataCrowdfunding.affiliate_id
        );

        subDataCrowdfunding.crowdfundings = fourthRow;
      });

      dataCrowdfunding.crowdfundings = thirdRow;
    });

    crowdfundingData.crowdfundings = secondRow;

    setCrowdfunding(crowdfundingData);
  }, []);

  useEffect(() => {
    api
      .get('crowdfunding/658e7a5e-e21c-4e81-acfa-4ba1e2517089')
      .then((response) => {
        const crowdfundingData = {
          id: response.data.id,
          affiliate_owner_id: response.data.affiliate_owner_id,
          affiliate_id: response.data.affiliate_id,
          name: response.data.affiliate.student.name,
          referral_code: response.data.affiliate.student.referral_code,
          avatar: response.data.affiliate.student.avatar.avatar_url,
          crowdfundings: [] as ICrowdfunding[],
        };

        setAffiliateSelected(crowdfundingData);
      });
  }, []);

  useEffect(() => {
    if (Object.keys(affiliateSelected).length > 0) {
      api
        .get<ICrowdfundingResponse[]>(
          `crowdfunding-affiliates/${affiliateSelected.affiliate_id}`,
          {
            params: {
              skip: 0,
              take: 3,
            },
          }
        )
        .then(async (response) => {
          const data = response.data.map((crowdfundingData) => ({
            id: crowdfundingData.id,
            affiliate_owner_id: crowdfundingData.affiliate_owner_id,
            affiliate_id: crowdfundingData.affiliate_id,
            name: crowdfundingData.affiliate.student.name,
            referral_code: crowdfundingData.affiliate.student.referral_code,
            avatar: crowdfundingData.affiliate.student.avatar.avatar_url,
            crowdfundings: [] as ICrowdfunding[],
          }));

          data.unshift(affiliateSelected);
          const crowdfundingPromise = new Promise<void>((resolve) => {
            if (response.data.length > 0) {
              let index = 0;
              response.data.forEach(async (crowdfundingData) => {
                const responseThirdRow = await api.get<ICrowdfundingResponse[]>(
                  `crowdfunding-affiliates/${crowdfundingData.affiliate_id}`,
                  {
                    params: {
                      skip: 0,
                      take: 3,
                    },
                  }
                );

                const dataThird = responseThirdRow.data.map(
                  (subCrowdfundingData) => ({
                    id: subCrowdfundingData.id,
                    affiliate_owner_id: subCrowdfundingData.affiliate_owner_id,
                    affiliate_id: subCrowdfundingData.affiliate_id,
                    name: subCrowdfundingData.affiliate.student.name,
                    referral_code:
                      subCrowdfundingData.affiliate.student.referral_code,
                    avatar:
                      subCrowdfundingData.affiliate.student.avatar.avatar_url,
                    crowdfundings: [] as ICrowdfunding[],
                  })
                );

                data.push(...dataThird);

                const subCrowdfundingPromise = new Promise<void>(
                  (subResolve) => {
                    if (responseThirdRow.data.length > 0) {
                      responseThirdRow.data.forEach(
                        async (subCrowdfundingData, subIndex) => {
                          const responseFourthRow = await api.get<
                            ICrowdfundingResponse[]
                          >(
                            `crowdfunding-affiliates/${subCrowdfundingData.affiliate_id}`,
                            {
                              params: {
                                skip: 0,
                                take: 3,
                              },
                            }
                          );

                          const dataFourth = responseFourthRow.data.map(
                            (subSubCrowdfundingData) => ({
                              id: subSubCrowdfundingData.id,
                              affiliate_owner_id:
                                subSubCrowdfundingData.affiliate_owner_id,
                              affiliate_id: subSubCrowdfundingData.affiliate_id,
                              name:
                                subSubCrowdfundingData.affiliate.student.name,
                              referral_code:
                                subSubCrowdfundingData.affiliate.student
                                  .referral_code,
                              avatar:
                                subSubCrowdfundingData.affiliate.student.avatar
                                  .avatar_url,
                              crowdfundings: [] as ICrowdfunding[],
                            })
                          );

                          data.push(...dataFourth);

                          if (responseThirdRow.data.length === subIndex + 1) {
                            subResolve();
                          }
                        }
                      );
                    } else {
                      subResolve();
                    }
                  }
                );

                await subCrowdfundingPromise;

                if (response.data.length === index + 1) {
                  resolve();
                }

                index += 1;
              });
            } else {
              resolve();
            }
          });

          await crowdfundingPromise;

          console.log(data);

          adjustPiramyd(data);
        });
    }
  }, [affiliateSelected, adjustPiramyd]);

  const handleAddNew = useCallback((crowdfundingData: ICrowdfunding) => {
    console.log('handleAddNew', crowdfundingData);
  }, []);

  const handleShowMore = useCallback((crowdfundingData: ICrowdfunding) => {
    setAffiliateSelected(crowdfundingData);
  }, []);

  const generateEmptyFourthRow = useCallback(
    (crowdfundingData, quantity, create) => {
      const item = (
        <div className="col-4 my-2">
          <button
            type="button"
            className="crowdfunding w-100 px-1 py-2 rounded-pill"
            onClick={() => {
              create && handleAddNew(crowdfundingData);
            }}
          />
        </div>
      );

      const items = [] as JSX.Element[];

      for (let i = 0; i < quantity; i += 1) {
        items.push(item);
      }

      return items;
    },
    [handleAddNew]
  );

  const generateEmptyThirdRow = useCallback(
    (crowdfundingData, quantity, create) => {
      const item = (
        <div className="col-sm-4 my-2">
          <button
            type="button"
            className="crowdfunding w-100 py-2 rounded-pill"
            onClick={() => {
              create && handleAddNew(crowdfundingData);
            }}
          />
          <div className="row text-center fourth-line">
            {generateEmptyFourthRow(crowdfundingData, 3, false)}
          </div>
          <button
            type="button"
            className="show-more w-100 mb-4 mb-sm-0 py-2 rounded-pill"
            disabled
          >
            <IoMdArrowDown size={18} color="#CBCCCE" />{' '}
            <small>Bottom left</small>
          </button>
        </div>
      );

      const items = [] as JSX.Element[];

      for (let i = 0; i < quantity; i += 1) {
        items.push(item);
      }

      return items;
    },
    [generateEmptyFourthRow, handleAddNew]
  );

  const generateEmptySecondRow = useCallback(
    (crowdfundingData, quantity) => {
      const item = (
        <div className="col-lg-4 px-1 my-2">
          <button
            type="button"
            className="crowdfunding rounded-pill w-100 py-2 py-2"
            onClick={() => handleAddNew(crowdfundingData)}
          />
          <div className="row text-center third-line">
            {generateEmptyThirdRow(crowdfundingData, 3, false)}
          </div>
        </div>
      );

      const items = [] as JSX.Element[];

      for (let i = 0; i < quantity; i += 1) {
        items.push(item);
      }

      return items;
    },
    [generateEmptyThirdRow, handleAddNew]
  );

  return (
    <Container
      className={`col-12 mt-4 mt-lg-5 ${active ? 'd-block' : 'd-none'}`}
    >
      <div className="row px-2 justify-content-between">
        <div className="col-sm-4 col-md-3 col-xl-2 px-1 px-lg-3">
          <button type="button" className="btn-action rounded-pill w-100 py-2">
            <IoMdArrowUp size={18} color="#CBCCCE" className="mr-2" />
            <span>Back to top</span>
          </button>
        </div>
        <div className="col-sm-4 col-md-3 col-xl-2 px-1 px-lg-3 my-3 my-sm-0">
          <button type="button" className="btn-action rounded-pill w-100 py-2">
            <IoMdArrowUp size={18} color="#CBCCCE" className="mr-2" />
            <span>Top</span>
          </button>
        </div>
        <div className="col-sm-4 col-md-3 col-xl-2 px-1 px-lg-3">
          <button type="button" className="btn-action rounded-pill w-100 py-2">
            <IoMdArrowUp size={18} color="#CBCCCE" className="mr-2" />
            <span>One up</span>
          </button>
        </div>
      </div>
      {Object.keys(crowdfunding).length > 0 && (
        <div className="pyramid row text-center p-3">
          <div className="col-12 px-1 my-2">
            <div className="main-crowdfunding border-rad">
              <div className="d-flex flex-wrap flex-lg-nowrap align-items-center justify-content-between p-4 p-lg-3">
                <div className="w-100 w-sm-50 w-lg-100 border-sm-right">
                  <p className="mb-0 h4 font-weight-semibold">556</p>
                  <p className="mb-0 font-weight-normal">Total Team Members</p>
                </div>
                <hr className="w-25 mx-auto d-sm-none" />
                <div className="w-100 w-sm-50 w-lg-100">
                  <p className="mb-0 h4 font-weight-semibold">$397</p>
                  <p className="mb-0 font-weight-normal">Personal Volume</p>
                </div>
                <hr className="w-25 mx-auto d-sm-none" />

                <div className="w-100 d-flex my-4 my-lg-0 justify-content-center">
                  <div className="d-flex align-items-center">
                    <Avatar
                      src={crowdfunding.avatar}
                      className="main-avatar mr-2"
                    />
                    <div className="text-left">
                      <p className="mb-0 font-weight-semibold">
                        {crowdfunding.name}
                      </p>
                      <p className="mb-0 font-weight-normal">Mastermind</p>
                      <p className="mb-0 font-weight-normal">
                        {crowdfunding.referral_code}
                      </p>
                    </div>
                  </div>
                </div>
                <hr className="w-25 mx-auto d-sm-none" />

                <div className="w-100 w-sm-50 w-lg-100 border-sm-right">
                  <p className="mb-0 h4 font-weight-semibold">$5,985</p>
                  <p className="mb-0 font-weight-normal">Volume This Week</p>
                </div>
                <hr className="w-25 mx-auto d-sm-none" />

                <div className="w-100 w-sm-50 w-lg-100">
                  <p className="mb-0 h4 font-weight-semibold">$24,264</p>
                  <p className="mb-0 font-weight-normal">Volume This Month</p>
                </div>
              </div>
            </div>
          </div>
          {crowdfunding.crowdfundings.map((crowdfundingData) => (
            <div
              key={crowdfundingData.id}
              className="col-md-6 col-xl-4 px-1 my-2"
            >
              <button
                type="button"
                className="crowdfunding rounded-pill w-100 py-2 has-item"
                onClick={() => handleShowMore(crowdfundingData)}
              >
                <div className="d-flex justify-content-center align-items-center">
                  <img
                    src={crowdfundingData.avatar}
                    alt={crowdfundingData.name}
                    className="avatar mr-2"
                  />
                  <div className="text-left">
                    <p className="mb-0 font-weight-semibold">
                      {crowdfundingData.name}
                    </p>
                    <p className="mb-0 font-weight-normal">Mastermind</p>
                    <p className="mb-0 font-weight-normal">
                      {crowdfundingData.referral_code}
                    </p>
                  </div>
                </div>
              </button>
              <div className="row text-center third-line">
                {crowdfundingData.crowdfundings.map((subCrowdfundingData) => (
                  <div key={subCrowdfundingData.id} className="col-sm-4 my-2">
                    <button
                      type="button"
                      className="crowdfunding w-100 py-2 rounded-pill has-item"
                      onClick={() => handleShowMore(subCrowdfundingData)}
                    >
                      <img
                        src={subCrowdfundingData.avatar}
                        alt={subCrowdfundingData.name}
                        className="avatar"
                      />
                    </button>
                    <div className="row text-center fourth-line">
                      {subCrowdfundingData.crowdfundings.map(
                        (subSubCrowdfundingData) => (
                          <div
                            key={subSubCrowdfundingData.id}
                            className="col-4 my-2"
                          >
                            <button
                              type="button"
                              className="crowdfunding w-100 px-1 py-2 rounded-pill has-item"
                              onClick={() =>
                                handleShowMore(subSubCrowdfundingData)
                              }
                            >
                              <img
                                src={subSubCrowdfundingData.avatar}
                                alt={subSubCrowdfundingData.name}
                                className="w-100 rounded-circle"
                              />
                            </button>
                          </div>
                        )
                      )}
                      {subCrowdfundingData.crowdfundings.length < 3 &&
                        generateEmptyFourthRow(
                          subCrowdfundingData,
                          3 - subCrowdfundingData.crowdfundings.length,
                          true
                        )}
                    </div>
                    <button
                      type="button"
                      className="show-more w-100 mb-4 mb-sm-0 py-2 rounded-pill has-item"
                      onClick={() => handleShowMore(subCrowdfundingData)}
                    >
                      <IoMdArrowDown size={18} color="#CBCCCE" />{' '}
                      <small>Bottom left</small>
                    </button>
                  </div>
                ))}
                {crowdfundingData.crowdfundings.length < 3 &&
                  generateEmptyThirdRow(
                    crowdfundingData,
                    3 - crowdfundingData.crowdfundings.length,
                    true
                  )}
              </div>
            </div>
          ))}
          {crowdfunding.crowdfundings.length < 3 &&
            generateEmptySecondRow(
              crowdfunding,
              3 - crowdfunding.crowdfundings.length
            )}
        </div>
      )}
    </Container>
  );
};

export default TreeFormat;
