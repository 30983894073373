import styled from 'styled-components';

interface IContainer {
  show: boolean;
}

interface IPercentBar {
  percent: number;
}

export const Container = styled.div<IContainer>`
  position: fixed;
  width: 100%;
  height: 100vh;
  z-index: 1100;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  background: #00000055;
  opacity: ${(props) => (props.show ? 1 : 0)};
  visibility: ${(props) => (props.show ? 'visible' : 'hidden')};
`;

export const PercentBar = styled.div<IPercentBar>`
  width: 200px;
  height: 15px;
  border-radius: 16px;
  border: 1px solid #fff;
  background-color: #ffffff55;
  position: relative;
  overflow: hidden;

  :after {
    content: '';
    background-color: #ffffff;
    width: ${(props) => props.percent}%;
    height: 100%;
    transition-duration: 0.3s;
    position: absolute;
    top: 0;
    left: 0;
  }
`;
