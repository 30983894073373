import styled, { keyframes } from 'styled-components';
import { shade, lighten } from 'polished';

import signInBackgroung from '~/assets/banners/sign-in-background.png';

export const Container = styled.div`
  background-color: #232323;
  min-height: 100vh;

  .position-signIn {
    right: 0;
    top: 0;
  }
`;

const appearFromLeft = keyframes`
  from {
    opacity: 0;
    transform: translateX(-50px);
  }

  to {
    opacity: 1;
    transform: translateX(0);
  }
`;

export const AnimationContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  animation: ${appearFromLeft} 1s;

  h2 {
    color: rgb(203, 204, 206);
  }

  > div {
    background: rgb(54, 54, 54);
    background: linear-gradient(
      136deg,
      rgba(54, 54, 54, 0.41) 0%,
      rgba(54, 54, 54, 0) 100%
    );

    border: none;
    padding: 1px;
    background-image: linear-gradient(136deg, #242526, #242526),
      linear-gradient(136deg, rgba(123, 123, 123, 1), rgba(54, 54, 54, 0));
    background-origin: border-box;
    background-clip: content-box, border-box;
    border-image-slice: 1;
    border-radius: 10px;
    transition-duration: 0.3s;
    color: #fff;

    @media screen and (min-width: 992px) {
      width: 75%;
      margin: 0 auto;
    }

    @media screen and (min-width: 1700px) {
      width: 60%;
    }
  }

  .login {
    background: linear-gradient(
      180deg,
      rgba(54, 54, 54, 0.47) 0.29%,
      rgba(54, 54, 54, 0) 101.74%
    );

    border-radius: 10px;
    color: rgba(183, 183, 183, 1);
  }

  h1 {
    margin-bottom: 24px;
    color: #fff;
  }

  h2 {
    color: #cbccce;
    font-weight: bold !important;
  }

  p {
    color: #606060;
  }

  .normal-sm a {
    color: #cbccce;
  }

  .font-weigth-500 {
    font-weight: 500 !important;
  }

  .button-login {
    background-color: rgba(10, 10, 10, 1);
    color: #e4e4e4;
    text-decoration: none;
    border: none;
    border-radius: 15px;

    :hover {
      background-color: ${lighten(0.1, '#606060')};
      transition: 0.5s;
    }
  }

  .small {
    color: #828282;
  }

  form {
    margin: 60px 0;

    > div {
      padding: 6.5px 10px;
      border: 1.5px solid #3f3f3f;
      background-color: #242526 !important;
    }

    input {
      width: 100%;
      height: 40px;
    }

    input[id='password'] {
      width: 77%;
    }

    .btn-show-custom {
      .button-show {
        position: absolute;
        top: -8px;
        right: 0px;
        width: 70px;
        text-align: center;
        background: none;
        border-width: 0px 0px 0px 1px;
        border-top-style: initial;
        border-right-style: initial;
        border-bottom-style: initial;
        border-top-color: initial;
        border-right-color: initial;
        border-bottom-color: initial;
        border-image: initial;
        font-weight: bold;
        padding: 6px;
        color: rgb(203, 204, 206);
        height: 56px !important;
        border-left: none;
      }
    }

    @media screen and (max-width: 1199px) {
      .button-login {
        border-radius: 12px;
      }
    }

    @media screen and (max-height: 740px) {
      margin: 20px 0;
    }

    @media screen and (max-width: 450px) {
      input[id='password'] {
        width: 68%;
      }
    }

    a {
      color: #565656;
      display: block;
      text-decoration: none;
      transition-duration: 0.2s;

      :hover {
        color: ${shade(0.2, '#565656')};
      }
    }
  }

  @media screen and (max-width: 1400px) {
    form {
      margin: 40px 0px 0px 0px;
      input {
      }
    }
  }

  @media screen and (max-width: 1199px) {
    form {
      width: 100%;
      margin: 25px 0px 0px 0px;
    }

    .content {
      position: relative;
      z-index: 1;
      opacity: 0.8;

      a {
        color: rgba(203, 204, 206, 1);
      }
    }
  }

  @media screen and (max-width: 991px) {
    form {
      margin: 40px 0px 0px 0px;
    }
  }
`;

export const Background = styled.div`
  background-image: url(${signInBackgroung});
  background-position: right top;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
  position: relative;

  .content {
    position: relative;
    z-index: 1;
    opacity: 0.8;

    a {
      color: rgba(203, 204, 206, 1);
    }
  }
`;
