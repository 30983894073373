import React, { useEffect } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import Book from './Book';
import DigitalFranchise from './DigitalFranchise';
import Traffic from './Traffic';
import EliteAffiliateStatus from './EliteAffiliateStatus';
import FunnelOne from './FunnelOne';
import FunnelTwo from './FunnelTwo';
import { Container, Welcome, Options } from './styles';
import { useAuth } from '~/hooks/Auth';

const SalesFunnel: React.FC = () => {
  const { user } = useAuth();
  const userName = user.name.split(' ');
  const location = useLocation();
  const path = location.pathname;

  return (
    <Container>
      <div className="container-fluid container-xxl">
        <div className="row">
          <div className="col-12 mb-2 px-0">
            <Welcome>
              <div className="container-fluid">
                <div className="row align-items-center">
                  <div className="col-lg-12 col-xl-9 px-0">
                    <h1 className="h3 pl-3 h2-sm">
                      Hello, {userName[0].trim()}!
                    </h1>
                  </div>
                </div>
              </div>
            </Welcome>
          </div>
        </div>
        <Options className="row mx-1 pt-5 pb-4 mb-4">
          <div className="col-2 px-0">
            <NavLink
              to={`${process.env.PUBLIC_URL}/sales-funnel/book`}
              activeClassName="active border-bottom pb-1"
              exact
            >
              Book
            </NavLink>
          </div>
          <div className="col-2 px-0">
            <NavLink
              to={`${process.env.PUBLIC_URL}/sales-funnel/digital-franchise`}
              activeClassName="active border-bottom pb-1"
            >
              Digital Franchise
            </NavLink>
          </div>
          <div className="col-2 px-3 px-sm-0">
            <NavLink
              to={`${process.env.PUBLIC_URL}/sales-funnel/traffic`}
              activeClassName="active border-bottom pb-1"
            >
              Traffic
            </NavLink>
          </div>
          <div className="col-2 px-3 px-sm-0">
            <NavLink
              to={`${process.env.PUBLIC_URL}/sales-funnel/elite-affiliate-status`}
              activeClassName="active border-bottom pb-1"
            >
              Elite Affiliate Status
            </NavLink>
          </div>
        </Options>
        {path === '/sales-funnel/book' && <Book />}
        {path === '/sales-funnel/digital-franchise' && <DigitalFranchise />}
        {path === '/sales-funnel/traffic' && <Traffic />}
        {path === '/sales-funnel/elite-affiliate-status' && (
          <EliteAffiliateStatus />
        )}
      </div>
    </Container>
  );
};

export default SalesFunnel;
