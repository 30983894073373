import React from 'react';
import { IProps } from './interfaces';

const WsProduct: React.FC<IProps> = ({
  size = 32,
  color = '#000',
  className,
}) => {
  return (
    <svg
      className={className}
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.24487 14.7815L10.238 10.8914L13.6522 13.5733L16.5813 9.79297"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle
        cx="19.9954"
        cy="4.19954"
        r="1.9222"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.9245 3.11914H7.65679C4.64535 3.11914 2.77808 5.25186 2.77808 8.26331V16.3457C2.77808 19.3571 4.60874 21.4807 7.65679 21.4807H16.2609C19.2724 21.4807 21.1396 19.3571 21.1396 16.3457V9.30678"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default WsProduct;
