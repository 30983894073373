import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import api from '~/services/api';

import { Container, BoxOthers } from './styles';

import edit from '~/assets/icons/edit-icon.svg';

interface IAllCoursesProps {
  active: boolean;
  filter?: string;
  totalCourses(total: number): void;
}

interface ICourseStudent {
  id: string;
}

interface ILesson {
  id: string;
}

interface coursesResponseProps {
  id: string;
  title: string;
  description: string;
  slug: string;
  courseStudent: ICourseStudent[];
  lessons: ILesson[];
  thumbnail: {
    thumbnail_url: string;
    title: string;
  };
}

interface ICourses {
  id: string;
  title: string;
  description: string;
  slug: string;
  thumbnail: string;
  thumbnail_title: string;
}

const AllCourses: React.FC<IAllCoursesProps> = ({
  active,
  totalCourses,
  filter,
}) => {
  const [loading, setLoading] = useState(false);
  const [courses, setCourses] = useState<ICourses[]>([]);

  useEffect(() => {
    setLoading(true);

    api
      .get('courses', {
        params: {
          title: filter,
        },
      })
      .then((response) => {
        const data: ICourses[] = response.data.map(
          (course: coursesResponseProps) => ({
            id: course.id,
            title: course.title,
            description: course.description,
            slug: course.slug,
            thumbnail: course.thumbnail ? course.thumbnail.thumbnail_url : '',
            thumbnail_title: course.thumbnail ? course.thumbnail.title : '',
          })
        );
        totalCourses(data.length);
        setCourses(data);
      })
      .finally(() => setLoading(false));
  }, [totalCourses, active, filter]);

  return (
    <Container className={active ? 'd-block' : 'd-none'}>
      <div className="container-fluid px-0">
        <div className="row">
          {courses.map((course) => (
            <div key={course.id} className="col-sm-6 col-lg-4 my-2">
              <BoxOthers
                src={course.thumbnail}
                className="d-block p-relative w-100 thumb"
              >
                <div className="short-title p-absolute w-100 p-2 p-xxl-3">
                  <div className="row h-100">
                    <div className="col-12">
                      <p className="h6 h5-xxl h4-1800 font-weight-600 mb-0 py-2 pl-3 mt-2 w-100">
                        {course.title}
                      </p>
                    </div>
                    <div className="col-12">
                      <div className="row h-100 align-items-end">
                        <div className="col-12">
                          <div className="see-more-link mb-2">
                            <Link
                              to={`${process.env.PUBLIC_URL}/courses/update/${course.slug}`}
                              className="btn h6 px-0 py-1 py-sm-2 ml-3 mt-sm-3 mt-xl-0 mb-0"
                            >
                              Edit{' '}
                              <img src={edit} className="ml-2" alt="Edit" />
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </BoxOthers>
            </div>
          ))}
        </div>
      </div>
      {loading && (
        <div className="loading-box">
          <div className="spinner-border text-light" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      )}
    </Container>
  );
};

export default AllCourses;
