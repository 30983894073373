import styled from 'styled-components';

interface Img {
  src: string;
}

export const Container = styled.div`
  .ck.ck-dropdown .ck-dropdown__panel.ck-dropdown__panel_nw,
  .ck.ck-dropdown .ck-dropdown__panel.ck-dropdown__panel_sw {
    border-radius: 20px 20px 0 0;
  }
  .ck.ck-button,
  a.ck.ck-button {
    :hover {
      color: #414141 !important;
      background-color: #cbccca !important;
    }
  }

  .ck-on {
    color: #414141 !important;
  }

  .ck.ck-list {
    background-color: #414141;
  }
  .ck.ck-button .ck-tooltip,
  a.ck.ck-button .ck-tooltip {
    display: none !important;
  }

  .ck-content {
    min-height: 65vh !important;
  }

  .bg-gray {
    background-color: rgba(32, 32, 32, 1);
    border-radius: 20px;
  }

  .border-gray {
    border-color: #8c8c8c;
  }

  .step-lesson {
    span {
      color: #8c8c8c;

      :hover {
        color: rgba(32, 32, 32, 1) !important;
        text-decoration: none;
      }
    }
    text-decoration: none;
  }

  .min-width {
    min-width: 600px;
  }

  .btn-light-grey {
    background-color: rgba(203, 204, 206, 1);
    border: none;
    color: rgba(110, 110, 110, 1);
    border-radius: 15px;
  }

  .audio {
    height: 230px;
  }
`;

export const VideoInfo = styled.div`
  background: #202020;
  border-radius: 20px;
  padding: 20px;

  .bg-gray {
    background: #242526;
    border-radius: 20px;
    overflow: hidden;

    p {
      color: #565656;
    }
  }

  .remove-video {
    top: 0;
    right: 5px;
    z-index: 999;
    background-color: transparent;
    border: none;
  }

  .remove-audio {
    top: 0;
    right: 5px;
    z-index: 999;
    background-color: transparent;
    border: none;
  }

  .short-tilte {
    border: none;
    border-bottom: 1px solid rgba(51, 51, 51, 1);
    border-radius: 0;
  }

  .border-gray {
    border-color: #8c8c8c;
  }

  label {
    color: #828282;
  }

  .btn-radio {
    color: #cbccce;

    :hover {
      color: #cbccce;
    }
  }

  @media (min-width: 768px) and (max-width: 1199px) {
    .bd-right {
      border-right: solid 1px #333;
    }
  }
`;

export const LessonInfo = styled.div`
  background: #202020;
  border-radius: 20px;
  padding: 20px;

  .bg-gray {
    background: #242526;
    border-radius: 20px;
    overflow: hidden;

    p {
      color: #565656;
    }
  }

  label {
    color: #828282;
  }

  .bio-photo {
    width: 100%;
    border-radius: 20px;
  }

  .link-preview {
    span.error {
      position: absolute;
      bottom: -24px;
    }

    span.before-slug {
      background: #242526;
      border-radius: 10px;
      border: 1.5px solid #6e6e6e;
      padding: 5px 20px;
      color: #cbccce;
      margin-right: -15px;
      z-index: 1;
    }

    input {
      padding-left: 15px;
    }
  }
`;

export const Actions = styled.div`
  background: #202020;
  border-radius: 20px;
`;

export const Options = styled.div`
  border-radius: 15px;
  padding: 1px;
  border: solid 1px #8c8c8c;
  background-color: rgba(36, 37, 38, 1);

  .button-group {
    > div {
      width: 160px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      button {
        color: #8c8c8c !important;
        border-bottom: 1px solid #82551100 !important;
        padding: 5px 0;

        :hover {
          color: rgba(255, 255, 255) !important;
          border-color: rgba(255, 255, 255) !important;
        }
      }

      button.active {
        color: rgba(255, 255, 255) !important;
        border-color: rgba(255, 255, 255) !important;
      }
    }
  }

  small.error > span {
    width: 160px;
    color: #dc3545 !important;
    margin-bottom: 5px;
    text-align: center;
  }
`;

export const Content = styled.div`
  p {
    color: #ababab;
  }

  .ck-toolbar {
    background: rgba(65, 65, 65, 1) !important;
    border-top-left-radius: 20px !important;
    border-top-right-radius: 20px !important;
    border-color: rgba(51, 51, 51, 1) !important;
    padding: 1rem !important;
  }

  .ck-content {
    background: #202020 !important;
    border: 1px solid rgba(51, 51, 51, 1) !important;
    box-shadow: none !important;
    min-height: 100vh;
    border-bottom-left-radius: 20px !important;
    border-bottom-right-radius: 20px !important;
  }

  .ck-focused {
    border: 1px solid #fff !important;
  }

  .next-step {
    .ck-content {
      min-height: 230.33px;
    }

    .coach {
      border: solid 1px #3d3d3d;
      border-radius: 15px;
      .button-close {
        color: #dadada;
        right: 10px;
        top: 4px;
      }

      .coach-avatar {
        padding: 3px;
        border: solid 3px #e4e4e4;
        border-radius: 20px;
        width: 95px;
        height: 95px;
      }
    }
  }

  .discussion {
    .bg-gray {
      background-color: #4b4b4b;
      border-radius: 20px;

      textarea {
        background: #6d6d6d;
        border-radius: 15px;
        color: #bdbdbd;
        border: none;
        min-height: 100px;

        ::placeholder {
          color: #bdbdbd;
        }

        :focus {
          color: #bdbdbd;
          box-shadow: none;
        }
      }
    }
  }
`;

export const Img = styled.div<Img>`
  background-image: url(${(props) => props.src});
  background-position: center;
  background-size: cover;
  height: 184px;
`;

export const VideoPreview = styled.div`
  border-radius: 20px;
  overflow: hidden;
`;
