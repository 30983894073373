import React from 'react';
import { IProps } from './interfaces';

const WsStudents: React.FC<IProps> = ({
  size = 32,
  color = '#000',
  className,
}) => {
  return (
    <svg
      className={className}
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.59149 14.456L9.91127 14.4574C14.5557 14.4978 17.1835 15.4358 17.1835 17.998C17.1835 20.5059 14.665 21.4733 10.2236 21.5544L9.59149 21.56C4.74715 21.56 2.00049 20.6391 2.00049 18.018C2.00049 15.3935 4.75808 14.456 9.59149 14.456ZM9.59149 15.956C5.57414 15.956 3.50049 16.661 3.50049 18.018C3.50049 19.3665 5.56916 20.06 9.59149 20.06C13.6091 20.06 15.6835 19.355 15.6835 17.998C15.6835 16.6503 13.6129 15.956 9.59149 15.956ZM17.5872 13.7478C18.1677 13.7873 18.7449 13.8705 19.2996 13.9935C20.4877 14.2299 21.371 14.671 21.7757 15.5202C22.0745 16.1489 22.0745 16.8804 21.7757 17.509C21.3733 18.356 20.4992 18.794 19.3044 19.0397C18.8987 19.1232 18.5021 18.8619 18.4187 18.4562C18.3352 18.0505 18.5965 17.6539 19.0022 17.5705C19.771 17.4124 20.2827 17.156 20.4209 16.8652C20.5261 16.6439 20.5261 16.3854 20.4213 16.1648C20.2822 15.873 19.7678 15.616 18.9912 15.4614C18.4938 15.3513 17.991 15.2788 17.4854 15.2444C17.0721 15.2163 16.7599 14.8585 16.788 14.4452C16.8161 14.032 17.1739 13.7197 17.5872 13.7478ZM9.59149 2.49951C12.4395 2.49951 14.7265 4.78612 14.7265 7.63351C14.7265 10.4817 12.4397 12.7685 9.59149 12.7685C6.74341 12.7685 4.45749 10.4819 4.45749 7.63351C4.45749 4.78598 6.74359 2.49951 9.59149 2.49951ZM16.0202 3.56911C18.2551 3.56911 20.0672 5.38159 20.0672 7.61611C20.0672 9.85133 18.2554 11.6631 16.0202 11.6631C15.606 11.6631 15.2702 11.3273 15.2702 10.9131C15.2702 10.4989 15.606 10.1631 16.0202 10.1631C17.427 10.1631 18.5672 9.0229 18.5672 7.61611C18.5672 6.20994 17.4266 5.06911 16.0202 5.06911C15.606 5.06911 15.2702 4.73333 15.2702 4.31911C15.2702 3.9049 15.606 3.56911 16.0202 3.56911ZM9.59149 3.99951C7.57207 3.99951 5.95749 5.61435 5.95749 7.63351C5.95749 9.65354 7.57195 11.2685 9.59149 11.2685C11.6113 11.2685 13.2265 9.6533 13.2265 7.63351C13.2265 5.6146 11.6111 3.99951 9.59149 3.99951Z"
        fill={color}
      />
    </svg>
  );
};

export default WsStudents;
