import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { BsChevronRight } from 'react-icons/bs';
import { Modal } from 'react-bootstrap';
import { Form } from '@unform/web';
import Select from '~/components/Select';

import { Container, NameTable, Input } from './styles';
import Table from '~/components/Table';
import api from '~/services/api';

interface ITableData {
  id: string;
  affiliate_id: string;
  referral_id: string;
  affiliate_owner_id: string;
  username: string;
  name: string;
  avatar: string;
  product: string;
  referrer: string;
  affiliate_owner: string;
}
interface IOption {
  id: string;
  value: string;
  affiliate_id: string;
  selected: boolean;
}

interface IListFormatProps {
  active: boolean;
  changeValues: boolean;
  setChangeValues?(value: boolean): void;
}

interface ICrowdfundingResponse {
  id: string;
  affiliate_id: string;
  referral_id: string;
  affiliate_owner_id: string;
  affiliate: {
    crowdfunding_participation: boolean;
    franchise_owner: boolean;
    student: {
      name: string;
      referral_code: string;
      avatar: {
        avatar_url: string;
      };
    };
    product: {
      name: string;
    };
  };
  referral?: {
    student: {
      name: string;
      referral_code: string;
    };
  };
  affiliate_owner?: {
    student: {
      name: string;
      referral_code: string;
    };
  };
}

const ListFormat: React.FC<IListFormatProps> = ({
  active,
  changeValues,
  setChangeValues,
}) => {
  const [show, setShow] = useState(false);
  const [showRemoveModal, setShowRemoveModal] = useState(false);
  const [crowdfunding, setCrowdfunding] = useState<ITableData[]>([]);
  const [selectedMemberData, setSelectedMemberData] = useState<ITableData>(
    {} as ITableData
  );
  const [crowdfundingSelected, setCrowdfundingSelected] = useState<ITableData>(
    {} as ITableData
  );
  const [crowdfundingAvailables, setCrowdfundingAvailables] = useState<
    IOption[]
  >([]);
  const [filter, setFilter] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    api
      .get<ICrowdfundingResponse[]>('crowdfunding', {
        params: {
          filter,
        },
      })
      .then((response) => {
        const dataCrowdFunding = response.data.filter(
          (crowdfundingData) =>
            crowdfundingData.affiliate.crowdfunding_participation &&
            crowdfundingData.affiliate.franchise_owner
        );
        const data: ITableData[] = dataCrowdFunding.map((crowdfundingData) => {
          let affiliate_owner = '';
          if (crowdfundingData === dataCrowdFunding[0]) {
            affiliate_owner = 'Wiser Company';
          } else if (crowdfundingData.affiliate_owner) {
            affiliate_owner = crowdfundingData.affiliate_owner.student.name;
          }

          return {
            id: crowdfundingData.id,
            affiliate_id: crowdfundingData.affiliate_id,
            referral_id: crowdfundingData.referral_id,
            affiliate_owner_id:
              crowdfundingData === response.data[0]
                ? 'Wiser Company'
                : crowdfundingData.affiliate_owner_id,
            username: crowdfundingData.affiliate.student.referral_code,
            avatar: crowdfundingData.affiliate.student.avatar.avatar_url,
            name: crowdfundingData.affiliate.student.name,
            product: crowdfundingData.affiliate.product
              ? crowdfundingData.affiliate.product.name
              : 'N/A',
            referrer: crowdfundingData.referral
              ? crowdfundingData.referral.student.name
              : 'Wiser Company',
            affiliate_owner,
          };
        });
        setCrowdfunding(data);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [filter]);

  const handleClickPlaceMember = useCallback(
    (selectedCrowdfunding: ITableData) => {
      const datas: IOption[] = [];
      crowdfunding.forEach((crowdfundingData) => {
        const data = crowdfunding.filter(
          (dataCrowdfunding) =>
            crowdfundingData.affiliate_id === dataCrowdfunding.affiliate_owner
        );
        if (
          (!data || data.length < 3) &&
          crowdfundingData !== selectedCrowdfunding
        ) {
          const optionData = {
            id: crowdfundingData.id,
            value: crowdfundingData.name,
            selected: false,
            affiliate_id: crowdfundingData.affiliate_id,
          };
          datas.push(optionData);
        }
      });
      setCrowdfundingAvailables(datas);
      setCrowdfundingSelected(selectedCrowdfunding);
      setShow(true);
    },
    [crowdfunding]
  );

  const handleClose = useCallback(() => {
    setShow(false);
    setShowRemoveModal(false);
  }, []);

  const handleDone = useCallback(async () => {
    setLoading(true);
    try {
      const formData = {
        affiliate_id: crowdfundingSelected.affiliate_id,
        referral_id: crowdfundingSelected.referral_id,
        affiliate_owner_id: selectedMemberData.affiliate_id,
      };

      await api.patch(
        `crowdfunding/affiliates/set/${crowdfundingSelected.id}`,
        formData
      );

      const newCrowdfundingList = crowdfunding.slice();
      const crowdfundingIndex = newCrowdfundingList.findIndex(
        (newCrowdfunding) => newCrowdfunding === crowdfundingSelected
      );
      newCrowdfundingList[crowdfundingIndex].affiliate_owner_id =
        selectedMemberData.affiliate_id;
      newCrowdfundingList[crowdfundingIndex].affiliate_owner =
        selectedMemberData.name;
      setCrowdfunding(newCrowdfundingList);
      if (setChangeValues) {
        setChangeValues(!changeValues);
      }
      setSelectedMemberData({} as ITableData);
      handleClose();
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }, [
    changeValues,
    crowdfunding,
    crowdfundingSelected,
    handleClose,
    selectedMemberData.affiliate_id,
    selectedMemberData.name,
    setChangeValues,
  ]);

  const handleRemovePlaceMember = useCallback(
    (selectedCrowdfunding: ITableData) => {
      setCrowdfundingSelected(selectedCrowdfunding);
      setShowRemoveModal(true);
    },
    []
  );

  const handleConfirmRemove = useCallback(async () => {
    setLoading(true);
    try {
      const formData = {
        affiliate_id: crowdfundingSelected.affiliate_id,
        referral_id: crowdfundingSelected.referral_id,
        affiliate_owner_id: crowdfundingSelected.affiliate_owner_id,
      };

      await api.patch(
        `crowdfunding/affiliates/unset/${crowdfundingSelected.id}`,
        formData
      );

      const newCrowdfundingList = crowdfunding.slice();
      const crowdfundingIndex = newCrowdfundingList.findIndex(
        (newCrowdfunding) => newCrowdfunding === crowdfundingSelected
      );
      newCrowdfundingList[crowdfundingIndex].affiliate_owner_id = '';
      newCrowdfundingList[crowdfundingIndex].affiliate_owner = '';
      setCrowdfunding(newCrowdfundingList);
      if (setChangeValues) {
        setChangeValues(!changeValues);
      }

      handleClose();
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }, [
    changeValues,
    crowdfunding,
    crowdfundingSelected,
    handleClose,
    setChangeValues,
  ]);

  const columns = useMemo(
    () => [
      {
        name: 'Username',
        selector: 'username',
        sortable: true,
      },
      {
        name: 'Name',
        selector: 'name',
        sortable: true,
        cell: (row: ITableData) => (
          <NameTable
            data-tag="allowRowEvents"
            className="d-flex align-items-center text-center"
          >
            <img src={row.avatar} alt={row.name} className="mr-2" />
            <p className="mb-0">{row.name}</p>
          </NameTable>
        ),
      },
      {
        name: 'Product',
        selector: 'product',
        sortable: true,
      },
      {
        name: 'Referrer',
        selector: 'referrer',
        sortable: true,
      },
      {
        name: 'Assign New Placement Sponsor',
        cell: (row: ITableData) => (
          <Input
            name="input"
            className="rounded-pill form-control"
            value={row.affiliate_owner}
            disabled
          />
        ),
      },
      {
        name: 'Action',
        cell: (row: ITableData) => (
          <button
            type="button"
            onClick={() =>
              row.affiliate_owner_id
                ? handleRemovePlaceMember(row)
                : handleClickPlaceMember(row)
            }
            className="btn-dark rounded-pill w-100 font-weight-light py-1 table-btn"
          >
            <span className="d-block py-1 px-2">
              {row.affiliate_owner_id ? 'Remove Member' : 'Place Member'}
            </span>
          </button>
        ),
      },
    ],
    [handleClickPlaceMember, handleRemovePlaceMember]
  );

  const handleChange = useCallback(
    (e) => {
      const member = crowdfunding.find(
        (crowdfundingSearch) => e.id === crowdfundingSearch.id
      );
      if (member) {
        setSelectedMemberData(member);
      }
    },
    [crowdfunding]
  );

  const handleSearch = useCallback((value) => {
    setFilter(value);
  }, []);

  return (
    <Container className={`col-12 ${active ? 'd-block' : 'd-none'}`}>
      <div className="row">
        <div className="col-12 first-table px-0 px-sm-3 my-3">
          <Table
            data={crowdfunding}
            columns={columns}
            searchable
            onSearch={handleSearch}
            pagination
          />
        </div>
        {false && (
          <div className="col-12 my-3 table pb-3">
            <div className="d-flex justify-content-between align-items-center header p-3">
              <div className="small" />
              <div className="small">Username</div>
              <div className="small">Name</div>
              <div className="small">Product</div>
              <div className="small">Referrer</div>
              <div className="small">Assigned Placement Sponsor</div>
              <div className="small">Level</div>
            </div>
            {crowdfunding.map((affiliate) => (
              <div className="row-table-group">
                <div className="d-flex justify-content-between align-items-center row-table p-3">
                  <div>
                    <BsChevronRight size={20} color="#fff" />
                  </div>
                  <div>{affiliate.username}</div>
                  <div>
                    <NameTable
                      data-tag="allowRowEvents"
                      className="d-flex align-items-center"
                    >
                      <img
                        src={affiliate.avatar}
                        alt={affiliate.name}
                        className="mr-2"
                      />
                      <p className="mb-0">{affiliate.name}</p>
                    </NameTable>
                  </div>
                  <div>{affiliate.product}</div>
                  <div>{affiliate.referrer}</div>
                  <div>{affiliate.affiliate_owner}</div>
                  <div>1</div>
                </div>
                <div className="row-table-group pl-2">
                  <div className="d-flex justify-content-between align-items-center row-table p-3">
                    <div>
                      <BsChevronRight size={20} color="#fff" />
                    </div>
                    <div>{affiliate.username}</div>
                    <div>
                      <NameTable
                        data-tag="allowRowEvents"
                        className="d-flex align-items-center"
                      >
                        <img
                          src={affiliate.avatar}
                          alt={affiliate.name}
                          className="mr-2"
                        />
                        <p className="mb-0">{affiliate.name}</p>
                      </NameTable>
                    </div>
                    <div>{affiliate.product}</div>
                    <div>{affiliate.referrer}</div>
                    <div>teste</div>
                    <div>2</div>
                  </div>
                  <div className="row-table-group pl-2">
                    <div className="d-flex justify-content-between align-items-center row-table p-3">
                      <div>
                        <BsChevronRight size={20} color="#fff" />
                      </div>
                      <div>{affiliate.username}</div>
                      <div>
                        <NameTable
                          data-tag="allowRowEvents"
                          className="d-flex align-items-center"
                        >
                          <img
                            src={affiliate.avatar}
                            alt={affiliate.name}
                            className="mr-2"
                          />
                          <p className="mb-0">{affiliate.name}</p>
                        </NameTable>
                      </div>
                      <div>{affiliate.product}</div>
                      <div>{affiliate.referrer}</div>
                      <div>teste</div>
                      <div>3</div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
      <Modal
        size="lg"
        className="modal-crowdfunding"
        show={show}
        onHide={handleClose}
      >
        <Modal.Header closeButton>
          <Modal.Title>Assign New Placement Sponsor</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row justify-content-center">
            <div className="col-12">
              <Form
                onSubmit={() => {
                  console.log('submit');
                }}
              >
                <label htmlFor="member">Assign New Placement Sponsor</label>
                <Select
                  id="member"
                  name="member"
                  options={crowdfundingAvailables}
                  onChange={handleChange}
                />
              </Form>
            </div>
            {Object.keys(selectedMemberData).length > 0 ? (
              <div className="col-lg-3 mt-5">
                <div className="h-100 p-3">
                  <img
                    src={selectedMemberData.avatar}
                    alt={selectedMemberData.name}
                    className="w-75 rounded-circle mb-3"
                  />{' '}
                  <p>{selectedMemberData.name}</p>
                </div>
              </div>
            ) : (
              ''
            )}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            onClick={handleDone}
            className="btn-grey px-3 py-1"
          >
            <span className="d-block py-1 px-2">Done</span>
          </button>
        </Modal.Footer>
      </Modal>
      <Modal
        size="lg"
        className="modal-crowdfunding"
        show={showRemoveModal}
        onHide={handleClose}
      >
        <Modal.Header closeButton>
          <Modal.Title>Do you want to remove the selected member?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row justify-content-center pb-3">
            <div className="col-lg-5">
              <button
                type="button"
                onClick={handleConfirmRemove}
                className="btn-grey px-3 py-1 w-100"
              >
                <span className="d-block py-1 px-2">Yes</span>
              </button>
            </div>
            <div className="col-lg-5">
              <button
                type="button"
                onClick={handleClose}
                className="btn-dark px-3 py-1 w-100"
              >
                <span className="d-block py-1 px-2">No</span>
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      {loading && (
        <div className="loading-box">
          <div className="spinner-border text-light" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      )}
    </Container>
  );
};

export default ListFormat;
