import React, { Fragment, useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';

import api from '~/services/api';
import Categories from '~/components/Categories';
import { Container, Card } from './styles';

import edit from '~/assets/icons/edit.svg';

interface ICourseStudent {
  id: string;
}

interface ILesson {
  id: string;
}
interface ICoursesResponseProps {
  id: string;
  title: string;
  description: string;
  slug: string;
  courseStudent: ICourseStudent[];
  lessons: ILesson[];
  thumbnail: {
    thumbnail_url: string;
    title: string;
  };
}

interface IcourseCategoryResponse {
  id: string;
  course: ICoursesResponseProps;
}

interface ICourses {
  id: string;
  title: string;
  description: string;
  slug: string;
  membersEnrolled: number;
  videos: number;
  thumbnail: string;
  thumbnail_title: string;
}

interface IParamsProps {
  slug?: string;
}

interface ICategoriesListProps {
  active: boolean;
  filter?: string;
}

const CategoriesList: React.FC<ICategoriesListProps> = ({ active, filter }) => {
  const params = useParams<IParamsProps>();
  const [searchValue, setSearchValue] = useState('');
  const [category, setCategory] = useState('All');
  const [courses, setCourses] = useState<ICourses[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    window.scrollTo(0, 0);
    if (params.slug) {
      setSearchValue('');
      api
        .get(`categories/${params.slug}`, {
          params: {
            type: 'courses',
            title: filter,
          },
        })
        .then(async (response) => {
          const data = response.data.courseCategory.map(
            (courseCategory: IcourseCategoryResponse) => ({
              id: courseCategory.course.id,
              title: courseCategory.course.title,
              description: courseCategory.course.description,
              thumbnail: courseCategory.course.thumbnail
                ? courseCategory.course.thumbnail.thumbnail_url
                : '',
              slug: courseCategory.course.slug,
            })
          );
          setCourses(data);
          setCategory(response.data.name);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      api
        .get('courses', {
          params: {
            title: filter,
          },
        })
        .then(async (response) => {
          const data = response.data.map((course: ICoursesResponseProps) => ({
            id: course.id,
            title: course.title,
            description: course.description,
            thumbnail: course.thumbnail ? course.thumbnail.thumbnail_url : '',
            slug: course.slug,
          }));
          setCourses(data);
          setCategory('All');
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [filter, params.slug]);

  return (
    <Container className={active ? 'd-block' : 'd-none'}>
      <div className="container-fluid container-xxl">
        <div className="row">
          <div className="col-xl-9 mt-sm-5 px-0">
            {courses[0] && (
              <Card
                src={courses[0].thumbnail}
                bannerCard
                className="p-relative"
              >
                <div className="w-100 h-100 px-2">
                  <div className="w-100 h-100 row ">
                    <div className="col-sm-7 col-md-8 px-0">
                      <p className="mb-0 pl-4 pr-0 pr-sm-4 pt-4 h4 h2-sm">
                        {courses[0].title}
                      </p>
                    </div>
                    <div className="col-sm-5 col-md-4 pb-4 pb-sm-0">
                      <div className="row align-content-sm-between align-items-end h-100 px-md-4">
                        <div className="order-1 order-sm-0 col-5 col-sm-12 text-sm-right mt-sm-4 ml-3 ml-sm-0">
                          <Link
                            to={`${process.env.PUBLIC_URL}/courses/update/${courses[0].slug}`}
                            className="rounded-pill px-sm-3 py-3 edit font-weight-bold"
                          >
                            Edit
                            <img src={edit} className="ml-3" alt="" />
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Card>
            )}
          </div>
          <div className="mt-5 col-md-4 col-xl-3 px-0 px-xl-3 categories-list">
            <Categories type="courses" />
          </div>
          <div className="col-md-8 col-xl-12 mt-0 mt-md-5 mt-lg-0 px-0">
            <div className="row justify-content-lg-between justify-content-xl-start">
              <div className="col-12 mt-xl-5">
                <h2 className="h5">
                  {searchValue ? `Filter: ${searchValue}` : category}
                </h2>
              </div>

              {courses.length > 0 ? (
                <>
                  {courses.map((course, index) => (
                    <Fragment key={course.id}>
                      {index > 0 && (
                        <div className="col-lg-6 col-xl-4 my-3">
                          <Card src={course.thumbnail} className="p-relative">
                            <div className="p-absolute w-100 h-100">
                              <div className="w-100 h-100 row mx-0">
                                <div className="col-12">
                                  <p className="mb-0 px-3 pt-4 h4">
                                    {course.title}
                                  </p>
                                </div>
                                <div className="col-12">
                                  <div className="d-flex align-items-end justify-content-end h-100 px-3 pb-4">
                                    <Link
                                      to={`${process.env.PUBLIC_URL}/courses/update/${course.slug}`}
                                      className="rounded-pill px-3 py-2 edit font-weight-bold"
                                    >
                                      <span>Edit</span>
                                      <img src={edit} className="ml-3" alt="" />
                                    </Link>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Card>
                        </div>
                      )}
                    </Fragment>
                  ))}
                </>
              ) : (
                <div className="col-12">
                  <p className="h5 text-center">
                    No courses found for this filter.
                  </p>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      {loading && (
        <div className="loading-box">
          <div className="spinner-border text-light" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      )}
    </Container>
  );
};

export default CategoriesList;
