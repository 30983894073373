import styled from 'styled-components';
import { Modal as ModalComponent } from 'react-bootstrap';

interface IAvatarProps {
  src: string;
}

interface ICoach {
  selected?: boolean;
}

export const Container = styled.div`
  .profile {
    background: #202020;
    border-radius: 20px;

    + .profile {
      margin-top: 20px;
    }
  }

  .submit-button {
    background-color: #4a4b4b;
    border: none;
    border-radius: 20px;
    color: #e4e4e4 !important;
    text-align: center;

    :hover {
      text-decoration: none;
    }
  }
`;

export const Welcome = styled.div`
  p {
    color: #969696;
  }
`;

export const Profile = styled.div`
  img {
    padding: 3px;
    background-image: linear-gradient(0deg, #202020, #202020),
      radial-gradient(
        circle at top left,
        rgba(228, 228, 228, 0.5),
        rgba(228, 228, 228, 0.5)
      );
    background-origin: border-box;
    background-clip: content-box, border-box;
    border-image-slice: 1;
    border-radius: 50%;
  }

  span {
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background: #ffbb38;
    right: 5px;
    top: 8px;
  }

  p {
    color: rgba(247, 247, 247, 0.5);
  }
`;

export const Avatar = styled.div<IAvatarProps>`
  margin: 0 auto;
  width: 91px;
  height: 91px;
  background-image: url(${(props) => props.src});
  background-position: center;
  background-size: cover;
  border-radius: 50%;
  box-shadow: inset 0px 0px 0px 4px rgba(228, 228, 228, 0.5);
  box-sizing: border-box;
`;

export const PersonalInformation = styled.div`
  background-color: #202020;
  border-radius: 20px;
  label {
    color: #828282;
  }
  input {
    min-height: 35px;
    background-color: transparent;
  }

  .input-percent {
    input {
      width: 40%;
    }
  }

  .submit-button {
    background-color: #4a4b4b;
    border: none;
    border-radius: 20px;
    color: #e4e4e4 !important;
    text-align: center;

    :hover {
      text-decoration: none;
    }
  }

  .btn-radio {
    color: #cbccce;

    :hover {
      color: #cbccce;
    }
  }

  .btn-active {
    background-color: #4a4b4b;
    border-radius: 15px;
  }

  .btn-inactive {
    background-color: #18191a;
    border: 1px solid #262626;
    border-radius: 15px;
  }

  .percentage {
    top: 1px;
    right: 1px;
    border-left: 1.5px solid #3e3e3e;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    background-color: transparent;
    height: calc(100% - 2px);
    width: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #8c8c8c;
  }
  @media screen and (max-width: 1281px) {
    .col-update {
      flex: 0 0 100%;
      max-width: 100%;
    }
  }
  @media screen and (max-width: 769px) {
    .order-settings {
      order: -1;
    }
  }
`;

export const Modal = styled(ModalComponent)``;

export const Coach = styled.button<ICoach>`
  background: transparent;
  border: none;

  > div {
    background: #18191a;
    border-radius: 20px;
    border: 2px solid ${(props) => (props.selected ? '#000' : 'transparent')};
    padding-top: 15px;

    > div > div {
      width: 75px;
      height: 75px;
      box-shadow: inset 0px 0px 0px 2px rgba(228, 228, 228, 0.5);
    }
  }
`;
