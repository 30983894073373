import styled from 'styled-components';
import { darken } from 'polished';

interface TableContentProps {
  showHeader: boolean;
  rowIsClickable: boolean;
}

export const Search = styled.div`
  padding: 3px;
  border-radius: 100px;
  border: 1px solid #4a4b4b;
  background-color: #202020;

  input {
    margin: 4px 0;
    padding: 0 10px;
    border: none;
    border-radius: 100px;
    background-color: #202020;
  }
`;

export const TableContent = styled.div<TableContentProps>`
  border-radius: 10px;
  overflow: hidden;
  background-color: #202020;
  z-index: 0;

  .menu-table {
    z-index: 1000;
    top: 40px;
    left: 15px;
    right: 15px;

    .search {
      padding: 1px;
      border-radius: 100px;

      input {
        margin: 10px 0;
        padding: 0 10px;
        border: none;
        border-radius: 100px;
        background-color: #202020;
      }
    }

    .inputDate {
      border: 1px solid #4a4b4b;
      background-color: transparent;
      color: rgba(130, 130, 130, 1) !important;
      padding: 7px 10px;
      border-radius: 100px;
      text-align: center;
    }
  }

  div.tableData {
    .rdt_TableHeader {
      display: ${(props) => (props.showHeader ? 'block' : 'none')};
    }

    div {
      background-color: #202020;
      ::-webkit-scrollbar {
        background-color: transparent !important;
        height: 10px !important;
      }
      ::-webkit-scrollbar-thumb {
        background: #606060;
      }
    }

    .rdt_TableRow {
      padding: 5px 0;
      cursor: ${(props) => (props.rowIsClickable ? 'pointer' : 'default')};
      transition-duration: 0.3s;

      div {
        transition-duration: 0.3s;
      }

      :hover {
        background-color: ${darken(0.03, '#202020')};

        div {
          background-color: ${darken(0.03, '#202020')};
        }
      }
    }
  }

  @media screen and (max-width: 1399px) {
    div.tableData {
      max-width: 1200px;
    }
  }

  @media screen and (max-width: 380px) {
    .menu-table .inputDate {
      font-size: 13px;
    }
  }
`;

export const Pagination = styled.div`
  p,
  button {
    color: #828282 !important;
  }

  button {
    width: 35px;
    height: 35px;
    border-radius: 50%;
    font-weight: bold;

    :first-child,
    :last-child {
      width: auto;
      border-radius: 5px;
    }
  }

  button.active,
  button:hover {
    background: #3f3f3f !important;
    color: #dadada !important;
  }
  @media screen and (max-width: 400px) {
    .d-none-400 {
      display: none;
    }
  }
`;
