import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { lastDayOfMonth } from 'date-fns';
import { Link } from 'react-router-dom';
import { Form } from '@unform/web';
import { differenceInDays } from 'date-fns/esm';
import Graph from '~/components/Graph';
import api from '~/services/api';
import { formatPrice } from '~/utils/format';

import {
  Container,
  Welcome,
  Content,
  CoachAndAffiliate,
  Modal,
} from './styles';

import TopAfiliate from '~/components/TopAfiliate';
import TopCoach from '~/components/TopCoach';
import ProgressBar from '~/components/ProgressBar';
import { useAuth } from '~/hooks/Auth';

import members from '~/assets/icons/members-icon.svg';
import reveues from '~/assets/icons/reveues-icon.svg';
import totalReveues from '~/assets/icons/total-reveues-icon.svg';
import pendingTicket from '~/assets/icons/pending-ticket-icon.svg';
import resolveTicket from '~/assets/icons/resolve-ticket-icon.svg';
import adspendIcon from '~/assets/icons/adspend-icon.svg';
import paidMembersIcon from '~/assets/icons/paid-members-icon.svg';
import average from '~/assets/icons/average-sales-icon.svg';
import star from '~/assets/icons/star-icon.svg';
import upgrade from '~/assets/icons/upgrade-icon.svg';
import watch from '~/assets/icons/watch.svg';
import InputMask from '~/components/InputMask';

interface IStudent {
  id: string;
  name: string;
  avatar: {
    avatar_url: string;
  };
  created_at: string;
}

interface IOrder {
  student_id: string;
  amount_paid: string;
  created_at: string;
  deleted_at: string;
}

interface IReferrer {
  id: string;
}
interface IAffiliateResponse {
  name: string;
  avatar: {
    avatar_url: string;
  };
  affiliate: {
    referrers: IReferrer[];
  };
}
interface IAffiliate {
  name: string;
  avatar: string;
  referred_members: number;
}

const Home: React.FC = () => {
  const { user } = useAuth();
  const [newRevenuePercent, setNewRevenuePercent] = useState(0);
  const [newRevenue, setNewRevenue] = useState('');
  const [totalRevenuePrice, setTotalRevenuePrice] = useState('');
  const [newMembersPercent, setNewMembersPercent] = useState(0);
  const [newMembers, setNewMembers] = useState<IStudent[]>([]);
  const [totalMembers, setTotalMembers] = useState(0);
  const [memberCancelationPercent, setMemberCancelationPercent] = useState(0);
  const [memberCancelation, setMemberCancelation] = useState(0);
  const [refundPercent, setRefundPercent] = useState(0);
  const [refundPrice, setRefundPrice] = useState('');
  const [profit, setProfit] = useState((0).toFixed(2));
  const [month, setMonth] = useState(new Date().getMonth());
  const [year, setYear] = useState(new Date().getFullYear());
  const [newDirectRevenuePercent, setNewDirectRevenuePercent] = useState(0);
  const [newDirectRevenue, setNewDirectRevenue] = useState('');
  const [directTotalRevenuePrice, setDirectTotalRevenuePrice] = useState('');
  const [newDirectMembersPercent, setNewDirectMembersPercent] = useState(0);
  const [newDirectMembers, setNewDirectMembers] = useState<IStudent[]>([]);
  const [directTotalMembers, setDirectTotalMembers] = useState(0);
  const [show, setShow] = useState(false);
  const [adspend, setAdspend] = useState('$15,000.00');
  const [paidMembers, setPaidMembers] = useState(0);
  const [averageSales, setAverageSales] = useState('');
  const [membersUpgrade, setMembersUpgrade] = useState(0);
  const [averageUpgradeSales, setAverageUpgradeSales] = useState('');
  const [averageUpgradeTime, setAverageUpgradeTime] = useState('');
  const [totalUpgradeSales, setTotalUpgradeSales] = useState('');
  const [topAffiliate, setTopAffiliate] = useState<IAffiliate>(
    {} as IAffiliate
  );
  const [loading, setLoading] = useState(false);

  const userName = useMemo(() => {
    return user.name.split(' ')[0].trim();
  }, [user.name]);

  const cac = useMemo(() => {
    const adspendValue = parseFloat(adspend.replace('$', '').replace(/,/g, ''));
    const result = adspendValue / paidMembers;
    return Number.isNaN(result) || !Number.isFinite(result)
      ? formatPrice(0)
      : formatPrice(result);
  }, [adspend, paidMembers]);

  const initialDate = useMemo(() => {
    return new Date(year, month, 1, 0, 0, 0, 0);
  }, [year, month]);

  const endDate = useMemo(() => {
    return lastDayOfMonth(initialDate);
  }, [initialDate]);

  const getPercent = useCallback((x, total) => {
    if (x === 0) {
      return 0;
    }
    if (total === 0) {
      return 100;
    }
    return (x * 100) / total;
  }, []);

  const loadByAffiliates = useCallback(async () => {
    const responseStudentsNoTime = await api.get('students', {
      params: {
        referenced_by: 'affiliates',
      },
    });
    const responseStudents = await api.get('students', {
      params: {
        referenced_by: 'affiliates',
        initial_date: initialDate,
        end_date: endDate,
      },
    });
    const totalStudents = responseStudentsNoTime.data.length;
    const totalStudentsByDate = responseStudents.data.length;
    const percent = getPercent(
      totalStudentsByDate,
      totalStudents - totalStudentsByDate
    );

    const responseOrdersNoTime = await api.get('orders/admin', {
      params: {
        referenced_by: 'affiliates',
        status: 'succeeded',
      },
    });
    const responseOrders = await api.get('orders/admin', {
      params: {
        referenced_by: 'affiliates',
        initial_date: initialDate,
        end_date: endDate,
        status: 'succeeded',
      },
    });
    const totalRevenue = responseOrdersNoTime.data.reduce(
      (acumulador: number, currentValue: IOrder) => {
        return acumulador + parseFloat(currentValue.amount_paid);
      },
      0
    );
    const newOrders = responseOrders.data.reduce(
      (acumulador: number, currentValue: IOrder) => {
        return acumulador + parseFloat(currentValue.amount_paid);
      },
      0
    );
    const totalOrders = responseOrdersNoTime.data.length;
    const totalOrdersByDate = responseOrders.data.length;
    const percentOrders = getPercent(
      totalOrdersByDate,
      totalOrders - totalOrdersByDate
    );

    setTotalRevenuePrice(formatPrice(totalRevenue));
    setNewRevenuePercent(percentOrders);
    setNewRevenue(formatPrice(newOrders));
    setNewMembersPercent(percent);
    setNewMembers(responseStudents.data);
    setTotalMembers(responseStudentsNoTime.data.length);
  }, [endDate, initialDate, getPercent]);

  const loadByCompany = useCallback(async () => {
    const responseStudentsNoTime = await api.get('students', {
      params: {
        referenced_by: 'company',
      },
    });
    const responseStudents = await api.get('students', {
      params: {
        referenced_by: 'company',
        initial_date: initialDate,
        end_date: endDate,
      },
    });
    const totalStudents = responseStudentsNoTime.data.length;
    const totalStudentsByDate = responseStudents.data.length;
    const percent = getPercent(
      totalStudentsByDate,
      totalStudents - totalStudentsByDate
    );

    const responseOrdersNoTime = await api.get('orders/admin', {
      params: {
        referenced_by: 'company',
        status: 'succeeded',
      },
    });
    const responseOrders = await api.get('orders/admin', {
      params: {
        referenced_by: 'company',
        initial_date: initialDate,
        end_date: endDate,
        status: 'succeeded',
      },
    });

    const totalRevenue = responseOrdersNoTime.data.reduce(
      (acumulador: number, currentValue: IOrder) => {
        return acumulador + parseFloat(currentValue.amount_paid);
      },
      0
    );
    const newOrders = responseOrders.data.reduce(
      (acumulador: number, currentValue: IOrder) => {
        return acumulador + parseFloat(currentValue.amount_paid);
      },
      0
    );
    const totalOrders = responseOrdersNoTime.data.length;
    const totalOrdersByDate = responseOrders.data.length;
    const percentOrders = getPercent(
      totalOrdersByDate,
      totalOrders - totalOrdersByDate
    );

    setDirectTotalRevenuePrice(formatPrice(totalRevenue));
    setNewDirectRevenuePercent(percentOrders);
    setNewDirectRevenue(formatPrice(newOrders));
    setNewDirectMembersPercent(percent);
    setNewDirectMembers(responseStudents.data);
    setDirectTotalMembers(responseStudentsNoTime.data.length);
  }, [initialDate, endDate, getPercent]);

  const loadCancellations = useCallback(async () => {
    const responseCanceledOrdersNoTime = await api.get<IOrder[]>(
      'orders/canceled',
      {
        params: {
          status: 'succeeded',
        },
      }
    );
    const responseCanceledOrders = await api.get<IOrder[]>('orders/canceled', {
      params: {
        initial_date: initialDate,
        end_date: endDate,
        status: 'succeeded',
      },
    });

    const totalRefunds = responseCanceledOrdersNoTime.data.reduce(
      (acumulador, currentValue) => {
        return acumulador + parseFloat(currentValue.amount_paid);
      },
      0
    );

    console.log(responseCanceledOrders.data);

    const refunds = responseCanceledOrders.data.reduce(
      (acumulador, currentValue) => {
        return acumulador + parseFloat(currentValue.amount_paid);
      },
      0
    );
    const totalOrders = responseCanceledOrdersNoTime.data.length;
    const totalOrdersByDate = responseCanceledOrders.data.length;
    const percent = getPercent(
      totalOrdersByDate,
      totalOrders - totalOrdersByDate
    );
    const percentRefund = getPercent(refunds, totalRefunds - refunds);

    setMemberCancelationPercent(percent);
    setMemberCancelation(totalOrdersByDate);
    setRefundPercent(percentRefund);
    setRefundPrice(formatPrice(refunds));
  }, [initialDate, endDate, getPercent]);

  const loadPaidMembers = useCallback(async () => {
    const response = await api.get('orders/paid-members', {
      params: {
        initial_date: initialDate,
        end_date: endDate,
      },
    });
    const paidMembersQtd = response.data.length;
    const total = response.data.reduce(
      (acumulador: number, currentValue: IOrder) => {
        return acumulador + parseFloat(currentValue.amount_paid);
      },
      0
    );

    const result = total / paidMembersQtd;

    setPaidMembers(paidMembersQtd);
    setAverageSales(
      Number.isNaN(result) || !Number.isFinite(result)
        ? formatPrice(0)
        : formatPrice(result)
    );
  }, [endDate, initialDate]);

  const loadMembersUpgrade = useCallback(async () => {
    const responsePaidMembers = await api.get<IOrder[]>('orders/paid-members', {
      params: {
        initial_date: initialDate,
        end_date: endDate,
      },
    });
    const response = await api.get<IOrder[]>('orders/members-upgrade', {
      params: {
        initial_date: initialDate,
        end_date: endDate,
      },
    });
    const responseNoTime = await api.get<IOrder[]>('orders/members-upgrade');

    const membersUpgradeQtd = response.data.length;
    const total = response.data.reduce((acumulador, currentValue) => {
      return acumulador + parseFloat(currentValue.amount_paid);
    }, 0);
    const totalNoTime = responseNoTime.data.reduce(
      (acumulador, currentValue) => {
        return acumulador + parseFloat(currentValue.amount_paid);
      },
      0
    );

    const totalTime = responsePaidMembers.data.reduce(
      (acumulador, currentValue) => {
        const order = response.data.find(
          (orderData) => orderData.student_id === currentValue.student_id
        );
        let totalAux = 0;
        if (order) {
          if (order.created_at > currentValue.created_at) {
            totalAux = differenceInDays(
              new Date(order.created_at),
              new Date(currentValue.created_at)
            );
          } else {
            totalAux = differenceInDays(
              new Date(currentValue.created_at),
              new Date(order.created_at)
            );
          }
          console.log(totalAux);
        }
        return acumulador + totalAux;
      },
      0
    );

    console.log(totalTime);

    const result = total / membersUpgradeQtd;
    const resultAvarageTime = totalTime / membersUpgradeQtd;
    if (Number.isNaN(result) || !Number.isFinite(result)) {
      setAverageUpgradeTime('0');
    } else {
      setAverageUpgradeTime(
        Number.isInteger(resultAvarageTime)
          ? resultAvarageTime.toFixed(0)
          : resultAvarageTime.toFixed(1)
      );
    }
    setMembersUpgrade(membersUpgradeQtd);
    setAverageUpgradeSales(
      Number.isNaN(result) || !Number.isFinite(result)
        ? formatPrice(0)
        : formatPrice(result)
    );
    setTotalUpgradeSales(formatPrice(totalNoTime));
  }, [endDate, initialDate]);

  useEffect(() => {
    setLoading(true);
    window.scrollTo(0, 0);

    loadByAffiliates()
      .then(async () => {
        await loadByCompany();
        await loadCancellations();
        await loadPaidMembers();
        await loadMembersUpgrade();
      })
      .finally(() => setLoading(false));

    api
      .get<IAffiliateResponse>('students/affiliates/top-affiliate')
      .then((response) => {
        const data: IAffiliate = {
          name: response.data.name,
          avatar: response.data.avatar ? response.data.avatar.avatar_url : '',
          referred_members: response.data.affiliate
            ? response.data.affiliate.referrers.length
            : 0,
        };
        setTopAffiliate(data);
      });
  }, [
    loadByAffiliates,
    loadByCompany,
    loadCancellations,
    loadMembersUpgrade,
    loadPaidMembers,
  ]);

  const handleClose = useCallback(() => {
    setShow(false);
  }, []);

  const handleSubmitAdspend = useCallback(
    async (data) => {
      try {
        setAdspend(data.adspend);
        handleClose();
      } catch (error) {
        console.log(error);
      }
    },
    [handleClose]
  );

  return (
    <Container>
      <div className="container-fluid container-xxl">
        <div className="row">
          <div className="col-12 mb-2 px-0">
            <Welcome>
              <div className="container-fluid">
                <div className="row align-items-center">
                  <div className="col-lg-12 col-xl-9 px-0">
                    <h1 className="h3 pl-3 h2-sm">Hello, {userName}!</h1>
                  </div>
                  <div className="col-lg-12 col-xxl-3 px-0 py-3">
                    <div className="d-flex align-items-center justify-content-xxl-center">
                      <h2 className="h6 h5-xxl mb-0 light-grey-text">
                        Top Coach and Affiliate
                      </h2>
                      <img src={star} className="ml-3" alt="star icon" />
                    </div>
                  </div>
                </div>
              </div>
            </Welcome>

            <CoachAndAffiliate className="col-12 my-3 px-0 d-xxl-none webkit-box">
              <div className="pr-2 w-650">
                <TopCoach />
              </div>
              <div className="pl-2 w-650">
                <TopAfiliate />
              </div>
              <div className="w-50 w-sm-25 bg ml-3 mb-3 progress-bar">
                <ProgressBar color="#00F9AB" percentage={parseFloat(profit)} />
              </div>
            </CoachAndAffiliate>
          </div>

          <div className="col p-0">
            <Content>
              <div className="container-fluid">
                <div className="row mb-3">
                  <div className="col-12 px-0 d-lg-none">
                    <Graph date={endDate} />
                  </div>
                  <div className="col-sm-6 col-lg-3 px-0 mt-3 mt-lg-0 pr-sm-2">
                    <div className="bg-gray w-100 h-sm-100 d-flex flex-column justify-content-between">
                      <p className="small font-weight-300">
                        New Referred Members
                      </p>
                      <div className="d-flex align-items-end justify-content-between">
                        <img
                          src={members}
                          alt="Members"
                          className="icons pb-1"
                        />
                        <div className="text-right">
                          <p className="small text-green mb-0 font-weight-300">
                            <small>+{newMembersPercent.toFixed(2)}%</small>
                          </p>
                          <p className="mb-0 h4 h5-lg h4-xl h5-xxl text-green font-weight-400">
                            +{newMembers.length}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-6 col-lg-3 px-0 mt-3 mt-lg-0 pl-sm-2 pr-lg-2">
                    <div className="bg-gray w-100 h-sm-100 d-flex flex-column justify-content-between">
                      <p className="small font-weight-300">Total Members</p>
                      <div className="d-flex align-items-end justify-content-between">
                        <img
                          src={members}
                          alt="Members"
                          className="icons pb-1"
                        />
                        <div className="text-right">
                          <p className="mb-0 h4 h5-lg h4-xl h5-xxl font-weight-400">
                            {totalMembers}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-6 col-lg-3 px-0 mt-3 mt-lg-0 pr-sm-2 pl-lg-2">
                    <div className="bg-gray w-100 h-100 d-flex flex-column justify-content-between">
                      <p className="small font-weight-300">
                        Revenue this Month
                      </p>
                      <div className="d-flex align-items-end justify-content-between">
                        <img
                          src={reveues}
                          alt="Reveues"
                          className="icons pb-1"
                        />
                        <div className="text-right">
                          <p className="small text-green mb-0 font-weight-300">
                            <small>+{newRevenuePercent.toFixed(2)}%</small>
                          </p>
                          <p className="mb-0 h4 h5-lg h4-xl h5-xxl text-green font-weight-400">
                            +{newRevenue}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-6 col-lg-3 px-0 mt-3 mt-lg-0 pl-sm-2">
                    <div className="bg-gray w-100 h-sm-100 d-flex flex-column justify-content-between">
                      <p className="small font-weight-300">Total Revenue</p>
                      <div className="d-flex align-items-end justify-content-between">
                        <img
                          src={totalReveues}
                          alt="Total Reveues"
                          className="icons pb-1"
                        />
                        <div className="text-right">
                          <p className="mb-0 h4 h5-lg h4-xl h5-xxl font-weight-400 text-green">
                            {totalRevenuePrice}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-sm-6 col-lg-3 px-0 mt-3 pr-sm-2">
                    <div className="bg-gray w-100 h-sm-100 d-flex flex-column justify-content-between">
                      <p className="small font-weight-300">
                        New Direct Members
                      </p>
                      <div className="d-flex align-items-end justify-content-between">
                        <img
                          src={members}
                          alt="Members"
                          className="icons pb-1"
                        />
                        <div className="text-right">
                          <p className="small text-green mb-0 font-weight-300">
                            <small>
                              +{newDirectMembersPercent.toFixed(2)}%
                            </small>
                          </p>
                          <p className="mb-0 h4 h5-lg h4-xl h5-xxl text-green font-weight-400">
                            +{newDirectMembers.length}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-6 col-lg-3 px-0 mt-3 pl-sm-2 pr-lg-2">
                    <div className="bg-gray w-100 h-sm-100 d-flex flex-column justify-content-between">
                      <p className="small font-weight-300">
                        Total Direct Members
                      </p>
                      <div className="d-flex align-items-end justify-content-between">
                        <img
                          src={members}
                          alt="Members"
                          className="icons pb-1"
                        />
                        <div className="text-right">
                          <p className="mb-0 h4 h5-lg h4-xl h5-xxl font-weight-400">
                            {directTotalMembers}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-6 col-lg-3 px-0 mt-3 pr-sm-2 pl-lg-2">
                    <div className="bg-gray w-100 d-flex flex-column justify-content-between">
                      <p className="small font-weight-300">
                        Direct Revenue this month
                      </p>
                      <div className="d-flex align-items-end justify-content-between">
                        <img
                          src={reveues}
                          alt="Reveues"
                          className="icons pb-1"
                        />
                        <div className="text-right">
                          <p className="small text-green mb-0 font-weight-300">
                            <small>
                              +{newDirectRevenuePercent.toFixed(2)}%
                            </small>
                          </p>
                          <p className="mb-0 h4 h5-lg h4-xl h5-xxl text-green font-weight-400">
                            +{newDirectRevenue}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-6 col-lg-3 px-0 mt-3 pl-sm-2">
                    <div className="bg-gray w-100 h-sm-100 d-flex flex-column justify-content-between">
                      <p className="small font-weight-300">
                        Total Direct Revenue
                      </p>
                      <div className="d-flex align-items-end justify-content-between">
                        <img
                          src={totalReveues}
                          alt="Total Direct Reveues"
                          className="icons pb-1"
                        />
                        <div className="text-right">
                          <p className="mb-0 h4 h5-lg h4-xl h5-xxl font-weight-400 text-green">
                            {directTotalRevenuePrice}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-8 px-0 d-flex flex-column justify-content-between">
                    <div className="d-none d-lg-block">
                      <Graph date={endDate} />
                    </div>
                  </div>
                  <div className="col-lg-4 px-0 pl-lg-3">
                    <div className="h-100 d-sm-flex d-lg-block align-items-center bg-gray">
                      <div className="d-flex w-100 align-items-center justify-content-between mb-3 mt-2">
                        <h3 className="h6 mb-0 font-weight-400">New Members</h3>
                        <Link
                          to={`${process.env.PUBLIC_URL}/dashboard`}
                          className="btn-edit rounded-pill py-1 px-4 bg-transparent"
                        >
                          View All
                        </Link>
                      </div>
                      <ol>
                        {newMembers.map((newMember, index) => (
                          <li
                            key={newMember.id}
                            className="d-flex align-items-center my-2 pb-2 border-bottom"
                          >
                            <p className="mb-0">{index + 1}.</p>
                            <img
                              src={newMember.avatar.avatar_url}
                              alt={newMember.name}
                              className="avatar-coach mx-3"
                            />
                            <p className="mb-0">{newMember.name}</p>
                          </li>
                        ))}
                      </ol>
                    </div>
                  </div>
                </div>
                <div className="row mt-3">
                  <div className="col-sm-6 col-lg-3 px-0 pr-sm-2">
                    <div className="bg-gray h-100 w-100 d-flex flex-column justify-content-between">
                      <div className="d-flex justify-content-between">
                        <p className="small font-weight-300 mb-0">Adspend</p>
                        <button
                          type="button"
                          className="btn-edit rounded-pill py-1 px-3 bg-transparent"
                          onClick={() => setShow(true)}
                        >
                          Edit
                        </button>
                      </div>
                      <div className="d-flex align-items-end justify-content-between">
                        <img
                          src={adspendIcon}
                          alt="Adspend"
                          className="icons pb-1"
                        />
                        <div className="text-right">
                          <p className="mb-0 h4 h5-lg h4-xl h5-xxl text-green font-weight-400">
                            {adspend}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-6 col-lg-3 px-0 mt-3 mt-sm-0 pl-sm-2 pr-lg-2">
                    <div className="bg-gray h-sm-100 w-100 d-flex flex-column justify-content-between">
                      <p className="small font-weight-300">Paid Members</p>
                      <div className="d-flex align-items-end justify-content-between">
                        <img
                          src={paidMembersIcon}
                          alt="Paid Members"
                          className="icons pb-1"
                        />
                        <div className="text-right">
                          <p className="mb-0 h4 h5-lg h4-xl h5-xxl font-weight-400">
                            {paidMembers}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-sm-6 col-lg-3 px-0 mt-3 mt-lg-0 pr-sm-2 pl-lg-2">
                    <div className="bg-gray h-100 w-100 d-flex flex-column justify-content-between">
                      <p className="small font-weight-300">Average Sales</p>
                      <div className="d-flex align-items-end justify-content-between">
                        <img
                          src={average}
                          alt="Average Sales"
                          className="icons"
                        />
                        <div className="text-right">
                          <p className="mb-n1 h4 h5-lg h4-xl h5-xxl text-green font-weight-400">
                            {averageSales}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-6 col-lg-3 px-0 mt-3 mt-lg-0 pl-sm-2">
                    <div className="bg-gray h-100 w-100 d-flex flex-column justify-content-between">
                      <p className="small font-weight-300">CAC</p>
                      <div className="d-flex align-items-end justify-content-between">
                        <img
                          src={totalReveues}
                          alt="Total Direct Reveues"
                          className="icons"
                        />
                        <div className="text-right">
                          <p className="mb-n1 h4 h5-lg h4-xl h5-xxl text-green font-weight-400">
                            {cac}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row mt-3">
                  <div className="col-sm-6 col-lg-3 px-0 pr-sm-2">
                    <div className="bg-gray h-100 w-100 d-flex flex-column justify-content-between">
                      <p className="small font-weight-300">Members Upgrade</p>
                      <div className="d-flex align-items-end justify-content-between">
                        <img
                          src={upgrade}
                          alt="Members Upgrade"
                          className="icons pb-1"
                        />
                        <div className="text-right">
                          <p className="mb-0 h4 h5-lg h4-xl h5-xxl font-weight-400">
                            {membersUpgrade}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-6 col-lg-3 px-0 mt-3 mt-sm-0 pl-sm-2 pr-lg-2">
                    <div className="bg-gray h-sm-100 w-100 d-flex flex-column justify-content-between">
                      <p className="small font-weight-300">
                        Average Upgrade Sales
                      </p>
                      <div className="d-flex align-items-end justify-content-between">
                        <img
                          src={average}
                          alt="Average Sales"
                          className="icons pb-1"
                        />
                        <div className="text-right">
                          <p className="mb-0 h4 h5-lg h4-xl h5-xxl text-green font-weight-400">
                            {averageUpgradeSales}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-sm-6 col-lg-3 px-0 mt-3 mt-lg-0 pr-sm-2 pl-lg-2">
                    <div className="bg-gray h-100 w-100 d-flex flex-column justify-content-between">
                      <p className="small font-weight-300">
                        Average Upgrade Time
                      </p>
                      <div className="d-flex align-items-end justify-content-between">
                        <img
                          src={watch}
                          alt="Average Upgrade Time"
                          className="icons"
                        />
                        <div className="text-right">
                          <p className="mb-n1 h4 h5-lg h4-xl h5-xxl font-weight-400">
                            {averageUpgradeTime} Days
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-6 col-lg-3 px-0 mt-3 mt-lg-0 pl-sm-2">
                    <div className="bg-gray h-100 w-100 d-flex flex-column justify-content-between">
                      <p className="small font-weight-300">
                        Total Upgrade Revenue
                      </p>
                      <div className="d-flex align-items-end justify-content-between">
                        <img
                          src={totalReveues}
                          alt="Total Reveues"
                          className="icons"
                        />
                        <div className="text-right">
                          <p className="mb-n1 h4 h5-lg h4-xl h5-xxl font-weight-400 text-green">
                            {totalUpgradeSales}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row mt-3">
                  <div className="col-sm-6 col-lg-3 px-0 pr-sm-2">
                    <div className="bg-gray h-100 w-100 d-flex flex-column justify-content-between">
                      <p className="small font-weight-300">
                        Member Cancellation
                      </p>
                      <div className="d-flex align-items-end justify-content-between">
                        <img
                          src={members}
                          alt="Members"
                          className="icons pb-1"
                        />
                        <div className="text-right">
                          <p className="small text-red mb-0 font-weight-300">
                            <small>
                              -{memberCancelationPercent.toFixed(2)}%
                            </small>
                          </p>
                          <p className="mb-0 h4 h5-lg h4-xl h5-xxl text-red font-weight-400">
                            -{memberCancelation}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-6 col-lg-3 px-0 mt-3 mt-sm-0 pl-sm-2 pr-lg-2">
                    <div className="bg-gray h-sm-100 w-100 d-flex flex-column justify-content-between">
                      <p className="small font-weight-300">
                        Member Reimbursement
                      </p>
                      <div className="d-flex align-items-end justify-content-between">
                        <img
                          src={members}
                          alt="Members"
                          className="icons pb-1"
                        />
                        <div className="text-right">
                          <p className="small text-red mb-0 font-weight-300">
                            <small>-{refundPercent}%</small>
                          </p>
                          <p className="mb-0 h4 h5-lg h4-xl h5-xxl text-red font-weight-400">
                            {refundPrice}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-sm-6 col-lg-3 px-0 mt-3 mt-lg-0 pr-sm-2 pl-lg-2">
                    <div className="bg-gray h-100 w-100 d-flex flex-column justify-content-between">
                      <p className="small font-weight-300">
                        Pending Support Tickets
                      </p>
                      <div className="d-flex align-items-end justify-content-between">
                        <img
                          src={pendingTicket}
                          alt="Pending Support Tickets"
                          className="icons"
                        />
                        <div className="text-right">
                          <p className="small text-green mb-0 font-weight-300">
                            <small>+1.4%</small>
                          </p>
                          <p className="mb-n1 h4 h5-lg h4-xl h5-xxl font-weight-400">
                            49
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-6 col-lg-3 px-0 mt-3 mt-lg-0 pl-sm-2">
                    <div className="bg-gray h-100 w-100 d-flex flex-column justify-content-between">
                      <p className="small font-weight-300">Tickets Solved</p>
                      <div className="d-flex align-items-end justify-content-between">
                        <img
                          src={resolveTicket}
                          alt="Resolved Support Tickets"
                          className="icons"
                        />
                        <div className="text-right">
                          <p className="small text-green mb-0 font-weight-300">
                            <small>90.4%</small>
                          </p>
                          <p className="mb-n1 h4 h5-lg h4-xl h5-xxl font-weight-400">
                            91
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Content>
          </div>
          {Object.keys(topAffiliate).length > 0 && (
            <CoachAndAffiliate className="col-lg-3 d-none pr-0 d-xxl-block">
              <div className="d-none d-xxl-block mb-3">
                <TopCoach />
              </div>
              <div className="d-none d-xxl-block">
                <TopAfiliate />
              </div>
              <div className="w-100 bg mt-3">
                <ProgressBar color="#00F9AB" percentage={parseFloat(profit)} />
              </div>
            </CoachAndAffiliate>
          )}
        </div>
      </div>
      <Modal show={show} onHide={handleClose}>
        <Form onSubmit={handleSubmitAdspend} className="p-3">
          <Modal.Header closeButton>
            <Modal.Title className="ml-auto">Adspend Edit</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <label className="w-100">
              <span>New Adspend</span>
              <InputMask
                kind="money"
                options={{
                  unit: '$',
                  delimiter: ',',
                  separator: '.',
                }}
                id="adspend"
                name="adspend"
                className="py-3 mt-3"
                value={adspend}
              />
            </label>
          </Modal.Body>
          <Modal.Footer className="d-flex justify-content-center">
            <button type="submit" className="btn submit-button px-3 py-2 w-75">
              Save
            </button>
          </Modal.Footer>
        </Form>
      </Modal>
      {loading && (
        <>
          <div className="loading-box">
            <div className="spinner-border text-light" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        </>
      )}
    </Container>
  );
};

export default Home;
