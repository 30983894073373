import React, {
  ChangeEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { RiSearchLine } from 'react-icons/ri';
import { RxPlus } from 'react-icons/rx';
import { useLocation } from 'react-router-dom';
import { MdDelete, MdEdit } from 'react-icons/md';
import { IoCloseOutline } from 'react-icons/io5';
import { Form } from '@unform/web';
import Swal from 'sweetalert2';
import * as Yup from 'yup';
import { FormHandles } from '@unform/core';

import api from '~/services/api';
import getValidationErros from '~/utils/getValidationsErrors';

import { Container, Modal } from './styles';
import Table from '~/components/Table';
import Input from '~/components/Input';
import Toast from '~/utils/toast';
import InputCheckbox, { IOption } from '~/components/InputCheckbox';

interface IUserTypeMenu {
  id: string;
  user_type_id: string;
  menu_id: string;
}

interface IUserType {
  id: string;
  type: string;
  usersTypesMenus: IUserTypeMenu[];
}

// interface IUserTypeResponse {
//   data: IUserType[];
//   from: number;
//   to: number;
//   total: number;
//   pages: number;
// }

// interface ITableData {
//   from: number;
//   to: number;
//   total: number;
//   current_page: number;
// }

interface IFormData {
  type: string;
}

interface IMenu {
  id: string;
  title: string;
}

const UsersTypes: React.FC = () => {
  const addFormRef = useRef<FormHandles>(null);
  const updateFormRef = useRef<FormHandles>(null);
  const location = useLocation();
  const [usersTypes, setUsersTypes] = useState<IUserType[]>([]);
  // const [tableData, setTableData] = useState<ITableData>({
  //   from: 0,
  //   to: 0,
  //   total: 0,
  //   current_page: 1,
  // });
  const [page] = useState(1);
  const [search, setSearch] = useState('');
  const [showAdd, setShowAdd] = useState(false);
  const [showUpdate, setShowUpdate] = useState(false);
  const [userTypeSelected, setUserTypeSelected] = useState({} as IUserType);
  const [menus, setMenus] = useState<IOption[]>([]);
  const [oldMenus, setOldMenus] = useState<IOption[]>([]);

  useEffect(() => {
    api.get<IMenu[]>('menus').then((response) => {
      const data = response.data.map<IOption>((menu) => ({
        label: menu.title,
        value: menu.id,
        selected: false,
      }));

      setMenus(data);
      setOldMenus(data);
    });
  }, []);

  const handleLoadUsersTypes = useCallback(
    async (pageData: number, searchData = '') => {
      const response = await api.get<IUserType[]>('users-types', {
        params: {
          search: searchData,
        },
      });

      setUsersTypes(response.data);
      // setTableData({
      //   from: response.data.from,
      //   to: response.data.to,
      //   total: response.data.total,
      //   current_page: pageData,
      // });
    },
    []
  );

  useEffect(() => {
    handleLoadUsersTypes(1);
  }, [handleLoadUsersTypes, location.pathname]);

  const handleSearch = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const { value } = e.target;
      handleLoadUsersTypes(1, value);
      setSearch(value);
    },
    [handleLoadUsersTypes]
  );

  const handleClickUpdate = useCallback((userType: IUserType) => {
    setMenus((state) =>
      state.map((menu) => ({
        ...menu,
        selected: userType.usersTypesMenus.some(
          (userTypeMenu) => userTypeMenu.menu_id === menu.value
        ),
      }))
    );
    setOldMenus((state) =>
      state.map((menu) => ({
        ...menu,
        selected: userType.usersTypesMenus.some(
          (userTypeMenu) => userTypeMenu.menu_id === menu.value
        ),
      }))
    );
    setUserTypeSelected(userType);
    setShowUpdate(true);
  }, []);

  const handleClickDelete = useCallback(
    (userType: IUserType) => {
      Swal.fire({
        title: `Do you want to delete the access type: ${userType.type}?`,
        icon: 'warning',
        showCloseButton: true,
        showCancelButton: true,
        confirmButtonText: 'Yes',
        confirmButtonColor: '#202020',
        cancelButtonColor: '#777777',
        cancelButtonText: 'No',
        reverseButtons: true,
        iconColor: '#202020',
      })
        .then(async (result) => {
          if (result.isConfirmed) {
            await api.delete(`users-types/${userType.id}`);

            const newUsersTypes = usersTypes.filter(
              (userTypeData) => userTypeData.id !== userType.id
            );

            setUsersTypes(newUsersTypes);

            Toast.fire({
              icon: 'success',
              title: 'Access type deleted!',
            });
          }
        })
        .catch(() => {
          Swal.fire(
            'Opss...',
            'Ocorreu um erro, tente novamente ou entre em contato com o suporte.',
            'error'
          );
        });
    },
    [usersTypes]
  );

  const columns = useMemo(
    () => [
      {
        name: '#',
        selector: 'id',
        cell: (_: IUserType, index: number) =>
          (index + 1).toString().padStart(2, '0'),
      },
      {
        name: 'Type',
        selector: 'type',
      },
      {
        name: '',
        selector: 'id',
        cell: (row: IUserType) => (
          <div className="w-100 d-flex justify-content-end">
            <button
              type="button"
              className="border-0 bg-transparent mr-2"
              onClick={() => handleClickUpdate(row)}
            >
              <MdEdit size={24} color="#777777" />
            </button>
            <button
              type="button"
              className="border-0 bg-transparent"
              onClick={() => handleClickDelete(row)}
            >
              <MdDelete size={24} color="#FF1A50" />
            </button>
          </div>
        ),
      },
    ],
    [handleClickDelete, handleClickUpdate]
  );

  // const handleChangePage = useCallback((pageData: number) => {
  //   setPage(pageData);
  // }, []);

  const handleClose = useCallback(() => {
    setShowAdd(false);
    setShowUpdate(false);
    setUserTypeSelected({} as IUserType);
    setMenus((state) => state.map((menu) => ({ ...menu, selected: false })));
    setOldMenus((state) => state.map((menu) => ({ ...menu, selected: false })));
  }, []);

  const handleSubmitAdd = useCallback(
    async (data: IFormData) => {
      try {
        addFormRef.current?.setErrors({});

        const schema = Yup.object().shape({
          type: Yup.string().required(),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        const formData = {
          type: data.type,
        };

        const response = await api.post('users-types', formData);

        const menusSelecteds = menus.filter((menu) => menu.selected);

        if (menusSelecteds.length > 0) {
          await new Promise<void>((resolve) => {
            menusSelecteds.forEach(async (menu, index) => {
              await api.post('users-types-menus', {
                user_type_id: response.data.id,
                menu_id: menu.value,
              });

              if (menusSelecteds.length === index + 1) {
                resolve();
              }
            });
          });
        }

        handleLoadUsersTypes(page, search);

        handleClose();

        Toast.fire({
          icon: 'success',
          title: 'Access types saved!',
        });
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErros(error);
          addFormRef.current?.setErrors(errors);
        } else {
          Swal.fire('Oops...', 'Ocorreu um erro tente novamente, por favor');
        }
      }
    },
    [handleClose, handleLoadUsersTypes, menus, page, search]
  );
  //
  const handleSubmitUpdate = useCallback(
    async (data: IFormData) => {
      try {
        updateFormRef.current?.setErrors({});

        const schema = Yup.object().shape({
          type: Yup.string().required(),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        const formData = {
          type: data.type,
        };

        const response = await api.put(
          `users-types/${userTypeSelected.id}`,
          formData
        );

        const menusSelecteds = menus.filter((menu) => menu.selected);
        const oldMenusSelecteds = oldMenus.filter((menu) => menu.selected);

        const removedMenus = oldMenusSelecteds.filter(
          (oldMenu) =>
            !menusSelecteds.some((menu) => menu.value === oldMenu.value)
        );

        if (removedMenus.length > 0) {
          await new Promise<void>((resolve) => {
            removedMenus.forEach(async (menu, index) => {
              await api.delete(
                `users-types-menus/${response.data.id}/${menu.value}`
              );

              if (removedMenus.length === index + 1) {
                resolve();
              }
            });
          });
        }

        const newMenus = menusSelecteds.filter(
          (menu) =>
            !oldMenusSelecteds.some((oldMenu) => oldMenu.value === menu.value)
        );

        if (newMenus.length > 0) {
          await new Promise<void>((resolve) => {
            newMenus.forEach(async (menu, index) => {
              await api.post(`users-types-menus`, {
                user_type_id: response.data.id,
                menu_id: menu.value,
              });

              if (newMenus.length === index + 1) {
                resolve();
              }
            });
          });
        }

        const newUsersTypes = usersTypes.slice();
        const userTypeIndex = newUsersTypes.findIndex(
          (userType) => userType.id === userTypeSelected.id
        );

        if (userTypeIndex >= 0) {
          newUsersTypes[userTypeIndex].type = data.type;
          newUsersTypes[userTypeIndex].usersTypesMenus = menusSelecteds.map(
            (menu) =>
              ({
                id: new Date().getTime().toString(),
                menu_id: menu.value,
                user_type_id: response.data.id,
              } as IUserTypeMenu)
          );
        }

        setUsersTypes(newUsersTypes);

        handleClose();

        Toast.fire({
          icon: 'success',
          title: 'Access types updated!',
        });
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErros(error);
          updateFormRef.current?.setErrors(errors);
        } else {
          Swal.fire('Oops...', 'Ocorreu um erro tente novamente, por favor');
        }
      }
    },
    [userTypeSelected.id, menus, oldMenus, usersTypes, handleClose]
  );

  const handleChangeMenus = useCallback((options: IOption[]) => {
    setMenus(options);
  }, []);

  return (
    <Container className="py-5">
      <div className="container">
        <div className="row">
          <div className="col-12 d-flex justify-content-between align-items-center mb-5">
            <h1>Access types</h1>
            <button
              type="button"
              className="btn-new align-items-center px-5 ml-lg-5 ml-auto mt-5 mt-sm-0 rounded-pill text-center d-inline-flex justify-content-center"
              onClick={() => setShowAdd(true)}
            >
              New access types{' '}
              <RxPlus size={20} color="#fff" className="ml-2" />
            </button>
          </div>
          <div className="col-12">
            <div className="box p-4">
              <div className="w-100 d-flex">
                <div className="d-flex align-items-center input w-100">
                  <input
                    placeholder="Search access type"
                    onChange={handleSearch}
                    className="border-0 bg-transparent"
                  />{' '}
                  <RiSearchLine size={24} color="#777777" className="ms-2" />
                </div>
              </div>
              <div className="row mt-4">
                <div className="col-12">
                  <Table columns={columns} data={usersTypes} pagination />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal show={showAdd} centered onHide={handleClose} size="lg">
        <Form ref={addFormRef} onSubmit={handleSubmitAdd} className="p-3">
          <Modal.Header className="border-0">
            <h3>New access type:</h3>
            <button
              type="button"
              onClick={handleClose}
              className="border-0 bg-transparent close-btn"
            >
              <IoCloseOutline size={38} color="#202020" />
            </button>
          </Modal.Header>
          <Modal.Body className="d-flex flex-column align-items-center justify-content-center">
            <label className="w-100 mb-3">
              <span className="d-block mb-2 fw-medium">Type</span>
              <Input name="type" placeholder="Type here" />
            </label>
            <label className="w-100">
              <span className="d-block mb-2 fw-medium">Menus access</span>
            </label>
            <InputCheckbox
              type="checkbox"
              name="menus"
              options={menus}
              className="menus"
              onChange={handleChangeMenus}
            />
          </Modal.Body>
          <Modal.Footer className="border-0">
            <button type="submit" className="btn-grey px-4 mr-3 py-2">
              Save
            </button>
          </Modal.Footer>
        </Form>
      </Modal>
      <Modal show={showUpdate} centered onHide={handleClose} size="lg">
        <Form
          ref={updateFormRef}
          onSubmit={handleSubmitUpdate}
          initialData={userTypeSelected}
          className="p-3"
        >
          <Modal.Header className="border-0">
            <h3>Update access type:</h3>
            <button
              type="button"
              onClick={handleClose}
              className="border-0 bg-transparent close-btn"
            >
              <IoCloseOutline size={38} color="#202020" />
            </button>
          </Modal.Header>
          <Modal.Body className="d-flex flex-column align-items-center justify-content-center">
            <label className="w-100 mb-3">
              <span className="d-block mb-2 fw-medium">Type</span>
              <Input name="type" placeholder="Type here" />
            </label>
            <label className="w-100">
              <span className="d-block mb-2 fw-medium">Menus access</span>
            </label>
            <InputCheckbox
              type="checkbox"
              name="menus"
              options={menus}
              className="menus"
              onChange={handleChangeMenus}
            />
          </Modal.Body>
          <Modal.Footer className="border-0">
            <button type="submit" className="btn-grey px-4 mr-3 py-2">
              Save
            </button>
          </Modal.Footer>
        </Form>
      </Modal>
    </Container>
  );
};

export default UsersTypes;
