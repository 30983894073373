import styled from 'styled-components';
import { lighten } from 'polished';
import { Modal as ModalComponent } from 'react-bootstrap';

export const Container = styled.div`
  .webkit-box {
    overflow: auto;
    display: -webkit-box;
  }
`;

export const AllCourses = styled.div``;

export const Welcome = styled.div`
  h1 {
    color: #e4e4e4;
  }

  p {
    color: #969696;
  }

  .btn-month {
    background: #242526;
    border: 1px solid rgba(225, 225, 225, 0.2);

    span {
      color: #8c8c8c;
    }
  }
`;

export const CoachAndAffiliate = styled.div`
  .bg {
    background: #202020;
    border-radius: 20px;
  }

  .progress-bar {
    > div {
      min-height: 100% !important;
      align-items: center;
      display: flex;
    }
  }
  @media screen and (max-width: 991px) {
    .w-650 {
      width: 650px;
    }
  }
`;

export const Content = styled.div`
  background: #202020;
  border-radius: 20px;
  padding: 20px;

  .btn-edit {
    border: solid 1px #606060;
    font-size: 13px;
    color: #606060 !important;
    text-decoration: none;
    :hover {
      border: solid 1px ${lighten(0.2, '#606060')};
      color: ${lighten(0.2, '#606060')} !important;
    }
  }

  .title-chart {
    h3,
    p {
      color: #cbccce;
    }
  }

  .btn-month {
    background: #242526;
    border: 1px solid rgba(225, 225, 225, 0.2);

    span {
      color: #8c8c8c;
    }
  }

  .bg-gray {
    background: #242526;
    border-radius: 20px;
    padding: 15px 20px;

    h3,
    p {
      color: #cbccce;
    }

    li {
      border-color: #474848 !important;
    }
  }

  .icons {
    width: 30px;
    height: 30px;
  }

  .text-green {
    color: #37c694 !important;
  }

  .text-red {
    color: #ff1a50 !important;
  }

  .avatar-coach {
    width: 40px;
    height: 40px;
    border-radius: 50%;
  }

  @media screen and (max-width: 575px) {
    padding: 15px;
    .bg-gray {
      height: 110px;
    }
  }
`;

export const Graph = styled.div`
  background: #242526;
  border-radius: 20px;
  padding: 20px;
`;

export const TopCoach = styled.div`
  background: #202020;
  border-radius: 20px;

  .avatar {
    width: 90px;
    height: 90px;
    padding: 3px;
    background-image: linear-gradient(0deg, #202020, #202020),
      linear-gradient(180deg, #825511 0%, #f3bf5c 100%);
    background-origin: border-box;
    background-clip: content-box, border-box;
    border-image-slice: 1;
    border-radius: 50%;
  }
`;

export const TopAfiliate = styled.div`
  background: #202020;
  border-radius: 20px;

  .btn-month {
    background: #242526;
    border: 1px solid rgba(225, 225, 225, 0.2);

    span {
      color: #8c8c8c;
    }
  }

  .avatar {
    width: 90px;
    height: 90px;
    padding: 3px;
    background-image: linear-gradient(0deg, #202020, #202020),
      linear-gradient(180deg, #825511 0%, #f3bf5c 100%);
    background-origin: border-box;
    background-clip: content-box, border-box;
    border-image-slice: 1;
    border-radius: 50%;
  }
`;

export const Modal = styled(ModalComponent)`
  .submit-button {
    background-color: #606060;
    border: none;
    border-radius: 15px;
    color: #e4e4e4 !important;
    font-weight: bold;
    text-align: center;

    :hover {
      text-decoration: none;
      background-color: ${lighten(0.1, '#606060')};
      transition: 0.5s;
    }
  }
`;
