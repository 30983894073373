import React from 'react';

import { IProps } from './interfaces';

const WsCoaches: React.FC<IProps> = ({
  size = 32,
  color = '#000',
  className,
}) => {
  return (
    <svg
      className={className}
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.591 15.9565L11.9108 15.9579C16.5552 15.9983 19.183 16.9363 19.183 19.4985C19.183 22.0064 16.6645 22.9738 12.2231 23.0549L11.591 23.0605C6.74666 23.0605 4 22.1396 4 19.5185C4 16.8939 6.75759 15.9565 11.591 15.9565ZM11.591 17.4565C7.57365 17.4565 5.5 18.1614 5.5 19.5185C5.5 20.8669 7.56868 21.5605 11.591 21.5605C15.6086 21.5605 17.683 20.8555 17.683 19.4985C17.683 18.1508 15.6124 17.4565 11.591 17.4565ZM11.591 4C14.439 4 16.726 6.28661 16.726 9.134C16.726 11.9822 14.4392 14.269 11.591 14.269C8.74293 14.269 6.457 11.9824 6.457 9.134C6.457 6.28647 8.74311 4 11.591 4ZM11.591 5.5C9.57159 5.5 7.957 7.11484 7.957 9.134C7.957 11.154 9.57146 12.769 11.591 12.769C13.6108 12.769 15.226 11.1538 15.226 9.134C15.226 7.11509 13.6107 5.5 11.591 5.5Z"
        fill={color}
      />
      <path
        d="M19.8125 8.50003V10.7667C19.8125 11.2176 19.6248 11.65 19.2908 11.9688C18.9567 12.2876 18.5037 12.4667 18.0312 12.4667C17.8738 12.4667 17.7228 12.407 17.6114 12.3007C17.5001 12.1945 17.4375 12.0503 17.4375 11.9V7.36669C17.4375 7.2164 17.5001 7.07227 17.6114 6.966C17.7228 6.85972 17.8738 6.80002 18.0312 6.80002C18.5037 6.80002 18.9567 6.97913 19.2908 7.29794C19.6248 7.61675 19.8125 8.04916 19.8125 8.50003ZM3.1875 8.50003C3.1875 8.04916 3.37517 7.61675 3.70922 7.29794C4.04327 6.97913 4.49634 6.80002 4.96875 6.80002C5.12622 6.80002 5.27725 6.85972 5.3886 6.966C5.49995 7.07227 5.5625 7.2164 5.5625 7.36669V11.9C5.5625 12.0503 5.49995 12.1945 5.3886 12.3007C5.27725 12.407 5.12622 12.4667 4.96875 12.4667C4.49634 12.4667 4.04327 12.2876 3.70922 11.9688C3.37517 11.65 3.1875 11.2176 3.1875 10.7667V8.50003Z"
        fill={color}
      />
      <path
        d="M14.8396 14.9267C14.963 15.0032 15.108 15.0441 15.2563 15.0441C15.4552 15.0441 15.646 14.9707 15.7866 14.84C15.9273 14.7094 16.0063 14.5321 16.0063 14.3473C16.0063 14.2095 15.9623 14.0748 15.8799 13.9602C15.7975 13.8456 15.6804 13.7563 15.5433 13.7036C15.4063 13.6509 15.2555 13.6371 15.11 13.6639C14.9645 13.6908 14.8309 13.7572 14.726 13.8546C14.6211 13.9521 14.5497 14.0762 14.5207 14.2114C14.4918 14.3466 14.5066 14.4867 14.5634 14.614C14.6202 14.7413 14.7163 14.8501 14.8396 14.9267Z"
        fill={color}
      />
      <path
        d="M5.30123 4.79932C5.24705 4.8838 5.22872 4.98624 5.25027 5.08416C5.27181 5.18209 5.33147 5.26743 5.41615 5.32159C5.50084 5.37574 5.60363 5.39427 5.70197 5.37302C5.80032 5.35177 5.88619 5.29248 5.94074 5.20824C6.57161 4.22934 7.43927 3.42406 8.46405 2.86632C9.48882 2.30858 10.6378 2.01626 11.8056 2.01626C12.9733 2.01626 14.1224 2.30858 15.1471 2.86632C16.1719 3.42406 17.0396 4.22934 17.6704 5.20824C17.7249 5.29272 17.8108 5.35224 17.9092 5.37363C18.0077 5.39501 18.1107 5.37652 18.1955 5.32228C18.2803 5.26805 18.34 5.18251 18.3615 5.08442C18.3829 4.98634 18.3644 4.8838 18.3099 4.79932C17.6103 3.71367 16.648 2.82054 15.5115 2.20199C14.375 1.58343 13.1007 1.25928 11.8056 1.25928C10.5105 1.25928 9.2362 1.58343 8.09968 2.20199C6.96317 2.82054 6.00089 3.71367 5.30123 4.79932Z"
        fill={color}
      />
    </svg>
  );
};

export default WsCoaches;
