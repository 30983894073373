import React from 'react';
import { IProps } from './interfaces';

const WsDashboard: React.FC<IProps> = ({
  size = 32,
  color = '#000',
  className,
}) => {
  return (
    <svg
      className={className}
      width={size}
      height={size}
      viewBox="0 0 26 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.7153 1H21.8795C23.6024 1 25 2.38876 25 4.102V8.23842C25 9.95036 23.6024 11.3404 21.8795 11.3404H17.7153C15.9911 11.3404 14.5935 9.95036 14.5935 8.23842V4.102C14.5935 2.38876 15.9911 1 17.7153 1Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.12182 1H8.28468C10.0089 1 11.4065 2.38876 11.4065 4.102V8.23842C11.4065 9.95036 10.0089 11.3404 8.28468 11.3404H4.12182C2.39764 11.3404 1 9.95036 1 8.23842V4.102C1 2.38876 2.39764 1 4.12182 1Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.12182 14.48H8.28468C10.0089 14.48 11.4065 15.8687 11.4065 17.5833V21.7184C11.4065 23.4316 10.0089 24.8204 8.28468 24.8204H4.12182C2.39764 24.8204 1 23.4316 1 21.7184V17.5833C1 15.8687 2.39764 14.48 4.12182 14.48Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.7153 14.48H21.8795C23.6024 14.48 25 15.8687 25 17.5833V21.7184C25 23.4316 23.6024 24.8204 21.8795 24.8204H17.7153C15.9911 24.8204 14.5935 23.4316 14.5935 21.7184V17.5833C14.5935 15.8687 15.9911 14.48 17.7153 14.48Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default WsDashboard;
