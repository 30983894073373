import React, {
  ChangeEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import Swal from 'sweetalert2';
import { FormHandles } from '@unform/core';

import { FiArrowLeft } from 'react-icons/fi';
import api from '~/services/api';
import getValidationErros from '~/utils/getValidationsErrors';

import { Container, Welcome, InputsGroup } from './styles';
import Input from '~/components/Input';
import InputCategory, { IValue } from '~/components/InputCategory';
import Textarea from '~/components/Textarea';
import Player from '~/components/Player';

import videoThumb from '~/assets/icons/video_thumb.svg';
import imgThumb from '~/assets/icons/img_thumb.svg';

interface ITraining {
  title: string;
  description: string;
}

interface ICategory {
  id: string;
  name: string;
}

interface ITrainingCategory {
  category: ICategory;
  deleted_at: string;
}

interface IParams {
  slug: string;
}

const TrainingUpdate: React.FC = () => {
  const params = useParams<IParams>();
  const history = useHistory();
  const formRef = useRef<FormHandles>(null);
  const [training, setTraining] = useState<ITraining>({} as ITraining);
  const [thumbnail, setThumbnail] = useState('');
  const [thumbnailSelected, setThumbnailSelected] = useState<File | null>(null);
  const [video, setVideo] = useState('');
  const [videoSelected, setVideoSelected] = useState<File | null>(null);
  const [status, setStatus] = useState('');
  const [trainingId, setTrainingId] = useState('');
  const [thumbnailId, setThumbnailId] = useState('');
  const [videoId, setVideoId] = useState('');
  const [loading, setLoading] = useState(false);
  const [categoriesData, setCategoriesData] = useState<IValue[]>([]);
  const [categoriesDeleted, setCategoriesDeleted] = useState<IValue[]>([]);

  useEffect(() => {
    setLoading(true);
    window.scrollTo(0, 0);

    api.get(`trainings/${params.slug}`).then((response) => {
      const data = {
        title: response.data.title,
        description: response.data.description,
      };

      if (
        response.data.trainingCategory &&
        response.data.trainingCategory.length > 0
      ) {
        const coursesCategories = response.data.trainingCategory.filter(
          (trainingCategory: ITrainingCategory) => !trainingCategory.deleted_at
        );
        const dataCategories: IValue[] = coursesCategories.map(
          (trainingCategory: ITrainingCategory) => ({
            id: trainingCategory.category.id,
            value: trainingCategory.category.name,
          })
        );
        setCategoriesData(dataCategories);
      }

      setTrainingId(response.data.id);
      setThumbnailId(response.data.thumbnail.id);
      setThumbnail(response.data.thumbnail.thumbnail_url);
      setVideoId(response.data.video.id);
      setVideo(
        response.data.video.media_id
          ? `https://cdn.jwplayer.com/videos/${response.data.video.media_id}.mp4`
          : response.data.video.video_url
      );
      setTraining(data);
      setLoading(false);
    });
  }, [params.slug]);

  const handleSelectCategories = useCallback((data) => {
    setCategoriesData(data);
  }, []);

  const handleRemoveCategory = useCallback((data) => {
    if (data.id) {
      setCategoriesDeleted((state) => [...state, data]);
    }
  }, []);

  const handleSelectThumbnail = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      if (e.target.files) {
        const file = e.target.files[0];
        if (file) {
          setThumbnail(URL.createObjectURL(file));
          setThumbnailSelected(file);
        } else {
          setThumbnail('');
          setThumbnailSelected(null);
        }
      }
    },
    []
  );

  const handleSelectVideo = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const file = e.target.files[0];
      if (file) {
        setVideo(URL.createObjectURL(file));
        setVideoSelected(file);
      } else {
        setVideo('');
        setVideoSelected(null);
      }
    }
  }, []);

  const handleRemoveVideo = useCallback(() => {
    setVideo('');
    setVideoSelected(null);
  }, []);

  const handleSubmit = useCallback(
    async (data: ITraining) => {
      try {
        setStatus('validating data!');
        setLoading(true);
        const schema = Yup.object().shape({
          title: Yup.string().required('Title is a required'),
          description: Yup.string().required(
            'Training Description is a required'
          ),
          categories: Yup.string().when('$categoryFilled', {
            is: (categoryFilled: boolean) => categoryFilled,
            then: Yup.string(),
            otherwise: Yup.string().required('Categories is a required'),
          }),
        });

        await schema.validate(data, {
          abortEarly: false,
          context: {
            categoryFilled: categoriesData.length > 0,
          },
        });

        if (categoriesDeleted.length > 0) {
          categoriesDeleted.forEach(async (category) => {
            const trainingsCategoriesResponse = await api.get(
              `trainings-categories/${trainingId}/${category.id}`
            );

            if (trainingsCategoriesResponse.data) {
              await api.delete(
                `trainings-categories/${trainingsCategoriesResponse.data.id}`
              );
            }
          });
        }

        let responseThumbnails;
        if (thumbnailSelected) {
          setStatus('uploading thumbnail!');
          const thumbnailData = new FormData();
          thumbnailData.append('thumbnail', thumbnailSelected as File);
          responseThumbnails = await api.post('thumbnails', thumbnailData);
        } else {
          responseThumbnails = {
            data: {
              id: thumbnailId,
            },
          };
        }

        if (responseThumbnails.data) {
          let responseVideo;
          if (videoSelected) {
            setStatus('uploading video!');
            const videoData = new FormData();
            videoData.append('video', videoSelected as File);
            responseVideo = await api.post('videos', videoData);
          } else {
            responseVideo = {
              data: {
                id: videoId,
              },
            };
          }

          if (responseVideo.data) {
            setStatus('updating training!');
            const { title, description } = data;

            const slug = title
              .replace(/[àáâãäå]/g, 'a')
              .replace(/æ/g, 'ae')
              .replace(/ç/g, 'c')
              .replace(/[èéêë]/g, 'e')
              .replace(/[ìíîï]/g, 'i')
              .replace(/ñ/g, 'n')
              .replace(/[òóôõö]/g, 'o')
              .replace(/œ/g, 'oe')
              .replace(/[ùúûü]/g, 'u')
              .replace(/[ýÿ]/g, 'y')
              .replace(/[^a-zA-Z0-9 -]/g, '')
              .replace(/ /g, '-')
              .toLowerCase();

            const formData = {
              thumbnail_id: responseThumbnails.data.id,
              video_id: responseVideo.data.id,
              title,
              description,
              slug,
            };

            const response = await api.put(`trainings/${trainingId}`, formData);

            if (response.data) {
              const categoriesPromise = new Promise<void>((resolve) => {
                if (categoriesData.length > 0) {
                  const lastIndex = categoriesData.length - 1;
                  categoriesData.forEach((category, index) => {
                    api
                      .get(
                        `trainings-categories/${response.data.id}/${category.id}`
                      )
                      .catch(async () => {
                        const trainingsCategoriesFormData = {
                          training_id: response.data.id,
                          category_id: category.id,
                        };

                        await api.post(
                          'trainings-categories',
                          trainingsCategoriesFormData
                        );

                        if (lastIndex === index) {
                          resolve();
                        }
                      });
                  });
                } else {
                  resolve();
                }
              });

              await categoriesPromise;

              Swal.fire(
                'Good job!',
                'Training updated successfully.',
                'success'
              ).then(() => {
                setLoading(false);
                history.push(`${process.env.PUBLIC_URL}/affiliates/training`);
              });
            }
          }
        }
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErros(error);
          formRef.current?.setErrors(errors);
          setLoading(false);
        } else {
          Swal.fire(
            'Opss...',
            'An error has occurred, please try again.',
            'error'
          ).then(() => setLoading(false));
        }
      }
    },
    [
      categoriesData,
      categoriesDeleted,
      history,
      thumbnailId,
      thumbnailSelected,
      trainingId,
      videoId,
      videoSelected,
    ]
  );

  return (
    <Container>
      <div className="container-fluid container-xxl">
        <div className="row justify-content-center">
          <div className="col-12 p-0 mb-5">
            <Welcome>
              <div className="container-fluid">
                <div className="row">
                  <div className="d-flex pl-3 align-items-center">
                    <Link
                      to={`${process.env.PUBLIC_URL}/affiliates/training`}
                      className="mr-4"
                    >
                      <FiArrowLeft size={33} />
                    </Link>
                    <h1 className="h2 mb-0">Training</h1>
                  </div>
                </div>
              </div>
            </Welcome>
          </div>
          <div className="col-12">
            <Form
              ref={formRef}
              initialData={training}
              onSubmit={handleSubmit}
              className="row"
            >
              <div className="col-lg-6 order-1 order-lg-0">
                <InputsGroup className="p-4 h-100">
                  <h2 className="h4">Training Information</h2>
                  <div className="py-3">
                    <label htmlFor="title">Title</label>
                    <Input name="title" id="title" className="py-3" />
                  </div>
                  <div className="py-3">
                    <label htmlFor="categories">Category</label>
                    <InputCategory
                      type="trainings"
                      onSelect={handleSelectCategories}
                      onRemove={handleRemoveCategory}
                      className="py-1"
                      valuesSelected={categoriesData}
                    />
                  </div>
                  <div className="py-3">
                    <label htmlFor="description">Description</label>
                    <Textarea
                      name="description"
                      id="description"
                      className="description"
                    />
                  </div>
                  <div className="d-flex py-3">
                    <button type="submit" className="btn-grey my-5 py-3 w-100">
                      <span className="d-block font-weight-bold">Save</span>
                    </button>
                  </div>
                </InputsGroup>
              </div>
              <div className="col-lg-6 order-0 order-lg-1 mb-5 mb-lg-0">
                <div className="w-100 h-60 pb-4">
                  {video ? (
                    <>
                      <Player src={video} />
                      <button
                        type="button"
                        onClick={handleRemoveVideo}
                        className="btn-remove-video mt-2 p-absolute"
                      >
                        <span className="d-block py-1 px-2">X</span>
                      </button>
                    </>
                  ) : (
                    <label
                      htmlFor="video"
                      className="btn bg-gray text-center w-100 h-100 p-0 mb-4 d-flex flex-column justify-content-center"
                    >
                      <div className="py-2 h-100 row align-items-center">
                        <div className="col-12">
                          <h3 className="text-left pl-5 text-white">Video</h3>
                        </div>
                        <div className="col-12">
                          <img
                            src={videoThumb}
                            alt="AddThumbnail"
                            className="my-5"
                          />
                        </div>
                        <div className="col-12">
                          <p className="h5">Upload your file here or</p>
                          <p className="h5 text-white text-decoration-underline">
                            Browse file
                          </p>
                        </div>
                      </div>
                    </label>
                  )}
                  <Input
                    type="file"
                    id="video"
                    name="video"
                    className="d-none"
                    onChange={handleSelectVideo}
                  />
                </div>
                <div className="w-100 h-40">
                  <label
                    htmlFor="thumbnail"
                    className="btn bg-gray text-center w-100 h-100 p-0"
                  >
                    {thumbnail ? (
                      <img
                        src={thumbnail}
                        alt="Thumbnail"
                        className="w-100 h-100"
                      />
                    ) : (
                      <div className="py-2 h-100 row align-items-center">
                        <div className="col-12">
                          <h3 className="text-left pl-5 text-white">
                            Thumbnail
                          </h3>
                        </div>
                        <div className="col-12">
                          <img
                            src={imgThumb}
                            alt="AddThumbnail"
                            className="my-5"
                          />
                        </div>
                        <div className="col-12">
                          <p className="h5">Upload your file here or</p>
                          <p className="h5 text-white text-decoration-underline">
                            Browse file
                          </p>
                        </div>
                      </div>
                    )}
                  </label>
                  <Input
                    type="file"
                    id="thumbnail"
                    name="thumbnail"
                    className="d-none"
                    onChange={handleSelectThumbnail}
                  />
                </div>
              </div>
            </Form>
          </div>
        </div>
      </div>
      {loading && (
        <>
          <div className="loading-box">
            <div className="spinner-border text-light" role="status">
              <span className="sr-only">Loading...</span>
            </div>
            <p className="font-weight-bold text-white mt-3">{status}</p>
          </div>
        </>
      )}
    </Container>
  );
};

export default TrainingUpdate;
