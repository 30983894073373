import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { Link, useHistory } from 'react-router-dom';
import { Container, NameTable } from './styles';
import Table from '~/components/Table';
import api from '~/services/api';

interface ITableData {
  id: string;
  affiliate_id: string;
  referral_id: string;
  affiliate_owner_id: string;
  username: string;
  name: string;
  avatar: string;
  product: string;
  referrer: string;
  affiliate_owner: string;
}

interface IListFormatProps {
  active: boolean;
  changeValues: boolean;
  setChangeValues?(value: boolean): void;
}

interface ICrowdfundingResponse {
  id: string;
  affiliate_id: string;
  referral_id: string;
  affiliate_owner_id: string;
  affiliate: {
    crowdfunding_participation: boolean;
    franchise_owner: boolean;
    student: {
      name: string;
      referral_code: string;
      avatar: {
        avatar_url: string;
      };
    };
    product: {
      name: string;
    };
  };
  referral?: {
    student: {
      name: string;
      referral_code: string;
    };
  };
  affiliate_owner?: {
    student: {
      name: string;
      referral_code: string;
    };
  };
}

const ListFormat: React.FC<IListFormatProps> = ({ active }) => {
  const history = useHistory();
  const [crowdfunding, setCrowdfunding] = useState<ITableData[]>([]);

  const [filter, setFilter] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    api
      .get<ICrowdfundingResponse[]>('crowdfunding', {
        params: {
          filter,
        },
      })
      .then((response) => {
        const dataCrowdFunding = response.data.filter(
          (crowdfundingData) =>
            crowdfundingData.affiliate.crowdfunding_participation &&
            crowdfundingData.affiliate.franchise_owner
        );
        const data: ITableData[] = dataCrowdFunding.map((crowdfundingData) => {
          let affiliate_owner = '';
          if (crowdfundingData === dataCrowdFunding[0]) {
            affiliate_owner = 'Wiser Company';
          } else if (crowdfundingData.affiliate_owner) {
            affiliate_owner = crowdfundingData.affiliate_owner.student.name;
          }

          return {
            id: crowdfundingData.id,
            affiliate_id: crowdfundingData.affiliate_id,
            referral_id: crowdfundingData.referral_id,
            affiliate_owner_id:
              crowdfundingData === response.data[0]
                ? 'Wiser Company'
                : crowdfundingData.affiliate_owner_id,
            username: crowdfundingData.affiliate.student.referral_code,
            avatar: crowdfundingData.affiliate.student.avatar.avatar_url,
            name: crowdfundingData.affiliate.student.name,
            product: crowdfundingData.affiliate.product
              ? crowdfundingData.affiliate.product.name
              : 'N/A',
            referrer: crowdfundingData.referral
              ? crowdfundingData.referral.student.name
              : 'Wiser Company',
            affiliate_owner,
          };
        });
        setCrowdfunding(data);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [filter]);

  const columns = useMemo(
    () => [
      {
        name: 'Username',
        selector: 'username',
        sortable: true,
      },
      {
        name: 'Name',
        selector: 'name',
        sortable: true,
        cell: (row: ITableData) => (
          <NameTable
            data-tag="allowRowEvents"
            className="d-flex align-items-center text-center"
          >
            <img src={row.avatar} alt={row.name} className="mr-2" />
            <p className="mb-0">{row.name}</p>
          </NameTable>
        ),
      },
      {
        name: 'Product',
        selector: 'product',
        sortable: true,
      },
      {
        name: 'Referrer',
        selector: 'referrer',
        sortable: true,
      },
    ],
    []
  );

  const handleSearch = useCallback((value) => {
    setFilter(value);
  }, []);

  const handleClick = useCallback(
    (row) => {
      history.push(
        `${
          process.env.PUBLIC_URL
        }/affiliates/crowdfunding/${row.username.replace('@', '')}`
      );
    },
    [history]
  );

  return (
    <Container className={`col-12 ${active ? 'd-block' : 'd-none'}`}>
      <div className="row">
        <div className="col-12 first-table px-0 px-sm-3 my-3">
          <Table
            data={crowdfunding}
            columns={columns}
            searchable
            onSearch={handleSearch}
            pagination
            onRowClicked={handleClick}
          />
        </div>
      </div>

      {loading && (
        <div className="loading-box">
          <div className="spinner-border text-light" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      )}
    </Container>
  );
};

export default ListFormat;
