import React from 'react';
import Player from '~/components/Player';

// import { Container } from './styles';

const Video: React.FC = () => {
  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-12">
            <Player src="http://localhost:3333/files/1715169400836-ele-está-vivo---superbook-episódio-1-11/1715169400836-ele-está-vivo---superbook-episódio-1-11.m3u8" />
          </div>
        </div>
      </div>
    </>
  );
};

export default Video;
