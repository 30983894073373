import React from 'react';

import { Container, PercentBar } from './styles';

interface ILoading {
  show: boolean;
  status: string;
  percent: number;
  showPercent?: boolean;
}

const Loading: React.FC<ILoading> = ({
  show,
  status,
  percent,
  showPercent,
}) => {
  return (
    <Container show={show}>
      <div className="spinner-border text-light" role="status">
        <span className="sr-only">Loading...</span>
      </div>
      <p className="font-weight-bold text-white mt-3">{status}</p>
      {showPercent && <PercentBar percent={percent} />}
    </Container>
  );
};

export default Loading;
