import React, { useEffect, useState, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { Loader } from '@googlemaps/js-api-loader';
import axios from 'axios';
import { format, utcToZonedTime } from 'date-fns-tz';

import { BiEditAlt } from 'react-icons/bi';
import { BsFillPersonPlusFill } from 'react-icons/bs';
import api from '~/services/api';

import {
  Container,
  Welcome,
  ManageCoaches,
  AddCoach,
  Coach,
  Avatar,
  CoachDetails,
  MiniAvatar,
} from './styles';
import Table from '~/components/Table';

import personAdd from '~/assets/icons/person-add.svg';
import coach1 from '~/assets/defaults/photo-coach.png';
import edit from '~/assets/icons/edit-icon.svg';

const loader = new Loader({
  apiKey: 'AIzaSyAFIKsXFxZbluvp0tOK8NXJl5-PEXJdsSc',
  version: 'weekly',
  libraries: ['places'],
});

interface IAddress {
  id: string;
  city: string;
  complement: string;
  country: string;
  neighborhood: string;
  number: string;
  state: string;
  street: string;
  zipCode: string;
}

interface coachResponseProps {
  id: string;
  name: string;
  slug: string;
  favorite_quote: string;
  email: string;
  languages: string;
  created_at: string;
  coachStudent: {
    length: number;
  };
  avatar: {
    avatar_url: string;
  };
  address: IAddress;
}

interface coachProps {
  id: string;
  slug: string;
  avatar: string;
  name: string;
  favorite_quote: string;
  assigned_members: number;
  email: string;
  languages: string;
  address: IAddress;
  location: string;
  timezone: string;
  created_at: string;
}

interface coachDetailsProps {
  slug: string;
  name: string;
  email: string;
  country: string;
  timezone: string;
  languages: string;
  actions: string;
  avatar: string;
}

const CoachesList: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [coachesData, setCoachesData] = useState<coachProps[]>([]);
  const [coaches, setCoaches] = useState<coachProps[]>([]);

  useEffect(() => {
    window.scrollTo(0, 0);
    setLoading(true);
    api
      .get('coaches')
      .then((response) => {
        loader.load().then(() => {
          response.data.forEach((coach: coachResponseProps) => {
            let localTime = '';
            if (coach.address) {
              const address = `${coach.address.street}, ${coach.address.number}, ${coach.address.neighborhood}, ${coach.address.city}, ${coach.address.state} - ${coach.address.zipCode} - ${coach.address.country}`;
              const geocoder = new google.maps.Geocoder();
              geocoder.geocode({ address }, async (results, status) => {
                if (status === 'OK' && results) {
                  let lat = 0;
                  let lng = 0;
                  if (results[0].geometry) {
                    if (results[0].geometry.location) {
                      lat = results[0].geometry.location.lat();
                      lng = results[0].geometry.location.lng();
                    }
                  }
                  const responseTimezone = await axios.get(
                    `https://maps.googleapis.com/maps/api/timezone/json?location=${lat},${lng}&timestamp=0&key=AIzaSyAFIKsXFxZbluvp0tOK8NXJl5-PEXJdsSc`
                  );
                  const time = utcToZonedTime(
                    new Date(),
                    responseTimezone.data.timeZoneId
                  );
                  localTime = format(time, 'p zz', {
                    timeZone: responseTimezone.data.timeZoneId,
                  });
                }
              });
            }

            const dataCoach: coachProps = {
              id: coach.id,
              name: coach.name,
              slug: coach.slug,
              email: coach.email,
              favorite_quote: coach.favorite_quote,
              assigned_members: coach.coachStudent.length,
              avatar: (coach.avatar && coach.avatar.avatar_url) || coach1,
              languages: coach.languages,
              address: coach.address,
              created_at: coach.created_at,
              location: coach.address ? coach.address.country : '',
              timezone: localTime,
            };

            setCoachesData((state) => [...state, dataCoach]);
          });
        });
      })
      .finally(() => setLoading(false));
  }, []);

  useEffect(() => {
    if (coachesData.length > 0) {
      const coachesList = coachesData.slice();
      coachesList.sort((a, b) => {
        const keyA = new Date(a.created_at);
        const keyB = new Date(b.created_at);
        // Compare the 2 dates
        if (keyA < keyB) return -1;
        if (keyA > keyB) return 1;
        return 0;
      });
      setCoaches(coachesData);
    }
  }, [coachesData]);

  const coachDetailsData = useMemo(() => {
    const data = coaches.map((coach) => ({
      slug: coach.slug,
      name: coach.name,
      email: coach.email,
      country: coach.location,
      timezone: coach.timezone,
      languages: coach.languages,
      actions: '<button>Edit</button>',
      avatar: coach.avatar,
      status: 'Ativo',
    }));
    setLoading(false);
    return data;
  }, [coaches]);

  const columns = useMemo(
    () => [
      {
        name: 'Coach Name',
        selector: 'name',
        sortable: true,
        cell: (row: coachDetailsProps) => (
          <div data-tag="allowRowEvents" className="d-flex align-items-center">
            <MiniAvatar src={row.avatar} className="mr-2" />
            {row.name}
          </div>
        ),
      },
      {
        name: 'Email',
        selector: 'email',
        sortable: true,
      },
      {
        name: 'Location',
        selector: 'country',
        sortable: true,
      },
      {
        name: 'Local Time',
        selector: 'timezone',
        sortable: true,
      },
      {
        name: 'Language(s)',
        selector: 'languages',
        sortable: true,
      },
      {
        name: 'Status',
        selector: 'status',
        sortable: true,
      },
      {
        name: '',
        selector: 'actions',
        cell: () => (
          <div data-tag="allowRowEvents" className="d-flex">
            <Link
              to="/"
              className="d-flex align-items-center border rounded-pill small btn-message ml-1"
            >
              <span className="d-block py-2 px-4">Message</span>
            </Link>
          </div>
        ),
      },
    ],
    []
  );

  return (
    <Container>
      <div className="container-fluid container-xxl">
        <div className="row">
          <div className="col-12 p-0">
            <Welcome className="">
              <h1 className="h3 pl-3 h2-sm mb-4">Coaches</h1>
            </Welcome>
            <ManageCoaches className="px-3 py-4 p-sm-4">
              <h2 className="h5 mb-3">Manage Coaches</h2>
              <div className="scrollbar d-sm-flex overflow-sm-auto pb-3">
                <div className="my-2 pl-0">
                  <AddCoach
                    to={`${process.env.PUBLIC_URL}/coaches/register`}
                    type="button"
                    className="add-width h-100 px-4 py-4 py-sm-5 p-sm-5 d-flex flex-column justify-content-center align-items-center bg-gray"
                  >
                    <div className="mb-3 mb-sm-4 icon-add">
                      <BsFillPersonPlusFill color="#606060" />
                    </div>
                    <div className="text-center color-add">
                      <p className="h5">Add a coach</p>
                      <p className="h5 font-weight-bold text-decoration-underline">
                        Search coaches
                      </p>
                    </div>
                  </AddCoach>
                </div>
                <div className="d-flex overflow-xs-auto scrollbar pb-3 pb-sm-0">
                  {coaches.map((coach) => (
                    <Coach key={coach.id} className="my-2">
                      <div className="card-width mr-3 mr-sm-0 ml-sm-4 d-flex flex-wrap align-items-center justify-content-center bg-gray h-100 py-3 px-4 px-sm-5">
                        <div className="w-100 py-4 text-center text-sm-right">
                          <Link
                            to={`${process.env.PUBLIC_URL}/coaches/update/${coach.slug}`}
                            className="border rounded-pill py-2 px-4 mr-sm-n3 small btn-edit"
                          >
                            Edit <BiEditAlt color="#828282" size={15} />
                          </Link>
                        </div>
                        <div>
                          <Avatar src={coach.avatar} className="mb-4" />
                          <h3 className="h5 font-weight-bold text-center mb-0">
                            {coach.name}
                          </h3>
                          <p className="text-center">Entrenador(a)</p>
                        </div>
                        <div className="row py-2">
                          <div className="col col-lg-6 px-2 my-2">
                            <div className="border-gray">
                              <div className="py-2 px-3 align-items-center">
                                <p className="font-weight-300 small mb-3 box-title">
                                  Assigned
                                  <br /> Members
                                </p>
                                <span className="font-weight-bold mb-0 box-number">
                                  {coach.assigned_members}
                                </span>
                              </div>
                            </div>
                          </div>
                          <div className="col col-lg-6 px-2 my-2">
                            <div className="border-gray">
                              <div className="py-2 px-3 align-items-center">
                                <p className="font-weight-300 small mb-3 box-title">
                                  Members
                                  <br /> Upgraded
                                </p>
                                <span className="font-weight-bold mb-0 box-number">
                                  0
                                </span>
                              </div>
                            </div>
                          </div>
                          <div className="col col-lg-6 px-2 my-2">
                            <div className="border-gray">
                              <div className="py-2 px-3 align-items-center">
                                <p className="font-weight-300 small mb-3 box-title">
                                  Revenue
                                  <br /> Generated
                                </p>
                                <span className="font-weight-bold mb-0 box-number">
                                  0
                                </span>
                              </div>
                            </div>
                          </div>
                          <div className="col col-lg-6 px-2 my-2">
                            <div className="border-gray">
                              <div className="py-2 px-3 align-items-center">
                                <p className="font-weight-300 small mb-3 box-title">
                                  Upgrade
                                  <br /> Ratio
                                </p>
                                <span className="font-weight-bold mb-0 box-number">
                                  0%
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Coach>
                  ))}
                </div>
              </div>
            </ManageCoaches>
            <CoachDetails className="py-4 mt-3">
              <div>
                <Table
                  // title="Coach Details"
                  data={coachDetailsData}
                  columns={columns}
                  date
                  searchable
                  pagination
                  exportable
                />
              </div>
            </CoachDetails>
          </div>
        </div>
      </div>
      {loading && (
        <div className="loading-box">
          <div className="spinner-border text-light" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      )}
    </Container>
  );
};

export default CoachesList;
