import React, { FC, useCallback, useRef } from 'react';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import Swal from 'sweetalert2';

import { Link } from 'react-router-dom';
import { useAuth } from '~/hooks/Auth';
import getValidationErros from '~/utils/getValidationsErrors';

import { Container, AnimationContainer, Background } from './styles';
import Input from '~/components/Input';

import logo from '~/assets/logotipos/logo.png';
import logoMobile from '~/assets/logotipos/logo.svg';
import twitter from '~/assets/icons/twitter.svg';
import facebook from '~/assets/icons/facebook.svg';
import telegram from '~/assets/icons/telegram.svg';
import instagram from '~/assets/icons/instagram.svg';
import wiserr from '~/assets/defaults/wiserr.png';

interface SignInFormData {
  email: string;
  password: string;
}

const SignIn: FC = () => {
  const formRef = useRef<FormHandles>(null);
  const { signIn } = useAuth();

  const handleSubmit = useCallback(
    async (data: SignInFormData) => {
      try {
        formRef.current?.setErrors({});
        const schema = Yup.object().shape({
          email: Yup.string()
            .email('Email Address must be a valid email')
            .required('Email Address is a required'),
          password: Yup.string().required('Password Address is a required'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        await signIn({
          email: data.email,
          password: data.password,
        });
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErros(error);
          formRef.current?.setErrors(errors);
        } else {
          Swal.fire(
            'Opss...',
            'There was an error signing in, check credentials',
            'error'
          );
        }
      }
    },
    [signIn]
  );

  return (
    <Container className="d-flex align-items-center">
      <div className="container-fluid h-100">
        <div className="row justify-content-center justify-content-lg-start align-items-center h-100">
          <Background className="d-none d-lg-block col-lg-6 text-center">
            <div className="h-100 d-flex flex-column align-items-center justify-content-between py-5 content">
              <img src={logo} alt="Logo" />
              <div className="text-center w-100">
                <div className="d-flex justify-content-center">
                  <a href="https://twitter.com/WiserrOfficial" target="blank">
                    <img src={twitter} alt="Twitter" className="mx-2" />
                  </a>
                  <a href="https://facebook.com/wiserrofficial/" target="blank">
                    <img src={facebook} alt="Facebook" className="mx-2" />
                  </a>
                  <a href="https://t.me/wiserrofficial" target="blank">
                    <img src={telegram} alt="Telegram" className="mx-2" />
                  </a>
                  <a
                    href="https://www.instagram.com/millonario.digital/"
                    target="blank"
                  >
                    <img src={instagram} alt="Instagram" className="mx-2" />
                  </a>
                </div>
                <p className="d-block my-2">@millonario.digital</p>
              </div>
            </div>
          </Background>
          <div className="p-absolute position-signIn d-none d-md-block">
            <img src={wiserr} alt="Wiserr" />
          </div>
          <AnimationContainer className="col-11 col-sm-9 col-lg-6 px-0 px-sm-3 my-5 my-lg-0">
            <div className="align-items-center shadow">
              <div className="p-xxl-5 p-lg-4 p-sm-5 p-4 login">
                <div className="col-12 text-center text-sm-right d-lg-none mb-4 mb-sm-n4">
                  <img src={logoMobile} alt="logo" className="" />
                </div>
                <h1 className="display-md-4 display-xxl-3 text-center text-sm-left font-weight-bold mb-3">
                  Hello!
                </h1>
                <h2 className="h6 h5-sm mb-1 mb-md-3 text-center text-sm-left">
                  Enter your details to enter our study platform
                </h2>
                <p className="text-center text-sm-left font-weight-400">
                  A Wiserr place to learn.
                </p>
                <Form ref={formRef} onSubmit={handleSubmit}>
                  <label htmlFor="email" className="d-block mb-1 mt-3 small">
                    Email Address
                  </label>
                  <Input id="email" name="email" className="bg-transparent" />
                  <label htmlFor="password" className="d-block mb-1 mt-4 small">
                    Password
                  </label>
                  <Input
                    id="password"
                    type="password"
                    name="password"
                    className="bg-transparent btn-show-custom"
                  />
                  <Link
                    to={`${process.env.PUBLIC_URL}/forgot-password`}
                    className="small mb-xl-3 mb-xxl-5 w-100 mt-2 mt-md-4 text-right"
                  >
                    Forgot Password ?
                  </Link>
                  <button
                    type="submit"
                    className="button-login my-5 my-xl-4 my-xxl-0 w-100"
                  >
                    <span className="text-white font-weight-bold d-block px-5 py-3">
                      Sign In
                    </span>
                  </button>
                </Form>
              </div>
            </div>
          </AnimationContainer>
        </div>
      </div>
    </Container>
  );
};

export default SignIn;
