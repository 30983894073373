import React from 'react';

import { IProps } from './interfaces';

const WsAffiliates: React.FC<IProps> = ({
  size = 32,
  color = '#000',
  className,
}) => {
  return (
    <svg
      className={className}
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.3588 2C19.7501 2 22 4.41665 22 7.89317V16.1068C22 19.5833 19.7501 22 16.3588 22H7.6412C4.24992 22 2 19.5833 2 16.1068V7.89317C2 4.4199 4.25586 2 7.6412 2H16.3588ZM16.3588 3.39535H7.6412C5.04923 3.39535 3.39535 5.16949 3.39535 7.89317V16.1068C3.39535 18.8341 5.04372 20.6047 7.6412 20.6047H16.3588C18.9563 20.6047 20.6047 18.8341 20.6047 16.1068V7.89317C20.6047 5.16587 18.9563 3.39535 16.3588 3.39535ZM7.69435 9.6295C8.04756 9.6295 8.33946 9.89197 8.38566 10.2325L8.39203 10.3272V16.7087C8.39203 17.094 8.07967 17.4064 7.69435 17.4064C7.34115 17.4064 7.04924 17.1439 7.00305 16.8034L6.99668 16.7087V10.3272C6.99668 9.94186 7.30904 9.6295 7.69435 9.6295ZM12.0354 6.57593C12.3886 6.57593 12.6805 6.8384 12.7267 7.17893L12.7331 7.2736V16.7087C12.7331 17.094 12.4208 17.4064 12.0354 17.4064C11.6822 17.4064 11.3903 17.1439 11.3441 16.8034L11.3378 16.7087V7.2736C11.3378 6.88829 11.6501 6.57593 12.0354 6.57593ZM16.3056 13.0017C16.6589 13.0017 16.9508 13.2642 16.997 13.6047L17.0033 13.6994V16.7087C17.0033 17.094 16.691 17.4064 16.3056 17.4064C15.9524 17.4064 15.6605 17.1439 15.6143 16.8034L15.608 16.7087V13.6994C15.608 13.3141 15.9203 13.0017 16.3056 13.0017Z"
        fill={color}
      />
    </svg>
  );
};

export default WsAffiliates;
