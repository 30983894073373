import styled from 'styled-components';

interface IThumbnail {
  thumbnail?: string;
}

export const Container = styled.div`
  audio::-webkit-media-controls-panel {
    background-color: #ddd;
  }
`;

export const Music = styled.div<IThumbnail>`
  width: 100%;
  height: 55vw;
  max-height: 304px;
  border-radius: 16px;
  background-image: url(${(props) => props.thumbnail});
  background-position: center;
  background-size: cover;
  position: relative;
  overflow: hidden;

  ::after {
    content: '';
    width: 100%;
    height: 100%;
    background-color: #00000055;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
  }

  button {
    z-index: 3;
    position: absolute;
    border-radius: 16px;
    border: 0;
    background-color: #00000085;
    transition: all 0.3s ease-in-out;
    :hover {
      transform: scale(1.5);
    }
  }

  .opacity-0 {
    opacity: 0;
  }

  @media screen and (min-width: 576px) {
    height: 50vw;
    max-height: 287px;
  }

  @media screen and (min-width: 768px) {
    height: 50vw;
    max-height: 388px;
  }

  @media screen and (min-width: 992px) {
    height: 50vw;
    max-height: 523px;
  }

  @media screen and (min-width: 1200px) {
    height: 25vw;
    max-height: 478px;
  }
`;

export const Canvas = styled.canvas`
  width: 100%;
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: 2;
`;
