import React, { useState, useMemo, useEffect } from 'react';
import ReactApexChart from 'react-apexcharts';
import { format } from 'date-fns';
import { ApexOptions } from 'apexcharts';
import api from '~/services/api';
import { formatPrice } from '~/utils/format';

import { Container } from './styles';

interface IStudent {
  created_at: string;
}

interface IOrder {
  amount_paid: string;
  created_at: string;
  deleted_at: string;
}

interface IGraph {
  date: Date;
}

const Graph: React.FC<IGraph> = ({ date }) => {
  const [totalRevenuePrice, setTotalRevenuePrice] = useState('');
  const [months, setMonths] = useState<string[]>([]);
  const [monthMembers, setMonthMembers] = useState<number[]>([]);
  const [monthSales, setMonthSales] = useState<number[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    api
      .get<IStudent[]>('students')
      .then(async (response) => {
        const selectedMonth = date.getMonth();
        const selectedYear = date.getFullYear();

        const monthsNames: string[] = [];
        const membersArr: number[] = [];
        for (let i = 5; i >= 0; i -= 1) {
          const monthCalc = selectedMonth - i;
          let year = selectedYear;
          if (monthCalc < 0) {
            year -= 1;
          }
          const dateAux = new Date(year, monthCalc);
          monthsNames.push(format(dateAux, 'MMMM'));
          const month = dateAux.getMonth();

          const dataByMonth = response.data.filter((student) => {
            const studentDate = new Date(student.created_at);
            const studentMonth = studentDate.getMonth();
            const studentYear = studentDate.getFullYear();
            return month === studentMonth && year === studentYear;
          });
          membersArr.push(dataByMonth.length);
        }

        const responseOrders = await api.get<IOrder[]>('orders/admin', {
          params: {
            status: 'succeeded',
          },
        });
        const total = responseOrders.data.reduce((acumulador, currentValue) => {
          return acumulador + parseFloat(currentValue.amount_paid);
        }, 0);

        const salesArr: number[] = [];
        for (let i = 5; i >= 0; i -= 1) {
          const monthCalc = selectedMonth - i;
          let year = selectedYear;
          if (monthCalc < 0) {
            year -= 1;
          }
          const dateAux = new Date(year, monthCalc);
          monthsNames.push(format(dateAux, 'MMMM'));
          const month = dateAux.getMonth();

          const dataByMonth = responseOrders.data.filter((order) => {
            const orderDate = new Date(order.created_at);
            const orderMonth = orderDate.getMonth();
            const orderYear = orderDate.getFullYear();
            return month === orderMonth && year === orderYear;
          });
          salesArr.push(dataByMonth.length);
        }

        setMonthSales(salesArr);
        setTotalRevenuePrice(formatPrice(total));
        setMonths(monthsNames);
        setMonthMembers(membersArr);
      })
      .finally(() => setLoading(false));
  }, [date]);

  const options = useMemo<ApexOptions>(
    () => ({
      chart: {
        height: 350,
        type: 'area',
        toolbar: {
          show: false,
        },
        selection: {
          enabled: false,
        },
        zoom: {
          enabled: false,
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: 'smooth',
      },
      xaxis: {
        type: 'category',
        categories: months,
      },
      tooltip: {
        x: {
          format: 'dd/MM/yy HH:mm',
        },
        theme: 'dark',
      },
      colors: ['#18191A', '#37c694'],
      markers: {
        strokeColors: '#4E4F51',
      },
      fill: {
        colors: ['rgba(24,25,26,1)', 'rgba(55,198,148,0.8)'],
        type: 'gradient',
        gradient: {
          gradientToColors: ([
            ['rgba(24,25,26,1)', 'rgba(24,25,26,0.5)'],
            ['rgba(55,198,148,1)', 'rgba(55,198,148,0.5)'],
          ] as unknown) as string[],
          shadeIntensity: 1,
          opacityFrom: 1,
          opacityTo: 0,
          stops: [0, 100],
        },
      },
      grid: {
        show: false,
      },
    }),
    [months]
  );

  const series = useMemo(
    () => [
      {
        name: 'Members',
        data: monthMembers,
      },
      {
        name: 'Sales',
        data: monthSales,
      },
    ],
    [monthMembers, monthSales]
  );

  return (
    <Container className="">
      <div className="d-sm-flex align-items-center justify-content-between title-chart">
        <div>
          <h3 className="h6 mb-3 font-weight-400">Company Growth</h3>
          <p>
            <span className="h4 h2-sm">{totalRevenuePrice}</span>
          </p>
        </div>
      </div>
      <ReactApexChart type="area" options={options} series={series} />
      {loading && (
        <>
          <div className="loading-box">
            <div className="spinner-border text-light" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        </>
      )}
    </Container>
  );
};

export default Graph;
