import { Form } from '@unform/web';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import * as Yup from 'yup';
import Swal from 'sweetalert2';
import { useHistory, useParams, Link } from 'react-router-dom';
import { FormHandles } from '@unform/core';

import getValidationErros from '~/utils/getValidationsErrors';
import Input from '~/components/Input';
import api from '~/services/api';
import Achievements from './Achievements';

import {
  Container,
  Welcome,
  PersonalInformation,
  Profile,
  Avatar,
  Modal,
  Coach,
} from './styles';
import InputAddress from '~/components/InputAddress';

interface IAddress {
  city: string;
  complement: string;
  country: string;
  neighborhood: string;
  number: string;
  state: string;
  street: string;
  zipCode: string;
}

interface ICoach {
  id: string;
  name: string;
  avatar: {
    id: string;
    avatar_url: string;
  };
}

interface ICoachStudent {
  id: string;
  coach: ICoach;
}

interface IStudent {
  id: string;
  name: string;
  email: string;
  avatar: {
    id: string;
    avatar_url: string;
  };
  phone?: string;
  zipCode: string;
  country: string;
  street: string;
  number: string;
  neighborhood: string;
  city: string;
  complement: string;
  state: string;
  affiliate_id?: string;
  referral_code: string;
  affiliate_commission?: number;
  digital_franchise_owned?: string;
  crowdfunding_share?: number;
  franchise_commission?: number;
  coachStudent?: ICoachStudent;
}

interface IParams {
  userID: string;
}

const Update: React.FC = () => {
  const history = useHistory<IParams>();
  const params = useParams<IParams>();
  const formRef = useRef<FormHandles>(null);
  const [student, setStudent] = useState<IStudent>({} as IStudent);
  const [activeAffiliate, setActiveAffiliate] = useState('no');
  const [franchiseOwner, setFranchiseOwner] = useState('no');
  const [crowdfundingParticipation, setCrowdfundingParticipation] = useState(
    'no'
  );
  const [addressId, setAddressId] = useState('');
  const [zipCodeData, setZipCode] = useState('');
  const [countryData, setCountry] = useState('');
  const [streetData, setStreet] = useState('');
  const [numberData, setNumber] = useState('');
  const [neighborhoodData, setNeighborhood] = useState('');
  const [stateData, setState] = useState('');
  const [cityData, setCity] = useState('');
  const [complementData, setComplement] = useState('');
  const [loading, setLoading] = useState(false);
  const [coaches, setCoaches] = useState<ICoach[]>([]);
  const [coachSelected, setCoachSelected] = useState({} as ICoach);
  const [show, setShow] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
    setLoading(true);
    api
      .get(`students/admin/${params.userID}`)
      .then(async (response) => {
        let product = '';
        let affiliate_id;
        let affiliate_commission = 10;
        let crowdfunding_share = 5;
        let franchise_commission = 40;
        setActiveAffiliate('no');
        setFranchiseOwner('no');
        setCrowdfundingParticipation('no');

        if (response.data.affiliate) {
          product = response.data.affiliate.product
            ? response.data.affiliate.product.name
            : '';
          affiliate_id = response.data.affiliate.id;
          affiliate_commission = response.data.affiliate.affiliate_commission;
          crowdfunding_share = response.data.affiliate.crowdfunding_share;
          franchise_commission = response.data.affiliate.franchise_commission;
          setActiveAffiliate(
            response.data.affiliate.active_affiliate ? 'yes' : 'no'
          );
          setFranchiseOwner(
            response.data.affiliate.franchise_owner ? 'yes' : 'no'
          );
          setCrowdfundingParticipation(
            response.data.affiliate.crowdfunding_participation ? 'yes' : 'no'
          );
        }

        const responseAddress = await api.get(
          `adresses/student/${response.data.id}`
        );
        let zipCode = '';
        let country = '';
        let city = '';
        let complement = '';
        let neighborhood = '';
        let number = '';
        let state = '';
        let street = '';

        if (responseAddress.data) {
          zipCode = responseAddress.data.zip_code;
          country = responseAddress.data.country;
          city = responseAddress.data.city;
          complement = responseAddress.data.complement;
          neighborhood = responseAddress.data.neighborhood;
          number = responseAddress.data.number;
          state = responseAddress.data.state;
          street = responseAddress.data.street;

          setAddressId(responseAddress.data.id);
        }

        const data: IStudent = {
          id: response.data.id,
          name: response.data.name,
          email: response.data.email,
          phone: response.data.phone,
          zipCode,
          country,
          city,
          complement,
          neighborhood,
          number,
          state,
          street,
          referral_code: response.data.referral_code.toLowerCase(),
          affiliate_id,
          affiliate_commission,
          digital_franchise_owned: product,
          crowdfunding_share,
          franchise_commission,
          avatar: {
            id: response.data.avatar.id,
            avatar_url: response.data.avatar.avatar_url,
          },
          coachStudent: response.data.coachStudent[0],
        };

        setCoachSelected(response.data.coachStudent[0].coach);
        setZipCode(zipCode);
        setCountry(country);
        setCity(city);
        setComplement(complement);
        setNeighborhood(neighborhood);
        setNumber(number);
        setState(state);
        setStreet(street);
        setStudent(data);
      })
      .finally(() => setLoading(false));
  }, [params]);

  const changeActiveAffiliate = useCallback((e) => {
    setActiveAffiliate(e.target.value);
  }, []);

  const changeFranchiseOwner = useCallback((e) => {
    setFranchiseOwner(e.target.value);
  }, []);

  const changeCrowdfundingParticipation = useCallback((e) => {
    setCrowdfundingParticipation(e.target.value);
  }, []);

  const handleChangeZipCode = useCallback((address: IAddress) => {
    const {
      zipCode,
      country,
      street,
      number,
      neighborhood,
      state,
      city,
      complement,
    } = address;
    setZipCode(zipCode);
    setCountry(country);
    setStreet(street);
    setNumber(number);
    setNeighborhood(neighborhood);
    setState(state);
    setCity(city);
    setComplement(complement);
  }, []);

  const handleChangeCountry = useCallback((e) => {
    setCountry(e.target.data);
  }, []);

  const handleChangeStreet = useCallback((e) => {
    setStreet(e.target.data);
  }, []);

  const handleChangeNumber = useCallback((e) => {
    setNumber(e.target.data);
  }, []);

  const handleChangeNeighborhood = useCallback((e) => {
    setNeighborhood(e.target.data);
  }, []);

  const handleChangeState = useCallback((e) => {
    setState(e.target.data);
  }, []);

  const handleChangeCity = useCallback((e) => {
    setCity(e.target.data);
  }, []);

  const handleChangeComplement = useCallback((e) => {
    setComplement(e.target.data);
  }, []);

  const handleSubmit = useCallback(
    async (data: IStudent) => {
      setLoading(true);
      try {
        formRef.current?.setErrors({});
        const schema = Yup.object().shape({
          name: Yup.string().required('Full name is a required'),
          email: Yup.string().email().required('Email address is a required'),
          referral_code: Yup.string().required('Referral code is a required'),
          address: Yup.string(),
        });

        await schema.validate(data, { abortEarly: false });

        const {
          name,
          email,
          referral_code,
          phone,
          affiliate_commission,
          crowdfunding_share,
          franchise_commission,
          zipCode,
          country,
          street,
          number,
          neighborhood,
          city,
          complement,
          state,
        } = data;

        const formData = {
          name,
          email,
          referral_code: referral_code.toLowerCase(),
          phone: phone || undefined,
          // address: address || undefined,
        };

        const response = await api.put(`students/${student.id}`, formData);

        if (response.data) {
          const formDataAffiliates = {
            student_id: student.id,
            active_affiliate: activeAffiliate === 'yes',
            affiliate_commission,
            franchise_owner: franchiseOwner === 'yes',
            crowdfunding_participation: crowdfundingParticipation === 'yes',
            crowdfunding_share,
            franchise_commission,
          };

          if (student.affiliate_id) {
            await api.put(
              `affiliates/${student.affiliate_id}`,
              formDataAffiliates
            );
          } else {
            await api.post('affiliates', formDataAffiliates);
          }

          if (zipCode) {
            const formAddressData = {
              student_id: student.id,
              zip_code: zipCode,
              country,
              street,
              number,
              neighborhood,
              city,
              complement,
              state,
            };

            if (addressId.length > 0) {
              await api.put(`adresses/${addressId}`, formAddressData);
            } else {
              await api.post('adresses', formAddressData);
            }
          }

          Swal.fire(
            'Good job!',
            'Student updated successfully.',
            'success'
          ).then(() => {
            setLoading(false);
            history.push(`${process.env.PUBLIC_URL}/students`);
          });
        }
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErros(error);
          formRef.current?.setErrors(errors);
          setLoading(false);
        } else {
          Swal.fire(
            'Opss...',
            'An error has occurred, please try again.',
            'error'
          ).then(() => setLoading(false));
        }
      }
    },
    [
      activeAffiliate,
      crowdfundingParticipation,
      franchiseOwner,
      history,
      student.affiliate_id,
      student.id,
      addressId,
    ]
  );

  const handleClickChangeCoach = useCallback(async () => {
    const response = await api.get<ICoach[]>('coaches');
    setCoaches(response.data);
    setShow(true);
  }, []);

  const handleClose = useCallback(async () => {
    setShow(false);
  }, []);

  const handleSelectCoach = useCallback(async (coach: ICoach) => {
    setCoachSelected(coach);
  }, []);

  const handleClickSaveChangeCoach = useCallback(async () => {
    try {
      setLoading(true);
      const newStudent = { ...student };
      if (newStudent.coachStudent) {
        await api.put(`coaches-students/${newStudent.coachStudent.id}`, {
          coach_id: coachSelected.id,
          student_id: newStudent.id,
        });
        newStudent.coachStudent.coach = coachSelected;
      } else {
        const response = await api.post(`coaches-students`, {
          coach_id: coachSelected.id,
          student_id: newStudent.id,
        });
        newStudent.coachStudent = {
          id: response.data.id,
          coach: coachSelected,
        };
      }

      setStudent(newStudent);
      handleClose();
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }, [coachSelected, handleClose, student]);

  return (
    <Container>
      <div className="container-fluid container-xxl">
        <div className="row">
          <div className="col-12 p-0">
            <Welcome className="pb-3">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-12">
                    <h1 className="h2 font-weight-bold">Students Management</h1>
                  </div>
                </div>
              </div>
            </Welcome>
            <div className="row">
              {Object.keys(student).length > 0 && (
                <>
                  <div className="col-lg-4 mb-5 mb-lg-0">
                    <div className="px-2 px-lg-3 profile py-2">
                      <div className="d-md-flex d-lg-block align-items-center bg-user px-2 py-3 py-xl-4 py-xxl-5">
                        <Profile className="text-center w-md-50 w-lg-auto">
                          <div className="w-100 p-relative mb-4">
                            <Avatar src={student.avatar.avatar_url} />
                          </div>
                          <h2 className="h5 mb-4">{student.name}</h2>
                        </Profile>
                        <Achievements userId={student.id} />
                      </div>
                    </div>
                    {student.coachStudent && (
                      <div className="px-2 px-lg-3 profile py-2">
                        <div className="d-md-flex d-lg-block align-items-center bg-user px-2 py-3 py-xl-4 py-xxl-5">
                          <h2 className="h4 mb-4 mt-n4">Coach</h2>
                          <Profile className="text-center w-md-50 w-lg-auto">
                            <div className="w-100 p-relative mb-4">
                              <Avatar
                                src={
                                  student.coachStudent.coach.avatar.avatar_url
                                }
                              />
                            </div>
                            <h2 className="h5 mb-4">
                              {student.coachStudent.coach.name}
                            </h2>
                          </Profile>
                          <button
                            type="button"
                            className="w-100 submit-button py-3"
                            onClick={handleClickChangeCoach}
                          >
                            <span className="d-block font-weight-bold">
                              Change coach
                            </span>
                          </button>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="col-lg-8">
                    <PersonalInformation className="p-3">
                      <div className="container-fluid">
                        <Form
                          ref={formRef}
                          onSubmit={handleSubmit}
                          initialData={student}
                          className="row justify-content-between"
                        >
                          <div className="col-12">
                            <h2 className="h4">Personal Information</h2>
                          </div>
                          <div className="col-md-6 my-2">
                            <label htmlFor="name">Full Name</label>
                            <Input name="name" id="name" />
                          </div>
                          <div className="col-md-6 col-xl-3 my-2">
                            <label htmlFor="email">Email Address</label>
                            <Input type="email" name="email" id="email" />
                          </div>
                          <div className="col-md-6 col-lg-4 col-xl-3 my-2">
                            <label htmlFor="phone">Phone Number</label>
                            <Input name="phone" id="phone" />
                          </div>
                          <div className="col-md-6 col-lg-4 my-2">
                            <label htmlFor="email">Código postal</label>
                            <InputAddress
                              name="zipCode"
                              value={zipCodeData}
                              onChange={handleChangeZipCode}
                            />
                          </div>
                          <div className="col-md-6 col-lg-4 my-2">
                            <label htmlFor="email">País</label>
                            <Input
                              name="country"
                              value={countryData}
                              onChange={handleChangeCountry}
                            />
                          </div>
                          <div className="col-md-6 col-lg-4 my-2">
                            <label htmlFor="email">Dirección</label>
                            <Input
                              name="street"
                              value={streetData}
                              onChange={handleChangeStreet}
                            />
                          </div>
                          <div className="col-md-6 col-lg-4 col-xl-3 my-2">
                            <label htmlFor="email">Numero</label>
                            <Input
                              name="number"
                              value={numberData}
                              onChange={handleChangeNumber}
                            />
                          </div>
                          <div className="col-md-6 col-lg-4 col-xl-3 my-2">
                            <label htmlFor="email">Vecindario</label>
                            <Input
                              name="neighborhood"
                              value={neighborhoodData}
                              onChange={handleChangeNeighborhood}
                            />
                          </div>
                          <div className="col-md-6 my-2">
                            <label htmlFor="email">Estado</label>
                            <Input
                              name="state"
                              value={stateData}
                              onChange={handleChangeState}
                            />
                          </div>
                          <div className="col-md-6 col-xl-5 my-2">
                            <label htmlFor="email">Ciudad</label>
                            <Input
                              name="city"
                              value={cityData}
                              onChange={handleChangeCity}
                            />
                          </div>
                          <div className="col-xl-7 my-2">
                            <label htmlFor="email">Complemento</label>
                            <Input
                              name="complement"
                              value={complementData}
                              onChange={handleChangeComplement}
                            />
                          </div>

                          <div className="col-12 mt-4">
                            <h2 className="h4">Affiliate Settings</h2>
                          </div>
                          <div className="col-12">
                            <div className="row">
                              <div className="col-sm-6 col-md-4 col-lg-6 col-xl-3 order-settings my-2">
                                <label htmlFor="referral_code">
                                  Referral Code
                                </label>
                                <Input
                                  name="referral_code"
                                  id="referral_code"
                                />
                              </div>
                              <div className="col-sm-6 col-md-4 col-lg-6 col-xl-3 my-2">
                                <label htmlFor="email">Active Affiliate</label>
                                <div className="d-flex text-center">
                                  <label
                                    className={`btn w-100 mx-1 btn-radio ${
                                      activeAffiliate === 'yes'
                                        ? 'btn-active'
                                        : 'btn-inactive'
                                    }`}
                                    htmlFor="active_affiliate_yes"
                                  >
                                    <span className="d-block py-2">YES</span>
                                    <Input
                                      type="radio"
                                      onChange={changeActiveAffiliate}
                                      name="active_affiliate"
                                      id="active_affiliate_yes"
                                      className="d-none"
                                      value="yes"
                                      checked={activeAffiliate === 'yes'}
                                    />
                                  </label>
                                  <label
                                    className={`btn w-100 mx-1 btn-radio ${
                                      activeAffiliate === 'no'
                                        ? 'btn-active'
                                        : 'btn-inactive'
                                    }`}
                                    htmlFor="active_affiliate_no"
                                  >
                                    <span className="d-block py-2">NO</span>
                                    <Input
                                      type="radio"
                                      onChange={changeActiveAffiliate}
                                      name="active_affiliate"
                                      id="active_affiliate_no"
                                      className="d-none"
                                      value="no"
                                      checked={activeAffiliate === 'no'}
                                    />
                                  </label>
                                </div>
                              </div>
                              <div className="col-sm-6 col-md-4 col-lg-6 col-xl-3 my-2">
                                <label htmlFor="affiliate_commission">
                                  Affiliate Commission
                                </label>
                                <div className="p-relative input-percent">
                                  <Input
                                    type=""
                                    name="affiliate_commission"
                                    id="affiliate_commission"
                                  />
                                  <span className="p-absolute w-50 percentage">
                                    %
                                  </span>
                                </div>
                              </div>
                              <div className="col-sm-6 col-md-4 col-lg-6 col-xl-3 my-2">
                                <label>Franchise Owner</label>
                                <div className="d-flex text-center">
                                  <label
                                    className={`btn w-100 mx-1 btn-radio ${
                                      franchiseOwner === 'yes'
                                        ? 'btn-active'
                                        : 'btn-inactive'
                                    }`}
                                    htmlFor="franchise_owner_yes"
                                  >
                                    <span className="d-block py-2">YES</span>
                                    <Input
                                      type="radio"
                                      onChange={changeFranchiseOwner}
                                      name="franchise_owner"
                                      id="franchise_owner_yes"
                                      className="d-none"
                                      value="yes"
                                      checked={franchiseOwner === 'yes'}
                                    />
                                  </label>
                                  <label
                                    className={`btn w-100 mx-1 btn-radio ${
                                      franchiseOwner === 'no'
                                        ? 'btn-active'
                                        : 'btn-inactive'
                                    }`}
                                    htmlFor="franchise_owner_no"
                                  >
                                    <span className="d-block py-2">NO</span>
                                    <Input
                                      type="radio"
                                      onChange={changeFranchiseOwner}
                                      name="franchise_owner"
                                      id="franchise_owner_no"
                                      className="d-none"
                                      value="no"
                                      checked={franchiseOwner === 'no'}
                                    />
                                  </label>
                                </div>
                              </div>
                              <div className="col-sm-6 col-md-4 col-lg-6 col-xl-3 order-settings my-2">
                                <label htmlFor="digital_franchise_owned">
                                  Digital Franchise Owned
                                </label>
                                <Input
                                  name="digital_franchise_owned"
                                  id="digital_franchise_owned"
                                  disabled
                                />
                              </div>
                              <div className="col-sm-6 col-md-4 col-lg-6 col-xl-3 my-2">
                                <label htmlFor="email">
                                  {' '}
                                  Crowdfunding Participation
                                </label>
                                <div className="d-flex text-center">
                                  <label
                                    className={`btn w-100 mx-1 btn-radio ${
                                      crowdfundingParticipation === 'yes'
                                        ? 'btn-active'
                                        : 'btn-inactive'
                                    }`}
                                    htmlFor="crowdfunding_participation_yes"
                                  >
                                    <span className="d-block py-2">YES</span>
                                    <Input
                                      type="radio"
                                      onChange={changeCrowdfundingParticipation}
                                      name="crowdfunding_participation"
                                      id="crowdfunding_participation_yes"
                                      className="d-none"
                                      value="yes"
                                      checked={
                                        crowdfundingParticipation === 'yes'
                                      }
                                    />
                                  </label>
                                  <label
                                    className={`btn w-100 mx-1 btn-radio ${
                                      crowdfundingParticipation === 'no'
                                        ? 'btn-active'
                                        : 'btn-inactive'
                                    }`}
                                    htmlFor="crowdfunding_participation_no"
                                  >
                                    <span className="d-block py-2">NO</span>
                                    <Input
                                      type="radio"
                                      onChange={changeCrowdfundingParticipation}
                                      name="crowdfunding_participation"
                                      id="crowdfunding_participation_no"
                                      className="d-none"
                                      value="no"
                                      checked={
                                        crowdfundingParticipation === 'no'
                                      }
                                    />
                                  </label>
                                </div>
                              </div>
                              <div className="col-sm-6 col-md-4 col-lg-6 col-xl-3 my-2">
                                <label htmlFor="crowdfunding_share">
                                  Crowdfunding Share
                                </label>
                                <div className="p-relative">
                                  <Input
                                    type=""
                                    name="crowdfunding_share"
                                    id="crowdfunding_share"
                                  />
                                  <span className="p-absolute w-50 percentage">
                                    %
                                  </span>
                                </div>
                              </div>
                              <div className="col-sm-6 col-md-4 col-lg-6 col-xl-3 my-2">
                                <label htmlFor="franchise_commission">
                                  Franchise Commission
                                </label>
                                <div className="p-relative">
                                  <Input
                                    type=""
                                    name="franchise_commission"
                                    id="franchise_commission"
                                  />
                                  <span className="p-absolute w-50 percentage">
                                    %
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="d-flex col my-3">
                            <Link
                              to={`${process.env.PUBLIC_URL}/students/update/${params.userID}/courses`}
                              className="w-100 submit-button py-3 mr-1"
                            >
                              <span className="d-block font-weight-bold">
                                Manager courses
                              </span>
                            </Link>
                            <Link
                              to={`${process.env.PUBLIC_URL}/students/update/${params.userID}/franchises`}
                              className="w-100 submit-button py-3 ml-1"
                            >
                              <span className="d-block font-weight-bold">
                                Manager franchise
                              </span>
                            </Link>
                          </div>

                          <div className="col my-3 col-update">
                            <button
                              type="submit"
                              className="w-100 submit-button py-3"
                            >
                              <span className="d-block font-weight-bold">
                                Update Personal Info
                              </span>
                            </button>
                          </div>
                        </Form>
                      </div>
                    </PersonalInformation>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      {loading && (
        <>
          <div className="loading-box">
            <div className="spinner-border text-light" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        </>
      )}
      <Modal show={show} onHide={handleClose} size="lg">
        <Modal.Header closeButton>
          <Modal.Title className="ml-auto">Change coach</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            {coaches.map((coach) => (
              <Coach
                type="button"
                className="col-lg-4 mb-3"
                selected={coachSelected.id === coach.id}
                onClick={() => handleSelectCoach(coach)}
              >
                <div className="text-center">
                  <div className="w-100 p-relative mb-4">
                    <Avatar src={coach.avatar.avatar_url} />
                  </div>
                  <h2 className="h6 mb-4">{coach.name}</h2>
                </div>
              </Coach>
            ))}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            onClick={handleClickSaveChangeCoach}
            className="btn-grey px-3 py-2"
          >
            Salvar
          </button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
};

export default Update;
