import { darken } from 'polished';
import styled, { css } from 'styled-components';

interface ContainerProps {
  isFilled: boolean;
  isFocuses: boolean;
  isErrored: boolean;
}

export const Container = styled.div<ContainerProps>`
  background: #17171a;
  border-radius: 10px;
  border: 1.5px solid #333333;
  padding: 5px 10px;
  width: 100%;
  display: flex;
  align-items: center;

  + div {
    margin-top: 8px;
  }

  div {
    width: 100%;
    position: relative;

    .button-show {
      position: absolute;
      top: -6px;
      right: -10px;
      width: 60px;
      text-align: center;
      background: none;
      border: 0;
      border-left: 1px solid rgba(224, 224, 224, 0.8);
      padding: 6px 10px 5px;
      color: #efefef;
    }
  }

  input {
    width: 100%;
    flex: 1;
    background: transparent;
    border: 0;
    color: #efefef;
    transition-duration: 0.2s;

    ::placeholder {
      color: #666360;
    }
  }

  .options {
    border-radius: 0 0 10px 10px;
    background-color: #17171a;
    border: 1.5px solid #fff;
    width: calc(100% + 2px);
    left: -1px;
    top: 34px;
    max-height: 200px;
    overflow: auto;
    z-index: 100;

    button {
      border: none;
      border-bottom: 1px solid #ffffff55;
      background: transparent;
      transition-duration: 0.3s;
      color: #efefef;

      :hover {
        background-color: ${darken(0.02, '#17171a')};
      }
    }
  }

  svg {
    margin-right: 16px;
    color: #666360;
    transition-duration: 0.2s;
  }

  ${(props) =>
    props.isErrored &&
    css`
      border-color: #c53030;
    `}

  ${(props) =>
    props.isFilled &&
    css`
      svg {
        color: #fff;
      }
    `}

  ${(props) =>
    props.isFocuses &&
    css`
      color: #fff;
      border-color: #fff !important;
      border-radius: 10px 10px 0 0 !important;

      svg {
        color: #fff;
      }
    `}
`;
