import React from 'react';

import { Container, Avatar } from './styles';

const TopAfiliate: React.FC = () => {
  return (
    <Container className="container-fluid mb-3 mb-xxl-0 px-4 py-3">
      <div className="row align-items-center">
        <div className="col d-none d-xxl-block">
          <p className="mb-0 font-weight-bold">Top Affiliate</p>
        </div>

        <div className="col-3 col-xxl-12 mt-3 mb-2 text-center">
          <Avatar className="mb-2 mx-auto" />
          <h3 className="h6 h5-xl font-weight-bold mb-0 mb-xxl-5 mt-xxl-4">
            Thiago Marques
          </h3>
        </div>
        <div className="col col-xxl-6 px-2 my-2">
          <div className="border-gray">
            <div className="py-2 px-3 align-items-center">
              <p className="small mb-3 box-title">
                Referred
                <br />
                Members
              </p>
              <p className="h3 font-weight-bold mb-0 box-number">3</p>
            </div>
          </div>
        </div>

        <div className="col col-xxl-6 px-2 my-2">
          <div className="border-gray">
            <div className="py-2 px-3 align-items-center">
              <p className="small mb-3 box-title">
                Referred
                <br />
                Members
              </p>
              <p className="h3 font-weight-bold mb-0 box-number">3</p>
            </div>
          </div>
        </div>

        <div className="col col-xxl-6 px-2 my-2">
          <div className="border-gray">
            <div className="py-2 px-3 align-items-center">
              <p className="small mb-3 box-title">
                Referred
                <br />
                Members
              </p>
              <p className="h3 font-weight-bold mb-0 box-number">3</p>
            </div>
          </div>
        </div>

        <div className="col col-xxl-6 px-2 my-2">
          <div className="border-gray">
            <div className="py-2 px-3 align-items-center">
              <p className="small mb-3 box-title">
                Referred
                <br />
                Members
              </p>
              <p className="h3 font-weight-bold mb-0 box-number">3</p>
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default TopAfiliate;
