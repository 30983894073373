import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { parseISO, format } from 'date-fns';

import api from '~/services/api';

import { Container, Welcome, NameStudentTable, OptionStudent } from './styles';
import Table from '~/components/Table';
import options from '~/assets/icons/options.svg';

interface ITableData {
  dataJoined: string;
  userID: string;
  avatar: string;
  name: string;
  member_type: string;
  referrer: string;
  produtc_owned: string;
  email: string;
  phone: string;
  created_at: string;
}

interface ICourseStudent {
  course: {
    title: string;
  };
}

interface IStudent {
  id: string;
  created_at: string;
  referral_code: string;
  avatar: {
    avatar_url: string;
  };
  name: string;
  courseStudent: ICourseStudent[];
  email: string;
  phone: string;
}

const Students: React.FC = () => {
  const hisotry = useHistory();
  const [students, setStudents] = useState<ITableData[]>([]);
  const [loading, setLoading] = useState(false);
  const [filter, setFilter] = useState('');

  useEffect(() => {
    setLoading(true);
    window.scrollTo(0, 0);

    api
      .get('students', {
        params: {
          filter,
        },
      })
      .then((response) => {
        const data = response.data.map((student: IStudent) => ({
          created_at: student.created_at,
          dataJoined: format(parseISO(student.created_at), 'yyyy-MMM-dd'),
          userID: student.id,
          avatar: student.avatar.avatar_url,
          name: student.name,
          member_type: student.courseStudent.length > 0 ? 'Student' : 'Visitor',
          referrer: student.referral_code,
          produtc_owned:
            student.courseStudent.length > 0
              ? student.courseStudent[0].course.title
              : 'Without course',
          email: student.email,
        }));
        setStudents(data);
        setLoading(false);
      });
  }, [filter]);

  const handleSelectedStudent = useCallback(
    (e) => {
      hisotry.push(`/students/update/${e.userID}`);
    },
    [hisotry]
  );

  const handleSearch = useCallback((value) => {
    setFilter(value);
  }, []);

  const columns = useMemo(
    () => [
      {
        name: 'Date Joined',
        selector: 'created_at',
        sortable: true,
        cell: (row: ITableData) => row.dataJoined,
      },
      {
        name: 'User ID',
        selector: 'userID',
        sortable: true,
      },
      {
        name: 'Name',
        selector: 'name',
        sortable: true,
        cell: (row: ITableData) => (
          <NameStudentTable
            data-tag="allowRowEvents"
            className="d-flex align-items-center"
          >
            <img src={row.avatar} alt={row.name} className="mr-2" />
            <p className="mb-0">{row.name}</p>
          </NameStudentTable>
        ),
      },
      {
        name: 'Member Type',
        selector: 'member_type',
        sortable: true,
      },
      {
        name: 'Referrer ',
        selector: 'referrer',
        sortable: true,
      },
      {
        name: 'Product Owned ',
        selector: 'produtc_owned',
        sortable: true,
      },
      {
        name: 'Email ',
        selector: 'email',
        sortable: true,
      },
      {
        name: '',
        selector: 'Options',
        sortable: true,
        cell: (row: ITableData) => (
          <OptionStudent
            data-tag="allowRowEvents"
            className="d-flex align-items-center"
            onClick={() => handleSelectedStudent(row)}
          >
            <img src={options} alt="options" className="mr-2" />
          </OptionStudent>
        ),
      },
    ],
    [handleSelectedStudent]
  );

  return (
    <Container>
      <div className="container-fluid container-xxl">
        <div className="row">
          <div className="col-12 p-0">
            <Welcome>
              <div className="container-fluid">
                <div className="row">
                  <div className="col-12">
                    <h1 className="h4 h2-lg mb-4">Students Management</h1>
                  </div>
                </div>
              </div>
            </Welcome>
            <Table
              data={students}
              columns={columns}
              pagination
              date
              searchable
              onSearch={handleSearch}
              exportable
            />
          </div>
        </div>
      </div>
      {loading && (
        <div className="loading-box">
          <div className="spinner-border text-light" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      )}
    </Container>
  );
};

export default Students;
