import { lighten } from 'polished';
import styled from 'styled-components';
import { Modal as ModalComponent } from 'react-bootstrap';

export const Container = styled.div`
  .btn-new {
    color: #dadada;
    border: solid 1px rgba(96, 96, 96, 1);
    background-color: rgba(32, 32, 32, 1);
    padding: 0.7rem 0;

    :hover {
      color: ${lighten(0.1, '#dadada')};
      border: solid 1px ${lighten(0.1, 'rgba(96, 96, 96, 1)')};
      text-decoration: none;
      img {
        filter: brightness(2.5);
      }
    }
  }

  .box {
    border-radius: 23px;
    background: #202020;

    .table-employees {
      > div {
        .content {
          overflow: auto;

          .tb-header,
          .tb-body {
            min-width: 731px;
            width: 100%;

            .tb-row {
              width: 100%;

              > button:nth-child(1),
              > div:nth-child(1) {
                width: 50px;
              }

              > button:nth-child(2),
              > div:nth-child(2) {
                width: 150px;
              }

              > button:nth-child(3),
              > div:nth-child(3) {
                width: 175px;
              }

              > button:nth-child(4),
              > div:nth-child(4) {
                width: 266px;
              }

              > button:nth-child(5),
              > div:nth-child(5) {
                width: 90px;
              }
            }
          }
        }
      }
    }

    .input {
      border: solid 1px rgba(96, 96, 96, 1);
      background-color: rgba(32, 32, 32, 1);
      border-radius: 50px;
      padding: 5px 15px;
      width: 400px;
      display: flex;
      align-items: center;
      height: 46px;
      color: #777777;

      input {
        width: calc(100% - 32px);

        ::placeholder {
          color: #a3a3a3;
        }
      }
    }

    .btn-filter {
      border-radius: 50%;
      border: 1px solid #e4e4e4;
      background: #f9f9f9;
      padding: 10px;
    }

    .box-gray {
      border-radius: 16px;
      background: #f9f9f9;
    }
  }
`;

export const Modal = styled(ModalComponent)`
  .modal-content {
    border-radius: 23px;
    background: #fff;
    border: none;
    position: relative;

    .input {
      border: solid 1px rgba(96, 96, 96, 1);
      background-color: rgba(32, 32, 32, 1);
      border-radius: 16px;
      padding: 5px 15px;
      width: 100%;
      display: flex;
      align-items: center;
      color: #777777;

      .options {
        top: 50px;
      }

      input {
        height: 40px;
        width: calc(100% - 32px);

        ::placeholder {
          color: #a3a3a3;
        }
      }
    }

    .close-btn {
      position: absolute;
      top: 20px;
      right: 20px;
    }
  }
`;
