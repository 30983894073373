import styled from 'styled-components';

export const Container = styled.div`
  h3 {
    color: #8c8c8c;
    font-weight: 300 !important;
  }

  p {
    color: #d9d9d9;
  }

  .border-gray-achievements {
    padding: 2px;
    color: rgba(217, 217, 217, 1);
    background-image: linear-gradient(0deg, #202020, #202020),
      radial-gradient(
        circle at top left,
        rgba(51, 51, 51, 1),
        rgba(51, 51, 51, 1)
      );
    background-origin: border-box;
    background-clip: content-box, border-box;
    border-image-slice: 1;
    border-radius: 15px;
  }

  @media screen and (max-width: 1281px) {
    h3 {
      font-size: 0.9rem !important;
    }
  }
  @media screen and (min-width: 2040px) {
    .p-2400 {
      padding: 1.5rem 2rem !important;
    }
  }

  @media screen and (min-width: 2300px) {
    .p-2400 {
      padding: 1.5rem 2.7rem !important;
    }
  }
`;
