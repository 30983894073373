/* eslint-disable no-param-reassign */
import React, { useCallback, useEffect, useState } from 'react';
import { FiCopy } from 'react-icons/fi';
import { RiDeleteBin6Line } from 'react-icons/ri';

import Swal from 'sweetalert2';
import {
  Container,
  Welcome,
  AddButton,
  Card,
  LinkCopy,
  Thumbnail,
} from './styles';
import api from '~/services/api';

import facebook from '~/assets/icons/facebook-icon.svg';
import twitter from '~/assets/icons/twitter-icon.svg';
import whatsapp from '~/assets/icons/whatsapp-circle-icon.svg';
import imgThumb from '~/assets/icons/img_thumb.svg';
import send from '~/assets/icons/send.svg';

interface ILink {
  id?: string;
  description: string;
  image: string;
  thumbnail?: File;
  thumbnail_id?: string;
  link: string;
  edited: boolean;
}

interface IResponseLink {
  id: string;
  description: string;
  link: string;
  thumbnail: {
    id: string;
    thumbnail_url: string;
  };
}

const PromotionalMaterial: React.FC = () => {
  const [links, setLinks] = useState<ILink[]>([]);

  useEffect(() => {
    window.scrollTo(0, 0);

    const elements = document.querySelectorAll('textarea');
    elements.forEach((element) => {
      element.style.height = '1px';
      element.style.height = `${element.scrollHeight}px`;
    });

    api.get(`links`).then((response) => {
      const data = response.data.map((link: IResponseLink) => ({
        id: link.id,
        description: link.description,
        image: link.thumbnail.thumbnail_url,
        thumbnail_id: link.thumbnail.id,
        link: link.link,
        edited: false,
      }));

      setLinks(data);
    });
  }, []);

  const handleAddLink = useCallback(() => {
    setLinks((state) => [
      ...state,
      {
        description: '',
        image: '',
        link: 'https://',
        edited: false,
      },
    ]);
  }, []);

  const handleChangeText = useCallback(
    (e, index) => {
      const newLinks = links.slice();
      newLinks[index].description = e.target.value;
      newLinks[index].edited = true;
      const elements = document.querySelectorAll('textarea');
      elements.forEach((element) => {
        if (element === e.target) {
          element.style.height = '1px';
          element.style.height = `${e.target.scrollHeight}px`;
        }
      });
      setLinks(newLinks);
    },
    [links]
  );

  const handleSelectFile = useCallback(
    (e, index) => {
      const newLinks = links.slice();
      if (e.target.files) {
        const file = e.target.files[0];
        if (file) {
          newLinks[index].image = URL.createObjectURL(file);
          newLinks[index].thumbnail = file;
        } else {
          newLinks[index].image = '';
          newLinks[index].thumbnail = undefined;
        }
      }
      newLinks[index].edited = true;
      setLinks(newLinks);
    },
    [links]
  );

  const handleChangeLink = useCallback(
    (value, index) => {
      const newLinks = links.slice();
      newLinks[index].link = value;
      newLinks[index].edited = true;
      setLinks(newLinks);
    },
    [links]
  );

  const handleClickDelete = useCallback(
    (item) => {
      const link = links.find((_, index) => index === item);
      if (link) {
        api.delete(`links/${link.id}`);
      }

      const newLinks = links.filter((_, index) => index !== item);
      setLinks(newLinks);
    },
    [links]
  );

  const handleClickSave = useCallback(
    async (index) => {
      try {
        const newLinks = links.slice();
        let responseThumbnail;
        if (newLinks[index].thumbnail) {
          const thumbnailData = new FormData();
          thumbnailData.append('thumbnail', newLinks[index].thumbnail as File);
          responseThumbnail = await api.post('thumbnails', thumbnailData);
        } else {
          responseThumbnail = {
            data: {
              id: newLinks[index].thumbnail_id,
            },
          };
        }

        if (responseThumbnail.data) {
          let response;
          if (newLinks[index].id) {
            response = await api.put(`links/${newLinks[index].id}`, {
              thumbnail_id: responseThumbnail.data.id,
              description: newLinks[index].description,
              link: newLinks[index].link,
            });
          } else {
            response = await api.post('links', {
              thumbnail_id: responseThumbnail.data.id,
              description: newLinks[index].description,
              link: newLinks[index].link,
            });
          }

          if (response) {
            newLinks[index].edited = false;
            setLinks(newLinks);
          }
        }
      } catch {
        Swal.fire(
          'Opss...',
          'An error has occurred, please try again.',
          'error'
        );
      }
    },
    [links]
  );

  return (
    <Container>
      <div className="container-fluid container-xxl">
        <div className="row">
          <div className="col-12 p-0">
            <Welcome>
              <div className="container-fluid">
                <div className="row justify-content-end">
                  <div className="col-12">
                    <h1 className="h3 h2-lg mb-3 mt-4 mt-sm-0">
                      Material promocional
                    </h1>
                    <p className="my-5 mb-lg-0 mb-xxl-5 font-weight-300">
                      Below you will find several links to promotional content,
                      funnels, blogs, etc. Each Link carries your personal
                      referal code.
                      <br />
                      These high converting pages are created to help you grow
                      your your referral list within our platorm. Enjoy!
                    </p>
                  </div>
                </div>
              </div>
            </Welcome>
          </div>
          <div className="col-lg-12 my-3">
            <div className="d-flex w-100 my-5 my-md-4 my-lg-0 justify-content-center justify-content-sm-start justify-content-lg-end">
              <AddButton
                type="button"
                className="h6 mb-0 py-2 px-4 small normal-sm"
                onClick={handleAddLink}
              >
                <img src={send} alt="plus" className="my-1 mr-3" />
                Add New Link
              </AddButton>
            </div>
          </div>
          {links.map((link, index) => (
            <div key={index} className="col-md-6 col-xl-4 px-0 px-sm-3 my-3">
              <Card className="p-3 h-100">
                <div className="d-flex justify-content-between align-items-center mb-3">
                  <div>
                    <h2 className="h6 ml-sm-2 mb-0">Promotional Material</h2>
                  </div>
                  {link.edited ? (
                    <button
                      type="button"
                      className="rounded-pill btn-top py-2"
                      onClick={() => handleClickSave(index)}
                    >
                      <span className="mb-0 small m-1">Save</span>
                    </button>
                  ) : (
                    <button
                      type="button"
                      className="d-flex align-items-center justify-content-center rounded-pill btn-top py-2 text-danger"
                      onClick={() => handleClickDelete(index)}
                    >
                      <RiDeleteBin6Line size={15} />
                      <span className="mb-0 small ml-2">Delete</span>
                    </button>
                  )}
                </div>
                <textarea
                  className="tinytext small form-control py-1 border-0 mb-4"
                  maxLength={280}
                  onChange={(e) => handleChangeText(e, index)}
                  value={link.description}
                  placeholder="Text for social media"
                />
                <label
                  htmlFor={`image-${index}`}
                  className="d-flex flex-column justify-content-center align-items-center label-image"
                >
                  {link.image ? (
                    <Thumbnail
                      src={link.image}
                      alt="Banner"
                      className="w-100 h-100"
                    />
                  ) : (
                    <>
                      <img src={imgThumb} alt="plus" className="w-25 mb-2" />
                      <p className="h4 mt-2 tinytext">New Image</p>
                    </>
                  )}
                </label>
                <input
                  type="file"
                  id={`image-${index}`}
                  className="d-none"
                  onChange={(e) => handleSelectFile(e, index)}
                />
                <LinkCopy className="rounded-pill d-flex justify-content-between align-items-center py-1 px-3 my-4 my-xl-3 my-xxl-5">
                  <input
                    className="small py-2 w-100 bg-transparent border-0"
                    value={link.link}
                    onChange={(e) => handleChangeLink(e.target.value, index)}
                  />
                  <button
                    type="button"
                    className="border-0 btn-copy bg-transparent"
                  >
                    <FiCopy size={24} color="#CBCCCE" />
                  </button>
                </LinkCopy>
                <div className="d-flex justify-content-center flex-wrap mt-4 pb-xxl-3">
                  <small className="w-100 text-center mb-3">
                    Share on social media
                  </small>
                  <div className="col px-2 px-sm-3 px-lg-2 px-xl-3">
                    <button
                      type="button"
                      className="btn-share shadow rounded-pill w-100"
                    >
                      <img src={facebook} alt="Facebook" className="my-2" />
                    </button>
                  </div>
                  <div className="col px-2 px-sm-3 px-lg-2 px-xl-3">
                    <button
                      type="button"
                      className="btn-share shadow rounded-pill w-100"
                    >
                      <img src={twitter} alt="Twitter" className="my-2" />
                    </button>
                  </div>
                  <div className="col px-2 px-sm-3 px-lg-2 px-xl-3">
                    <button
                      type="button"
                      className="btn-share shadow rounded-pill w-100"
                    >
                      <img src={whatsapp} alt="Whatsapp" className="my-2" />
                    </button>
                  </div>
                </div>
              </Card>
            </div>
          ))}
        </div>
      </div>
    </Container>
  );
};

export default PromotionalMaterial;
