import React, { useState, useEffect, useCallback } from 'react';
import { Link, NavLink, useLocation } from 'react-router-dom';
import 'react-day-picker/lib/style.css';

import { Container, Welcome, Options, Search } from './styles';
import AllAffiliateOffers from './AllAffiliateOffers';
import Categories from './Categories';
import Drafts from './Drafts';

import plus from '~/assets/icons/plus-admin-icon2.svg';
import search from '~/assets/icons/search-icon.svg';

const AffiliateOffers: React.FC = () => {
  const location = useLocation();
  const [optionSelected, setOptionSelected] = useState('categories');
  const [courses, setCourses] = useState(0);
  const [drafts, setDrafts] = useState(0);
  const [filter, setFilter] = useState('');

  useEffect(() => {
    window.scrollTo(0, 0);
    setOptionSelected(location.pathname.slice(17) || 'all-affiliate-offers');
    setFilter('');
  }, [location.pathname]);

  return (
    <Container>
      <div className="container-fluid container-xxl">
        <div className="row">
          <div className="col-12 p-0">
            <Welcome>
              <div className="container-fluid">
                <div className="row justify-content-center">
                  <div className="col-12">
                    <div className="d-sm-flex justify-content-between text-center">
                      <h1 className="h2 text-left">Affiliate Offers</h1>
                      <Link
                        to={`${process.env.PUBLIC_URL}/affiliate-offers/register`}
                        className="btn-new-course align-items-center px-5 ml-lg-5 ml-auto mt-5 mt-sm-0 rounded-pill text-center d-inline-flex justify-content-center"
                      >
                        <img src={plus} alt="plus" className="mr-2" />
                        Add New Offer
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </Welcome>
            <Options>
              <div className="container-fluid">
                <div className="row">
                  <div className="col-12">
                    <div className="row mx-1 pt-5 pb-4 mb-4">
                      <div className="col-5 col-sm-2 px-0">
                        <NavLink
                          to={`${process.env.PUBLIC_URL}/affiliate-offers`}
                          activeClassName="active"
                          className="text-center d-block"
                          exact
                        >
                          All Offers
                        </NavLink>
                      </div>
                      <div className="col-4 col-sm-2 px-0">
                        <NavLink
                          to={`${process.env.PUBLIC_URL}/courses/categories`}
                          activeClassName="active"
                          className="text-center d-block"
                        >
                          Software
                        </NavLink>
                      </div>
                      <div className="col-3 col-sm-2 px-3 px-sm-0">
                        <NavLink
                          to={`${process.env.PUBLIC_URL}/courses/drafts`}
                          activeClassName="active"
                          className="text-center d-block"
                        >
                          Programs
                        </NavLink>
                      </div>
                      <div className="col-3 col-sm-2 px-3 px-sm-0">
                        <NavLink
                          to={`${process.env.PUBLIC_URL}/courses/drafts`}
                          activeClassName="active"
                          className="text-center d-block"
                        >
                          Special Offers
                        </NavLink>
                      </div>
                      <div className="col-3 col-sm-2 px-3 px-sm-0">
                        <NavLink
                          to={`${process.env.PUBLIC_URL}/courses/drafts`}
                          activeClassName="active"
                          className="text-center d-block"
                        >
                          Events
                        </NavLink>
                      </div>
                      <div className="col-3 col-sm-2 px-3 px-sm-0">
                        <NavLink
                          to={`${process.env.PUBLIC_URL}/courses/drafts`}
                          activeClassName="active"
                          className="text-center d-block"
                        >
                          Other
                        </NavLink>
                      </div>
                      <hr className="w-100 mt-4" />
                    </div>
                    <AllAffiliateOffers
                      active={optionSelected === 'all-affiliate-offers'}
                      totalAffiliateOffers={setCourses}
                      filter={filter}
                    />
                    <Categories
                      active={!!optionSelected.match('categories')}
                      filter={filter}
                    />
                    <Drafts
                      active={optionSelected === 'drafts'}
                      totalCourses={setDrafts}
                      filter={filter}
                    />
                  </div>
                </div>
              </div>
            </Options>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default AffiliateOffers;
