import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';

import { Container, Welcome, Card } from './styles';

import training from '~/assets/defaults/training.png';
import crowfunding from '~/assets/defaults/crowfunding.png';
import PromotionalMaterial from '~/assets/defaults/promotional-material.png';

const Affiliates: React.FC = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Container>
      <div className="container-fluid container-xxl">
        <div className="row">
          <div className="col-12 p-0">
            <Welcome>
              <div className="container-fluid">
                <div className="row">
                  <div className="col-12">
                    <h1 className="h4 h2-sm mt-4 mb-5 ">Centro de afiliados</h1>
                  </div>
                </div>
              </div>
            </Welcome>
          </div>
          <div className="col-lg-4 my-4 my-lg-0 order-1 order-lg-0">
            <Card>
              <img src={crowfunding} alt="Links" className="" />
              <div className="py-4 px-4 px-md-5 px-lg-4">
                <h3 className="h5 h4-sm pt-lg-4 mb-3 mb-lg-2 font-weight-600">
                  CrowdFunding
                </h3>
                <p className="mb-4 text-gray">
                  Exclusive for Digital Franchise Owners, who can benefit from
                  up to 8 rounds of funding for their business, 100% Free!
                </p>
                <Link
                  to={`${process.env.PUBLIC_URL}/affiliates/crowdfunding`}
                  className="btn-grey mb-4 mb-sm-0 mb-lg-4 d-block w-sm-50 w-lg-75 mx-lg-auto text-center"
                >
                  Go to
                </Link>
              </div>
            </Card>
          </div>

          <div className="col-lg-4 order-2 order-lg-1">
            <Card>
              <img src={PromotionalMaterial} alt="Social Media" className="" />
              <div className="py-4 px-4 px-md-5 px-lg-4">
                <h3 className="h5 h4-sm pt-lg-4 mb-3 mb-lg-2 font-weight-600">
                  Promotional Material
                </h3>
                <p className="mb-4 text-gray">
                  We provide you with high converting promotional materials with
                  your own affiliate ID that you can use to promote Wiserr.
                </p>
                <Link
                  to={`${process.env.PUBLIC_URL}/affiliates/promotional-material`}
                  className="btn-grey mb-4 mb-sm-0 mb-lg-4 d-block w-sm-50 w-lg-75 mx-lg-auto text-center"
                >
                  Go to
                </Link>
              </div>
            </Card>
          </div>
          <div className="col-lg-4 order-0 order-lg-2">
            <Card>
              <img src={training} alt="Training" className="" />
              <div className="py-4 px-4 px-md-5 px-lg-4">
                <h3 className="h5 h4-sm pt-lg-4 mb-3 mb-lg-2 font-weight-600">
                  Training
                </h3>
                <p className="mb-4 text-gray">
                  Learn everything you need to know to become a sucessfull
                  affiliate and grow your business with us.
                </p>
                <Link
                  to={`${process.env.PUBLIC_URL}/affiliates/training`}
                  className="btn-grey mb-4 mb-sm-0 mb-lg-4 d-block w-sm-50 w-lg-75 mx-lg-auto text-center"
                >
                  Go to
                </Link>
              </div>
            </Card>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default Affiliates;
