import React, {
  ChangeEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import Swal from 'sweetalert2';
import { FormHandles } from '@unform/core';
import { FiArrowLeft } from 'react-icons/fi';

import api from '~/services/api';
import getValidationErros from '~/utils/getValidationsErrors';

import { Container, Welcome, InputsGroup } from './styles';
import Input from '~/components/Input';
import InputCategory, { IValue } from '~/components/InputCategory';
import Textarea from '~/components/Textarea';
import Player from '~/components/Player';

import videoThumb from '~/assets/icons/video_thumb.svg';
import imgThumb from '~/assets/icons/img_thumb.svg';

interface ITraining {
  title: string;
  description: string;
}

const TrainingRegister: React.FC = () => {
  const history = useHistory();
  const formRef = useRef<FormHandles>(null);
  const [thumbnail, setThumbnail] = useState('');
  const [thumbnailSelected, setThumbnailSelected] = useState<File | null>(null);
  const [video, setVideoPhoto] = useState('');
  const [videoSelected, setVideoSelected] = useState<File | null>(null);
  const [status, setStatus] = useState('');
  const [loading, setLoading] = useState(false);
  const [categoriesData, setCategoriesData] = useState<IValue[]>([]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleSelectCategories = useCallback((data) => {
    setCategoriesData(data);
  }, []);

  const handleSelectThumbnail = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      if (e.target.files) {
        const file = e.target.files[0];
        if (file) {
          setThumbnail(URL.createObjectURL(file));
          setThumbnailSelected(file);
        } else {
          setThumbnail('');
          setThumbnailSelected(null);
        }
      }
    },
    []
  );

  const handleSelectVideo = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const file = e.target.files[0];
      if (file) {
        setVideoPhoto(URL.createObjectURL(file));
        setVideoSelected(file);
      } else {
        setVideoPhoto('');
        setVideoSelected(null);
      }
    }
  }, []);

  const handleRemoveVideo = useCallback(() => {
    setVideoPhoto('');
    setVideoSelected(null);
  }, []);

  const handleSubmit = useCallback(
    async (data: ITraining) => {
      try {
        setLoading(true);
        setStatus('uploading thumbnail!');
        const schema = Yup.object().shape({
          title: Yup.string().required('Title is a required'),
          description: Yup.string().required(
            'Training Description is a required'
          ),
          categories: Yup.string().when('$categoryFilled', {
            is: (categoryFilled: boolean) => categoryFilled,
            then: Yup.string(),
            otherwise: Yup.string().required('Categories is a required'),
          }),
          thumbnail: Yup.string().required('Thumbnail is a required'),
          video: Yup.string().required('Video is a required'),
        });

        await schema.validate(data, {
          abortEarly: false,
          context: {
            categoryFilled: categoriesData.length > 0,
          },
        });

        const thumbnailData = new FormData();
        thumbnailData.append('thumbnail', thumbnailSelected as File);
        const responseThumbnails = await api.post('thumbnails', thumbnailData);

        if (responseThumbnails.data) {
          setStatus('uploading video!');
          const videoData = new FormData();
          videoData.append('video', videoSelected as File);
          const responseVideo = await api.post('videos', videoData);

          if (responseVideo.data) {
            setStatus('uploading training!');
            const { title, description } = data;

            const slug = title
              .replace(/[àáâãäå]/g, 'a')
              .replace(/æ/g, 'ae')
              .replace(/ç/g, 'c')
              .replace(/[èéêë]/g, 'e')
              .replace(/[ìíîï]/g, 'i')
              .replace(/ñ/g, 'n')
              .replace(/[òóôõö]/g, 'o')
              .replace(/œ/g, 'oe')
              .replace(/[ùúûü]/g, 'u')
              .replace(/[ýÿ]/g, 'y')
              .replace(/[^a-zA-Z0-9 -]/g, '')
              .replace(/ /g, '-')
              .toLowerCase();

            const formData = {
              thumbnail_id: responseThumbnails.data.id,
              video_id: responseVideo.data.id,
              title,
              description,
              slug,
            };

            const response = await api.post('trainings', formData);

            if (response.data) {
              const categoriesPromise = new Promise<void>((resolve) => {
                if (categoriesData.length > 0) {
                  const lastIndex = categoriesData.length - 1;
                  categoriesData.forEach((category, index) => {
                    api
                      .get(
                        `trainings-categories/${response.data.id}/${category.id}`
                      )
                      .catch(async () => {
                        const trainingsCategoriesFormData = {
                          training_id: response.data.id,
                          category_id: category.id,
                        };

                        await api.post(
                          'trainings-categories',
                          trainingsCategoriesFormData
                        );

                        if (lastIndex === index) {
                          resolve();
                        }
                      });
                  });
                } else {
                  resolve();
                }
              });

              await categoriesPromise;

              Swal.fire(
                'Good job!',
                'Training successfully registered.',
                'success'
              ).then(() => {
                setLoading(false);
                history.push(`${process.env.PUBLIC_URL}/affiliates/training`);
              });
            }
          }
        }
      } catch (error) {
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErros(error);
          formRef.current?.setErrors(errors);
          setLoading(false);
        } else {
          Swal.fire(
            'Opss...',
            'An error has occurred, please try again.',
            'error'
          ).then(() => setLoading(false));
        }
      }
    },
    [categoriesData, history, thumbnailSelected, videoSelected]
  );

  return (
    <Container>
      <div className="container-fluid container-xxl">
        <div className="row justify-content-center">
          <div className="col-12 p-0 mb-5">
            <Welcome>
              <div className="container-fluid">
                <div className="row">
                  <div className="d-flex pl-3 align-items-center">
                    <Link
                      to={`${process.env.PUBLIC_URL}/affiliates/training`}
                      className="mr-4"
                    >
                      <FiArrowLeft size={33} />
                    </Link>
                    <h1 className="h2 mb-0">Training</h1>
                  </div>
                </div>
              </div>
            </Welcome>
          </div>
          <div className="col-12">
            <Form ref={formRef} onSubmit={handleSubmit} className="row">
              <div className="order-1 order-lg-0 col-lg-6">
                <InputsGroup className="px-4 px-sm-5 py-4 h-100">
                  <h2 className="h5 h4-sm">Training Information</h2>
                  <div className="pb-3 pt-5 input">
                    <label htmlFor="title" className="font-weight-400">
                      Title
                    </label>
                    <Input name="title" id="title" />
                  </div>
                  <div className="py-3 input">
                    <label htmlFor="categories" className="font-weight-400">
                      Category
                    </label>
                    <InputCategory
                      type="trainings"
                      onSelect={handleSelectCategories}
                      className="py-1"
                    />
                  </div>
                  <div className="pb-3 pt-5 text-area">
                    <label htmlFor="description" className="font-weight-400">
                      Description
                    </label>
                    <Textarea
                      name="description"
                      id="description"
                      className="description"
                    />
                  </div>
                  <div className="d-flex py-3 mt-5 mb-4">
                    <button
                      type="submit"
                      className="font-weight-bold btn-grey py-3 w-100"
                    >
                      Save
                    </button>
                  </div>
                </InputsGroup>
              </div>
              <div className="order-0 order-lg-1 col-lg-6">
                <div className="w-100 h-lg-60 pb-lg-4">
                  {video ? (
                    <>
                      <Player src={video} />
                      <button
                        type="button"
                        onClick={handleRemoveVideo}
                        className="btn-remove-video mt-2 p-absolute"
                      >
                        <span className="d-block py-1 px-2">X</span>
                      </button>
                    </>
                  ) : (
                    <label
                      htmlFor="video"
                      className="btn bg-gray text-center w-100 h-100 p-0 mb-4 d-flex flex-column justify-content-center"
                    >
                      <div className="py-4 h-100 row align-content-between">
                        <div className="col-12">
                          <h3 className="h4 text-center text-sm-left pl-sm-5">
                            Video
                          </h3>
                        </div>
                        <div className="col-12 my-4 my-lg-0">
                          <img
                            src={videoThumb}
                            alt="AddThumbnail"
                            className="my-5"
                          />
                        </div>
                        <div className="col-12">
                          <p className="h6">Upload your file here or</p>
                          <p className="h6 browse-color font-weight-bold text-decoration-underline">
                            Browse file
                          </p>
                        </div>
                      </div>
                    </label>
                  )}
                  <Input
                    type="file"
                    id="video"
                    name="video"
                    className="d-none"
                    onChange={handleSelectVideo}
                  />
                </div>
                <div className="w-100 h-lg-40 pb-4 pb-lg-0">
                  <label
                    htmlFor="thumbnail"
                    className="btn bg-gray text-center w-100 h-lg-100 p-0"
                  >
                    {thumbnail ? (
                      <img src={thumbnail} alt="Thumbnail" className="w-100" />
                    ) : (
                      <div className="py-4 h-100 row align-content-between">
                        <div className="col-12">
                          <h3 className="h4 text-center text-sm-left pl-sm-5">
                            Thumbnail
                          </h3>
                        </div>
                        <div className="col-12">
                          <img
                            src={imgThumb}
                            alt="AddThumbnail"
                            className="my-5"
                          />
                        </div>
                        <div className="col-12">
                          <p className="h6">Upload your file here or</p>
                          <p className="h6 browse-color font-weight-bold text-decoration-underline">
                            Browse file
                          </p>
                        </div>
                      </div>
                    )}
                  </label>
                  <Input
                    type="file"
                    id="thumbnail"
                    name="thumbnail"
                    className="d-none"
                    onChange={handleSelectThumbnail}
                  />
                </div>
              </div>
            </Form>
          </div>
        </div>
      </div>
      {loading && (
        <>
          <div className="loading-box">
            <div className="spinner-border text-light" role="status">
              <span className="sr-only">Loading...</span>
            </div>
            <p className="font-weight-bold text-white mt-3">{status}</p>
          </div>
        </>
      )}
    </Container>
  );
};

export default TrainingRegister;
