import React, { FC, ComponentType, useMemo } from 'react';
import {
  Route as ReactDOMRoute,
  RouteProps as ReactDOMRouterProps,
  Redirect,
  useLocation,
} from 'react-router-dom';

import { useAuth } from '../hooks/Auth';

import Auth from '~/pages/_Layouts/Auth';
import Default from '~/pages/_Layouts/Default';

interface RouteProps extends ReactDOMRouterProps {
  isPrivate?: boolean;
  component: ComponentType;
}

const Route: FC<RouteProps> = ({
  isPrivate = false,
  component: Component,
  ...rest
}) => {
  const { user } = useAuth();
  const location = useLocation();

  const Layout = useMemo(() => {
    return isPrivate ? Default : Auth;
  }, [isPrivate]);

  const pathname = useMemo(() => {
    if (user) {
      const paths = user.type.usersTypesMenus.map(
        (userTypeMenu) => userTypeMenu.menu.link
      );

      const pathMatch = paths.some((path) =>
        location.pathname.startsWith(`/${path}`)
      );

      console.log(pathMatch);

      if (pathMatch) {
        return '/';
      }

      return paths[0];
    }

    return undefined;
  }, [location.pathname, user]);

  return (
    <ReactDOMRoute
      {...rest}
      render={({ location: locationData }) => {
        if (pathname) {
          return isPrivate === !!user &&
            locationData.pathname.match(pathname) ? (
            <Layout>
              <Component />
            </Layout>
          ) : (
            <Redirect
              to={{
                pathname,
                state: location,
              }}
            />
          );
        }

        return isPrivate === !!user ? (
          <Layout>
            <Component />
          </Layout>
        ) : (
          <Redirect
            to={{
              pathname: '/',
              state: location,
            }}
          />
        );
      }}
    />
  );
};

export default Route;
