import React from 'react';
import { IProps } from './interfaces';

const WsFaq: React.FC<IProps> = ({ size = 32, color = '#000', className }) => {
  return (
    <svg
      className={className}
      width={size}
      height={size}
      viewBox="0 0 26 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.7065 10.0156L14.0856 13.7361C13.2111 14.4217 11.9852 14.4217 11.1108 13.7361L6.4502 10.0156"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.4617 3.99023H17.6753C19.1479 4.00675 20.5496 4.62932 21.5538 5.71295C22.5581 6.79658 23.0772 8.24668 22.9903 9.72553V16.7974C23.0772 18.2762 22.5581 19.7263 21.5538 20.81C20.5496 21.8936 19.1479 22.5162 17.6753 22.5327H7.4617C4.29846 22.5327 2.1665 19.9593 2.1665 16.7974V9.72553C2.1665 6.56364 4.29846 3.99023 7.4617 3.99023Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default WsFaq;
