import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  ChangeEvent,
} from 'react';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';
import Swal from 'sweetalert2';
import { Link, useHistory, useParams } from 'react-router-dom';
import { MdCameraAlt } from 'react-icons/md';
import { RiDeleteBin6Line } from 'react-icons/ri';

import getValidationErros from '~/utils/getValidationsErrors';
import api from '~/services/api';

import {
  Container,
  Welcome,
  ContactInfo,
  PersonalInformation,
  Avatar,
  Modal,
} from './styles';
import Input from '~/components/Input';
import Textarea from '~/components/Textarea';
import InputTags, { IValue } from '~/components/InputTags';
import InputAddress from '~/components/InputAddress';
import imgThumb from '~/assets/icons/img_thumb.svg';

import languagesData from '~/utils/languages.json';

const profilePhoto = 'http://cdn.wiserr.io/avatars/default-avatar.png';

interface IParams {
  slug: string;
}

interface coachFormData {
  name: string;
  email: string;
  phone: string;
  address: string;
  bio: string;
  favorite_quote: string;
  display_name: string;
  display_phone: string;
  display_email: string;
  skype: string;
  commission: string;
  zipCode: string;
  country: string;
  street: string;
  number: string;
  neighborhood: string;
  state: string;
  city: string;
  complement: string;
  slug: string;
}

interface IAddress {
  city: string;
  complement: string;
  country: string;
  neighborhood: string;
  number: string;
  state: string;
  street: string;
  zipCode: string;
}

const CoachesUpdate: React.FC = () => {
  const params = useParams<IParams>();
  const history = useHistory();
  const formRef = useRef<FormHandles>(null);
  const [coach, setCoach] = useState<coachFormData>({} as coachFormData);
  const [coachId, setCoachId] = useState('');
  const [avatarId, setAvatarId] = useState('');
  const [avatar, setAvatar] = useState('');
  const [bioPhoto, setBioPhoto] = useState('');
  const [avatarSelected, setAvatarSelectedSelected] = useState<File | null>(
    null
  );
  const [bioPhotoSelected, setBioPhotoSelectedSelected] = useState<File | null>(
    null
  );
  const [fullName, setFullName] = useState('');
  const [displayName, setDisplayName] = useState('');
  const [emailAddress, setEmailAddress] = useState('');
  const [displayEmail, setDisplayEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [displayPhone, setDisplayPhone] = useState('');
  const [loading, setLoading] = useState(false);
  const [languages, setLanguages] = useState<IValue[]>([]);
  const [addressId, setAddressId] = useState('');
  const [zipCodeData, setZipCode] = useState('');
  const [countryData, setCountry] = useState('');
  const [streetData, setStreet] = useState('');
  const [numberData, setNumber] = useState('');
  const [neighborhoodData, setNeighborhood] = useState('');
  const [stateData, setState] = useState('');
  const [cityData, setCity] = useState('');
  const [complementData, setComplement] = useState('');
  const [show, setShow] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
    setLoading(true);
    api
      .get(`coaches/${params.slug}`)
      .then((response) => {
        setCoachId(response.data.id);
        const index = response.data.file ? response.data.file.length : 0;

        const data: coachFormData = {
          display_name: response.data.display_name,
          display_phone: response.data.display_phone,
          display_email: response.data.display_email,
          skype: response.data.skype,
          commission: response.data.commission,
          name: response.data.name,
          email: response.data.email,
          phone: response.data.phone,
          address: response.data.address || '',
          slug: response.data.slug,
          bio: response.data.biography,
          favorite_quote: response.data.favorite_quote,
          zipCode: response.data.address ? response.data.address.zip_code : '',
          country: response.data.address ? response.data.address.country : '',
          street: response.data.address ? response.data.address.street : '',
          number: response.data.address ? response.data.address.number : '',
          neighborhood: response.data.address
            ? response.data.address.neighborhood
            : '',
          state: response.data.address ? response.data.address.state : '',
          city: response.data.address ? response.data.address.city : '',
          complement: response.data.address
            ? response.data.address.complement
            : '',
        };
        setAddressId(response.data.address ? response.data.address.id : '');
        setAvatarId(response.data.avatar.id);
        setAvatar(response.data.avatar.avatar_url);
        setBioPhoto(
          response.data.file !== null ? response.data.file[index - 1] : ''
        );

        setFullName(response.data.name);
        setDisplayName(response.data.display_name);
        setEmailAddress(response.data.email);
        setDisplayEmail(response.data.display_email);
        setPhoneNumber(response.data.phone);
        setDisplayPhone(response.data.display_phone);
        setZipCode(data.zipCode);
        setCountry(data.country);
        setStreet(data.street);
        setNumber(data.number);
        setNeighborhood(data.neighborhood);
        setState(data.state);
        setCity(data.city);
        setComplement(data.complement);
        if (response.data.languages) {
          const dataLanguages: IValue[] = response.data.languages
            .split(', ')
            .map((language: string) => ({
              value: language,
            }));
          setLanguages(dataLanguages);
        }
        setCoach(data);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [params.slug]);

  const handleSelectAvatar = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const file = e.target.files[0];
      if (file) {
        setAvatar(URL.createObjectURL(file));
        setAvatarSelectedSelected(file);
      } else {
        setAvatar('');
        setAvatarSelectedSelected(null);
      }
    }
  }, []);

  const handleSelectBioPhoto = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      if (e.target.files) {
        const file = e.target.files[0];
        if (file) {
          setBioPhoto(URL.createObjectURL(file));
          setBioPhotoSelectedSelected(file);
        } else {
          setBioPhoto('');
          setBioPhotoSelectedSelected(null);
        }
      }
    },
    []
  );

  const handleChangeName = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setFullName(e.target.value);
  }, []);

  const handleChangeDisplayName = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setDisplayName(e.target.value);
    },
    []
  );

  const handleChangeEmail = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setEmailAddress(e.target.value);
  }, []);

  const handleChangeDisplayEmail = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setDisplayEmail(e.target.value);
    },
    []
  );

  const handleChangePhone = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setPhoneNumber(e.target.value);
  }, []);

  const handleChangeDisplayPhone = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setDisplayPhone(e.target.value);
    },
    []
  );

  const handleChangeZipCode = useCallback((address: IAddress) => {
    const {
      zipCode,
      country,
      street,
      number,
      neighborhood,
      state,
      city,
      complement,
    } = address;
    setZipCode(zipCode);
    setCountry(country);
    setStreet(street);
    setNumber(number);
    setNeighborhood(neighborhood);
    setState(state);
    setCity(city);
    setComplement(complement);
  }, []);

  const handleChangeCountry = useCallback((e) => {
    setCountry(e.target.data);
  }, []);

  const handleChangeStreet = useCallback((e) => {
    setStreet(e.target.data);
  }, []);

  const handleChangeNumber = useCallback((e) => {
    setNumber(e.target.data);
  }, []);

  const handleChangeNeighborhood = useCallback((e) => {
    setNeighborhood(e.target.data);
  }, []);

  const handleChangeState = useCallback((e) => {
    setState(e.target.data);
  }, []);

  const handleChangeCity = useCallback((e) => {
    setCity(e.target.data);
  }, []);

  const handleChangeComplement = useCallback((e) => {
    setComplement(e.target.data);
  }, []);

  const handleSelectLanguage = useCallback((value) => {
    setLanguages(value);
  }, []);

  const handleSubmit = useCallback(
    async (data: coachFormData) => {
      setLoading(true);
      try {
        formRef.current?.setErrors({});
        const schema = Yup.object().shape({
          name: Yup.string().required('Full Name is a required'),
          email: Yup.string()
            .email('Email Address must be a valid email')
            .required('Email Address is a required'),
          bio: Yup.string().required('Bio is a required'),
          favorite_quote: Yup.string().required('Favorite Quote is a required'),
          display_name: Yup.string().required('Display Name is a required'),
          display_phone: Yup.string().required('Display Phone is a required'),
          display_email: Yup.string()
            .email('Display Email must be a valid email')
            .required('Display Email is a required'),
          zipCode: Yup.string().required('Zip code Phone is a required'),
          country: Yup.string().required('Country Phone is a required'),
          street: Yup.string().required('Street Phone is a required'),
          number: Yup.string().required('Number Phone is a required'),
          neighborhood: Yup.string(),
          state: Yup.string().required('State Phone is a required'),
          city: Yup.string().required('City Phone is a required'),
          complement: Yup.string().required('Complement Phone is a required'),
          skype: Yup.string().required('Skype ID is a required'),
          commission: Yup.string().required('Commission is a required'),
          languages: Yup.string().when('$languagesIsFilled', {
            is: (languagesIsFilled: boolean) => languagesIsFilled,
            then: Yup.string(),
            otherwise: Yup.string().required('Languages spoken is a required'),
          }),
        });

        await schema.validate(data, {
          abortEarly: false,
          context: {
            languagesIsFilled: languages.length > 0,
          },
        });

        let responseAvatar;
        if (avatarSelected) {
          const avatarData = new FormData();
          avatarData.append('avatar', avatarSelected as File);
          responseAvatar = await api.post('avatars', avatarData);
        } else {
          responseAvatar = {
            data: {
              id: avatarId,
            },
          };
        }

        if (responseAvatar.data) {
          const {
            name,
            email,
            address,
            bio,
            favorite_quote,
            display_name,
            display_phone,
            display_email,
            skype,
            commission,
            zipCode,
            country,
            street,
            number,
            neighborhood,
            state,
            city,
            complement,
          } = data;

          const newLanguages = languages.map((language) => language.value);

          const formData = {
            avatar_id: responseAvatar.data.id,
            name,
            email,
            phone: display_phone,
            address,
            biography: bio,
            favorite_quote,
            display_name,
            display_phone,
            display_email,
            skype,
            languages: newLanguages.join(', '),
            commission: parseFloat(commission.replace(',', '.')),
          };

          const response = await api.put(`coaches/${coachId}`, formData);

          if (response.data) {
            const formAddressData = {
              coach_id: response.data.id,
              zip_code: zipCode,
              country,
              street,
              number,
              neighborhood,
              city,
              complement,
              state,
            };

            if (addressId) {
              await api.put(`adresses/${addressId}`, formAddressData);
            } else {
              await api.post('adresses', formAddressData);
            }

            let responseBioPhoto;
            if (bioPhotoSelected) {
              const bioPhotoData = new FormData();
              bioPhotoData.append('foreign_id', response.data.id);
              bioPhotoData.append('file', bioPhotoSelected as File);

              responseBioPhoto = await api.post('files', bioPhotoData);
            } else {
              responseBioPhoto = {
                data: true,
              };
            }

            if (responseBioPhoto.data) {
              Swal.fire(
                'Good job!',
                'Coach successfully updated.',
                'success'
              ).then(() => {
                setLoading(false);
                history.push(`${process.env.PUBLIC_URL}/coaches`);
              });
            }
          }
        }
      } catch (error) {
        console.log(error);
        if (error instanceof Yup.ValidationError) {
          const errors = getValidationErros(error);
          formRef.current?.setErrors(errors);
          setLoading(false);
        } else {
          Swal.fire(
            'Opss...',
            'An error has occurred, please try again.',
            'error'
          ).then(() => setLoading(false));
        }
      }
    },
    [
      addressId,
      avatarId,
      avatarSelected,
      bioPhotoSelected,
      coachId,
      history,
      languages,
    ]
  );

  const handleShow = useCallback(() => {
    setShow(true);
  }, []);

  const handleClose = useCallback(() => {
    setShow(false);
  }, []);

  const handleDelete = useCallback(async () => {
    setLoading(true);
    try {
      await api.delete(`coaches/${coachId}`);
      Swal.fire('Good job!', 'Coach deleted successfully.', 'success').then(
        () => {
          handleClose();
          setLoading(false);
          history.push(`${process.env.PUBLIC_URL}/coaches`);
        }
      );
    } catch (error) {
      Swal.fire(
        'Opss...',
        'An error has occurred, please try again.',
        'error'
      ).then(() => setLoading(false));
    }
  }, [coachId, handleClose, history]);

  return (
    <Container>
      <div className="container-fluid container-xxl">
        <Form
          ref={formRef}
          onSubmit={handleSubmit}
          initialData={coach}
          className="row"
        >
          <div className="col-12 px-0">
            <Welcome>
              <h1 className="h3 pl-3 h2-sm mb-4">Edit Coach</h1>
            </Welcome>
          </div>
          <div className="col-xl-3">
            <ContactInfo className="row h-xl-100">
              <div className="col-md-3 col-xl-12 px-0 text-center">
                <h3 className="h5 mb-3 d-xl-none">Contact Information</h3>
                <div className="bd-right py-4 py-xl-0">
                  <Avatar
                    src={avatar.match('default-avatar') ? profilePhoto : avatar}
                    htmlFor="avatar"
                  >
                    <div>
                      <MdCameraAlt size={20} color="#fff" />
                    </div>
                    <Input
                      type="file"
                      name="avatar"
                      id="avatar"
                      className="d-none"
                      onChange={handleSelectAvatar}
                    />
                  </Avatar>
                </div>
              </div>
              <div className="col-12 d-md-none d-xl-block">
                <hr className="my-3 my-lg-0" />
              </div>
              <div className="col-md-9 col-xl-12 px-0 pl-md-4 pl-xl-0">
                <h3 className="h5 mb-4 d-none d-xl-block">
                  Contact Information
                </h3>
                <div className="row">
                  <div className="col-sm-6 col-xl-12 my-2 my-sm-3">
                    <label htmlFor="display_name" className="small">
                      Username
                    </label>
                    <Input
                      id="display_name"
                      name="display_name"
                      value={displayName || fullName}
                      onChange={handleChangeDisplayName}
                      className="border-gray"
                    />
                  </div>
                  <div className="col-sm-6 col-xl-12 my-2 my-sm-3">
                    <label htmlFor="display_phone" className="small">
                      Phone number
                    </label>
                    <Input
                      id="display_phone"
                      name="display_phone"
                      onChange={handleChangeDisplayPhone}
                      value={displayPhone || phoneNumber}
                      className="border-gray"
                    />
                  </div>
                  <div className="col-sm-6 col-xl-12 my-2 my-sm-3">
                    <label htmlFor="display_email" className="small">
                      Email
                    </label>
                    <Input
                      type="email"
                      id="display_email"
                      name="display_email"
                      value={displayEmail || emailAddress}
                      onChange={handleChangeDisplayEmail}
                      className="border-gray"
                    />
                  </div>
                  <div className="col-sm-6 col-xl-12 my-2 my-sm-3">
                    <label htmlFor="skype" className="small">
                      Skype ID
                    </label>
                    <Input id="skype" name="skype" className="border-gray" />
                  </div>
                  <div className="col-sm-6 col-xl-12 my-2 my-sm-3">
                    <label htmlFor="commission" className="commission h5 mb-4">
                      Commission
                    </label>
                    <div className="p-relative input-percent">
                      <Input
                        type="number"
                        name="commission"
                        id="commission"
                        className="border-gray"
                        min={0}
                      />
                      <span className="p-absolute w-25 percentage">%</span>
                    </div>
                  </div>
                </div>
              </div>
            </ContactInfo>
          </div>
          <div className="col-xl-9 px-0 pl-xl-3 pr-xl-0 mt-4 mt-xl-0">
            <PersonalInformation className="h-100">
              <button
                type="button"
                className="d-flex align-items-center justify-content-center text-danger ml-auto border-0 bg-transparent"
                onClick={handleShow}
              >
                <RiDeleteBin6Line size={15} />
                <span className="mb-0 ml-2">Delete</span>
              </button>

              <h3 className="h5">Personal Information</h3>
              <div className="row">
                <div className="col-lg-6 my-2">
                  <label htmlFor="name">Full Name</label>
                  <Input
                    name="name"
                    id="name"
                    className="border-gray"
                    onChange={handleChangeName}
                  />
                </div>
                <div className="col-lg-6 my-2">
                  <label htmlFor="email">Email Address</label>
                  <Input
                    type="email"
                    name="email"
                    id="email"
                    className="border-gray"
                    onChange={handleChangeEmail}
                  />
                </div>

                <div className="col-sm-6 col-lg-4 my-2">
                  <label htmlFor="email">Zip code</label>
                  <InputAddress
                    name="zipCode"
                    value={zipCodeData}
                    onChange={handleChangeZipCode}
                    className="border-gray"
                  />
                </div>
                <div className="col-sm-6 col-lg-4 my-2">
                  <label htmlFor="email">Country</label>
                  <Input
                    name="country"
                    value={countryData}
                    onChange={handleChangeCountry}
                    className="border-gray"
                  />
                </div>
                <div className="col-lg-4 my-2">
                  <label htmlFor="email">Street</label>
                  <Input
                    name="street"
                    value={streetData}
                    onChange={handleChangeStreet}
                    className="border-gray"
                  />
                </div>
                <div className="col-sm-6 col-lg-3 my-2">
                  <label htmlFor="email">Number</label>
                  <Input
                    name="number"
                    value={numberData}
                    onChange={handleChangeNumber}
                    className="border-gray"
                  />
                </div>
                <div className="col-sm-6 col-lg-3 my-2">
                  <label htmlFor="email">Neighborhood</label>
                  <Input
                    name="neighborhood"
                    value={neighborhoodData}
                    onChange={handleChangeNeighborhood}
                    className="border-gray"
                  />
                </div>
                <div className="col-sm-6 my-2">
                  <label htmlFor="email">State</label>
                  <Input
                    name="state"
                    value={stateData}
                    onChange={handleChangeState}
                    className="border-gray"
                  />
                </div>
                <div className="col-sm-6 col-lg-5 my-2">
                  <label htmlFor="email">City</label>
                  <Input
                    name="city"
                    value={cityData}
                    onChange={handleChangeCity}
                    className="border-gray"
                  />
                </div>
                <div className="col-lg-7 my-2">
                  <label htmlFor="email">Complement</label>
                  <Input
                    name="complement"
                    value={complementData}
                    onChange={handleChangeComplement}
                    className="border-gray"
                  />
                </div>
                <div className="col-sm-6 col-lg-7">
                  <div className="mt-3">
                    <label htmlFor="languages" className="small">
                      Spoken languages
                    </label>
                    <InputTags
                      name="languages"
                      id="languages"
                      data={languages}
                      foundData={languagesData}
                      onChange={handleSelectLanguage}
                      className="border-gray language-input pt-2"
                    />
                  </div>
                </div>
                <div className="col-sm-6 col-lg-5">
                  <div className="mt-3">
                    <label htmlFor="favorite_quote" className="small">
                      Favorite quote
                    </label>
                    <Textarea
                      id="favorite_quote"
                      name="favorite_quote"
                      className="border-gray h-107px"
                    />
                  </div>
                </div>
              </div>
              <div className="row justify-content-between mt-4">
                <div className="col-md-6 col-lg-4">
                  <div className="w-100">
                    <label
                      htmlFor="bio_photo"
                      className="btn bg-gray text-center w-100 h-100 p-0"
                    >
                      {bioPhoto ? (
                        <img src={bioPhoto} alt="bio_photo" className="w-100" />
                      ) : (
                        <div className="py-2 h-100 row align-items-center">
                          <div className="col-12">
                            <img
                              src={imgThumb}
                              alt="bio_photo"
                              className="my-4"
                            />
                          </div>
                          <div className="col-12">
                            <p className="h5">
                              <small>Upload your biophoto here</small>
                            </p>
                            <p className="h5 text-white text-decoration-underline">
                              <small>Browse file</small>
                            </p>
                          </div>
                        </div>
                      )}
                    </label>
                    <Input
                      type="file"
                      id="bio_photo"
                      name="bio_photo"
                      className="d-none"
                      onChange={handleSelectBioPhoto}
                    />
                  </div>
                </div>
                <div className="col-md-6 col-lg-8 d-flex flex-column justify-content-between">
                  <div className="h-100">
                    <label htmlFor="bio" className="small">
                      Bio
                    </label>
                    <Textarea
                      id="bio"
                      name="bio"
                      className="border-gray h-143px p-3"
                    />
                  </div>
                </div>
                <div className="col-12 d-flex flex-column justify-content-between">
                  <div className="row justify-content-end">
                    <div className="col-md-2 my-md-3 col-update order-1 order-md-0">
                      <Link
                        to={`${process.env.PUBLIC_URL}/coaches/${coach.slug}`}
                        className="d-block my-bio w-100 py-2 px-0"
                      >
                        <span className="d-block py-2">See my bio</span>
                      </Link>
                    </div>
                    <div className="col-md-4 my-3 col-update order-0 order-md-1">
                      <button
                        type="submit"
                        className="w-100 submit-button py-2 px-5"
                      >
                        <span className="d-block py-2">Save data</span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </PersonalInformation>
          </div>
        </Form>
      </div>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title className="ml-auto">Delete coach</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p className="mb-0 text-center">
            Are you sure you want to delete the coach
          </p>
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-around">
          <button
            type="button"
            onClick={handleClose}
            className="w-25 btn-grey px-3 py-2"
          >
            NO
          </button>
          <button
            type="button"
            onClick={handleDelete}
            className="w-25 btn-grey px-3 py-2"
          >
            YES
          </button>
        </Modal.Footer>
      </Modal>
      {loading && (
        <div className="loading-box">
          <div className="spinner-border text-light" role="status">
            <span className="sr-only">Loading...</span>
          </div>
        </div>
      )}
    </Container>
  );
};

export default CoachesUpdate;
